import React, { useEffect } from 'react'
import { ActionPrivacyPolicy } from '../../store/actions/actions-server-data';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function PrivacyPolicy() {
    const dispatch = useDispatch();
    const MyState = useSelector(state => state);
    const privacyPolicy = MyState.rServerData.privacyPolicy != undefined ? MyState.rServerData.privacyPolicy : null;
    const isLoading = MyState.rLoading ? MyState.rLoading.privacyPolicy : false;
    useEffect(() => {
        if(!privacyPolicy){
            dispatch(ActionPrivacyPolicy());
        }
    }, [privacyPolicy, dispatch]);
    
    return (
        <div className='p-5'>
            {
                isLoading ? (
                    <div className="">
                        <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
                            <p className='mt-2' >Please wait...</p>
                        </div>
                    </div>
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: privacyPolicy && privacyPolicy.content }} />
                )}
        </div>
    )
}

export default PrivacyPolicy
