import Offcanvas from 'react-bootstrap/Offcanvas';
import './SideBar.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ActionDownloadGuideBook, ActionGetCourseCompletionCertificate, ActiongetCourseProgressofCurrentOrder } from '../../store/actions/actions-server-data';
import GuideBookModal from './GuideBookModal';

function SideBar({ show, handleClose }) {
    const MyState = useSelector((state) => state);
    const dispatch = useDispatch();
    const UserAccessToken = MyState.rServerData.Usertoken ? MyState.rServerData.Usertoken : null;
    const CourseMaterialDownloadUrl = MyState.rServerData.Coursematerial ? MyState.rServerData.Coursematerial : null;
    const GuideBookDownloadUrl = MyState.rServerData.GuideBook ? MyState.rServerData.GuideBook : null;
    const Certifcate = MyState.rServerData.completionCertificate ? MyState.rServerData.completionCertificate : null;
    const order = MyState.rServerData.IntiallcourseDetails ? MyState.rServerData.IntiallcourseDetails : [];
    const userDetails = MyState.rServerData.userDataLogggedIn || [];
    const userData = MyState.rServerData.useraccount ? MyState.rServerData.useraccount.userDetails : [];

    const { getCourseProgress } = MyState.rServerData;

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleDownloadCertificate = async () => {
        try {
            if (Certifcate.success == true) {
                var Url = Certifcate && Certifcate.certificate_url || '';
                const response = await fetch(Url);
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'BioMagnetism-and-Beyond-Certificate.pdf');
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                alert(Certifcate.message)
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    useEffect(() => {
        dispatch(ActionDownloadGuideBook({ token: UserAccessToken }));
        if (!getCourseProgress) {
            dispatch(ActiongetCourseProgressofCurrentOrder({ userId: userDetails.id, token: UserAccessToken }));
        }
        if (!Certifcate) {
            if (order.orders) {
                dispatch(ActionGetCourseCompletionCertificate({ userId: userDetails.id, token: UserAccessToken }));
            }
        }
    }, []);
    return (
        <>
            <Offcanvas show={show} onHide={handleClose} placement='end' id="sidebar" style={{ height: '100vh' }}>
                <div onClick={handleClose} className='closeBtn'>
                    <i class="fa-solid fa-xmark"></i>
                </div>
                <Offcanvas.Body style={{ height: '100vh', overflow: 'hidden' }}>
                    <div className='listContainer'>
                        <ul className="list-unstyled">
                            {UserAccessToken ? ('') : (<><li><a href="/login">SIGN IN</a></li> <hr /></>)}
                            <li><a href="/">HOME</a></li>
                            <hr />
                            <li><a href="/about-us">ABOUT US</a></li>
                            <hr />
                            <li><a href="/Course_overView">COURSE OVERVIEW</a></li>
                            <hr />
                            <li><a href="/myCoursePage">COURSE</a></li><hr />
                            <li><a href="/allCourse">ALL COURSE</a></li><hr />
                            {userData?.is_course_purchased == 1 ?
                                (<><li><a href={CourseMaterialDownloadUrl && CourseMaterialDownloadUrl.data} download style={{ color: 'white' }}>COURSE MATERIAL</a>  </li> <hr /></>) : ''}
                            <li style={{ color: "white", cursor: 'pointer' }} onClick={handleShowModal}>COURSE GUIDEBOOK</li>  <hr />
                            <li><a href="/faq">FAQ</a></li>
                            <hr />
                            <li><a href="https://drgarciabiomagnetism.com/products/" target="_blank" rel="noopener noreferrer">PURCHASE SUPPLIES</a></li>
                            <hr />
                            <li><a href="/press-media">MEDIA / BLOGS</a></li>
                            <hr />
                            <li><a href="/contact-us">CONTACT US</a></li>
                            <hr />
                            {userData?.is_course_purchased == 1 ? (<><li><a href="community">Q&A Forum</a></li> <hr /></>) : ''}
                            <li><a href="/help_videos">HELP VIDEOS</a></li>
                            <hr />
                            {userData?.is_course_purchased == 1 ? (<><li><a style={{ color: '#FFF', cursor: 'pointer' }} href='/assesment-answers' >QUIZ DOWNLOADS</a></li></>) : ''}

                            {getCourseProgress && getCourseProgress.sessionId == 6 && getCourseProgress.lectureId >= 57 && (
                                <><hr /><li> <a style={{ color: '#FFF', cursor: 'pointer' }} onClick={handleDownloadCertificate}>DOWNLOAD CERTIFICATE</a></li></>
                            )}
                        </ul>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            {<GuideBookModal show={showModal} handleClose={handleCloseModal} Url={GuideBookDownloadUrl} />}
        </>
    );
}

export default SideBar;