import React, { useEffect, useState, useRef } from 'react';
import './FeedbackPage.css';
import { useDispatch, useSelector } from 'react-redux';
import { ActionGetFeedback, ActionPostFeedback } from '../../store/actions/actions-server-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function FeedbackPage() {
    const [questions, setQuestions] = useState([]);
    const [questionOptions, setQuestionOptions] = useState([]);
    const [option, setOption] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [errors, setErrors] = useState([]);

    const questionRefs = useRef([]);
    const textareaRefs = useRef([]);

    const dispatch = useDispatch();
    const MyState = useSelector(state => state);
    const userAccessToken = MyState.rServerData.Usertoken || null;
    const User = MyState.rServerData.useraccount ? MyState.rServerData.useraccount.userDetails : [];
    const feedbackQuestions = MyState.rServerData ? MyState.rServerData.getFeedback : [];
    const getFeedbackLoading = MyState.rLoading ? MyState.rLoading.getFeedback : null;
    const postFeedbackLoading = MyState.rLoading ? MyState.rLoading.postFeedback : null;

    useEffect(() => {
        if (feedbackQuestions) {
            setOption(feedbackQuestions.options);
            setQuestionOptions(feedbackQuestions.questionOption);
            setQuestions(feedbackQuestions.questions)
        } else {
            const obj = { token: userAccessToken }
            dispatch(ActionGetFeedback(obj));
        }
    }, [dispatch, feedbackQuestions]);

    const handleOptionSelect = (questionId, optionId) => {
        setSelectedOptions({ ...selectedOptions, [questionId]: optionId + 1 });
        setErrors(prevErrors => prevErrors.filter(error => error !== questionId));
    };

    const handleTextareaChange = (index) => {
        setErrors(prevErrors => prevErrors.filter(error => error !== index + questions.length));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = [];
        
        // Validate selected options
        questions.forEach((_, idx) => {
            if (!selectedOptions.hasOwnProperty(idx)) {
                newErrors.push(idx);
            }
        });

        // Validate text areas
        questionOptions.forEach((_, index) => {
            const textAreaValue = document.getElementById(`textAreaInput-${index}`).value;
            if (!textAreaValue) {
                newErrors.push(index + questions.length);
            }
        });

        if (newErrors.length > 0) {
            setErrors(newErrors);
            const firstErrorIndex = newErrors[0];
            if (firstErrorIndex < questions.length) {
                questionRefs.current[firstErrorIndex].scrollIntoView({ behavior: 'smooth' });
            } else {
                textareaRefs.current[firstErrorIndex - questions.length].scrollIntoView({ behavior: 'smooth' });
            }
            return;
        }

        // Gather selected option IDs
        const selectedOptionsArray = Object.values(selectedOptions);
        // Gather textarea inputs
        const textAreaInputsObject = {};
        questionOptions.forEach((data, index) => {
            const textAreaValue = document.getElementById(`textAreaInput-${index}`).value;
            textAreaInputsObject[`textarea${index + 6}`] = textAreaValue || null;
        });

        const obj = {
            token: userAccessToken,
            userId: User.id,
            email: User.email,
            answers_list: selectedOptionsArray,
            textarea6: textAreaInputsObject != undefined ? textAreaInputsObject.textarea6 : '',
            textarea7: textAreaInputsObject != undefined ? textAreaInputsObject.textarea7 : '',
            textarea8: textAreaInputsObject != undefined ? textAreaInputsObject.textarea8 : ''
        }
        dispatch(ActionPostFeedback(obj));
    };

    return (
        <div className='feedbackContainer'>
            <div>
                <div>
                    {
                        postFeedbackLoading && <div className="">
                            <div className="payment_overlay">
                                <div className="d-flex align-items-center justify-content-center flex-column">
                                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
                                    <p className='mt-2 payment_loading'>Please wait...</p>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        getFeedbackLoading ? <div className="">
                            <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
                                <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
                                <p className='mt-2'>Please wait...</p>
                            </div>
                        </div> :
                            <div>
                                <div className='feedbackHeading'>
                                    <h1>FEEDBACK</h1>
                                    <p>Please provide your valuable feedback. It will help us to improve better</p>
                                </div>
                                <div className='py-3'>
                                    <p>All fields with an asterisk (*) are mandatory</p>
                                </div>
                                {questions && questions.length > 0 ? (
                                    <form onSubmit={handleSubmit}>
                                        {questions.map((question, idx) => (
                                            <div key={idx} ref={el => questionRefs.current[idx] = el}>
                                                <div>
                                                    <p className='questions'>{idx + 1}*. {question.keyvalue}</p>
                                                </div>
                                                <div className='optionsContainer'>
                                                    <ol type='a'>
                                                        {option && option.length > 0 ? option.map((option, id) => (
                                                            <li
                                                                key={id}
                                                                className={selectedOptions[idx] === id + 1 ? 'selectedOption' : ''}
                                                                onClick={() => handleOptionSelect(idx, id)}
                                                            >
                                                                {option.keyvalue}
                                                            </li>
                                                        )) : ''}
                                                    </ol>
                                                </div>
                                                {errors.includes(idx) && <p className='error-message text-danger' style={{fontWeight: '600'}}>Please select the answer.</p>}
                                            </div>
                                        ))}
                                        {questionOptions.map((data, index) => (
                                            <div key={index} className='feedTextarea' ref={el => textareaRefs.current[index] = el}>
                                                <div>
                                                    <p className='questions'>{index + 6}*. {data.keyvalue}</p>
                                                </div>
                                                <textarea 
                                                    id={`textAreaInput-${index}`} 
                                                    rows={10} 
                                                    style={{ width: '100%' }} 
                                                    onChange={() => handleTextareaChange(index)}
                                                />
                                                {errors.includes(index + questions.length) && <p className='error-message text-danger' style={{fontWeight: '600'}}>Field is required.</p>}
                                            </div>
                                        ))}
                                        <div style={{ textAlign: 'center' }}>
                                            <button type="submit" className='feedSubmit mt-3'>Submit</button>
                                        </div>
                                    </form>
                                ) : ('')}
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FeedbackPage;
