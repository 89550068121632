import http from "../services/http";
import $q from "q";
import { config } from "../configs";
import { getHeader } from "../store/store";
import { useSelector } from "react-redux";
import axios from 'axios';


const { API_MAP, API_URL } = config;

export default class UserAPI {

  static login(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .post(API_URL + API_MAP.LOGIN, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static register(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .post(API_URL + API_MAP.REGISTER, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static updateUserAcc(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.UPDATE_USER_ACC, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static forgotPassword(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .post(API_URL + API_MAP.ForgotPassword, param)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static resetPassword(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .post(API_URL + API_MAP.ResetPassword, param)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static getCountriesList() {
    const deferred = $q.defer();
    const header = getHeader();
    const url = `${API_URL}${API_MAP.GET_COUNTRIES}`;
    http
      .get(url)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }



  static aboutus(param) {
    const deferred = $q.defer();
    http
      .get(API_URL + API_MAP.ABOUTUS, param)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }



  static courseoverview(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .get(API_URL + API_MAP.COURSEOVERVIEW, param)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  // static userLogout(param) {
    
  //   http
  //     .post(API_URL + API_MAP., param)
  //     .then((res) => deferred.resolve(res))
  //     .catch((error) => deferred.reject(error));

  //   return deferred.promise;
  // }

  static assesments(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.USERASSESMENT, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

}