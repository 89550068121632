import * as Yup from "yup";


export const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email Id is Required").email(),
  password: Yup.string().required("Password is Required").matches(/^\S*$/, 'Password cannot start or end with a space')
    .matches(/^\S*(?:\s+\S+)*$/, 'Password cannot contain spaces in the middle'),
});

export const updateUserPasswordSchema = Yup.object().shape({
  oldpassword: Yup.string().required("Old password is required"),
  password: Yup.string().required('Please provide a valid new password.'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please confirm your password.'),
});

export const subscriberValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email ID is Required").email(),
});

export const contactUsValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter Your name'),
  email: Yup.string().email('Invalid email').required('Please enter Your Email'),
  phone: Yup.string().required('Please enter Your Contact number'),
  message: Yup.string().required('Please enter Your Message or Query'),
});


export const registerFormValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email id is required").email(),
  password: Yup.string().required("Password is required").matches(/^\S*$/, 'Password cannot start or end with a space')
    .matches(/^\S*(?:\s+\S+)*$/, 'Password cannot contain spaces in the middle'),
  ConfirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("confirm password is Required").matches(/^\S*$/, 'Password cannot start or end with a space')
    .matches(/^\S*(?:\s+\S+)*$/, 'Password cannot contain spaces in the middle'),
  username: Yup.string().required("Name is required"),
  employeeId: Yup.string().required('Emp Id is required')
    .max(10, 'Emp Id must be at most 10 characters')
    .matches(/^[a-zA-Z0-9]{1,10}$/, 'Emp Id must be 10 alphanumeric characters')
});


export const forgetPasswordFormValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email Id is Required").email(),
});

export const restPasswordFormValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email Id is required").email(),
  gen_password: Yup.string().required("Generated password is required").matches(/^\S*$/, 'Generated Password cannot start or end with a space')
  .matches(/^\S*(?:\s+\S+)*$/, 'Generated Password cannot contain spaces in the middle'),
  new_password: Yup.string().required("New password is required").matches(/^\S*$/, 'Password cannot start or end with a space')
    .matches(/^\S*(?:\s+\S+)*$/, 'Password cannot contain spaces in the middle')
});

export const restPasswomValidationSchema = Yup.object().shape({
  reset_email: Yup.string().required("Email Id is Required").email(),
  gen_password: Yup.string().required("Generated password is Required"),
  new_password: Yup.string().required("New password is Required"),
});

export const forgetPasswordValidationSchema = Yup.object().shape({
  emailId: Yup.string().required("Employee Id is Required"),
});

export const schema = Yup.object().shape({
  oldpassword: Yup.string().required("Current Password is required"),
  password: Yup.string()
    .required("New Password is required")
    .matches(/^\S*$/, "Password should not contain spaces"),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref('password'), null], "Passwords must match")
});

export const RegPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required("New password is Required").matches(/^\S*$/, 'Password cannot start or end with a space')
    .matches(/^\S*(?:\s+\S+)*$/, 'Password cannot contain spaces in the middle'),
  confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("confirm password is Required").matches(/^\S*$/, 'Password cannot start or end with a space')
    .matches(/^\S*(?:\s+\S+)*$/, 'Password cannot contain spaces in the middle'),
});

export const updateUserValidationSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name is Required"),
  lastname: Yup.string().required("Last Name is Required"),
  profileNumber: Yup.string().required("Contact Number is Required"),
  email: Yup.string().required("Email Id is Required").email(),
  password: Yup.string()
    .required("Password is Required")
    .matches(/^\S*$/, 'Password cannot start or end with a space')
    .matches(/^\S*(?:\s+\S+)*$/, 'Password cannot contain spaces in the middle'),
  address: Yup.string().required("Address is Required"),
  country: Yup.string().required("Country is Required"),
  state: Yup.string().required("State is Required"),
  city: Yup.string().required("City is Required"),
  zipcode: Yup.string().required("Zipcode is Required"),
});