import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { ActionGetCommunity, ActionPostQuery } from '../../store/actions/actions-server-data';

function PostQuestionModal({ show, handleClose, onSubmit1, onSubmit2, title, placeholder, cancel, post, ...props }) {
    const [selectedTopic, setSelectedTopic] = useState('1');
    const [postDescription, setPostDescription] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = () => {
        if (onSubmit1) {
            onSubmit1(postDescription, setError, selectedTopic)
        }
        if (onSubmit2) {
            onSubmit2(postDescription, setError, selectedTopic)
        }
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} animation={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div>
                            {props.postCard && props.postCard.length > 0 && (
                                <select
                                    name="topic"
                                    id="topic"
                                    onChange={(e) => setSelectedTopic(e.target.value)}
                                    style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "var(--bs-border-width) solid var(--bs-border-color)",
                                        borderRadius: "var(--bs-border-radius)",
                                        outline: "none",
                                    }}
                                >
                                    {props.postCard.map((items, idx) => (
                                        <option key={idx} value={idx + 1}>
                                            {items.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <div className='mt-4 mb-3'>
                            <textarea className='form-control' rows={5} name="post_description" placeholder={placeholder}
                                onChange={(e) => setPostDescription(e.target.value)}
                            />
                            {error && <div className="text-danger mt-2">{error}</div>}
                        </div>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                {cancel}
                            </Button>
                            <Button variant="primary" onClick={handleSubmit}>
                                {post}
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
        </div >
    );
}

export default PostQuestionModal;
