import { URL_MAP } from "../constants/urls";
import { generatePath } from "react-router";

const {
  BASE_URL,
  dummy,
  Login,
  Home,
  Register,
  ForgotPassword,
  ResetPassword,
  AllCourse,
  ApplyCourse,
  MyCoursePage,
  DownladAssesmentAnswers,
  CourseDetailPage,
  WatchVideoPage,
  UserAccountPage,
  AboutUs,
  ContactUs,
  CourseOverview,
  MediaBlog,
  HelpVideo,
  Terms_Condition,
  PrivacyPolicy,
  RefundCancel,
  Faq,
  Community,
  CommunityPost,
  CommunityPostDetails,
  AssesmentPage,
  AssesmentResult,
  AuthorizePay,
  PaymentComplete,
  normalOrderInvoice,
  RenewedOrderInvoice,
  GiftingOrderInvoice,
  CommunitySearch,
  PaymentFailed,
  FeedbackPage
} = URL_MAP;

export const buildRoute = (...args) => {
  return args.reduce((prevRoute, item) => {
    return (
      prevRoute +
      (typeof item === "number" || item.indexOf("(") === -1 ? `/${item}` : item)
    );
  }, "");
};

export const applyRouteParams = (path, params) => {
  return generatePath(path, params);
};

const ROUTES = {
  ROOT: `/${BASE_URL}`,
  dummy: buildRoute(dummy),
  Home: buildRoute(Home),
  Login: buildRoute(Login),
  Register: buildRoute(Register),
  ForgotPassword: buildRoute(ForgotPassword),
  ResetPassword: buildRoute(ResetPassword),
  AllCourse: buildRoute(AllCourse),
  ApplyCourse: buildRoute(ApplyCourse),
  MyCoursePage: buildRoute(MyCoursePage),
  DownladAssesmentAnswers: buildRoute(DownladAssesmentAnswers),
  CourseDetailPage: buildRoute(CourseDetailPage),
  WatchVideoPage: buildRoute(WatchVideoPage),
  UserAccountPage: buildRoute(UserAccountPage),
  AboutUs: buildRoute(AboutUs),
  ContactUs: buildRoute(ContactUs),
  CourseOverview: buildRoute(CourseOverview),
  MediaBlog: buildRoute(MediaBlog),
  HelpVideo: buildRoute(HelpVideo),
  Terms_Condition: buildRoute(Terms_Condition),
  PrivacyPolicy: buildRoute(PrivacyPolicy),
  RefundCancel: buildRoute(RefundCancel),
  Faq: buildRoute(Faq),
  Community: buildRoute(Community),
  CommunityPost: buildRoute(CommunityPost),
  CommunityPostDetails: buildRoute(CommunityPostDetails, ":cat_id", ":topic_id", ":id"),
  AssesmentPage: buildRoute(AssesmentPage),
  AssesmentResult: buildRoute(AssesmentResult),
  AuthorizePay: buildRoute(AuthorizePay),
  PaymentComplete: buildRoute(PaymentComplete),
  normalOrderInvoice: buildRoute(normalOrderInvoice),
  RenewedOrderInvoice: buildRoute(RenewedOrderInvoice),
  GiftingOrderInvoice: buildRoute(GiftingOrderInvoice),
  CommunitySearch: buildRoute(CommunitySearch),
  PaymentFailed:buildRoute(PaymentFailed),
  FeedbackPage: buildRoute(FeedbackPage)
};

export default ROUTES;
