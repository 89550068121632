import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActiongetCourseProgressofCurrentOrder, AtionSaveAssesmentResults } from '../../store/actions/actions-server-data';
import './AssesmentPage.css';
import AssesmentModal from './AssesmentModal';
import AssesmentResult from '../AssesmentResult/AssesmentResult';
import { ActionAssesment } from '../../store/actions/actions-server-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router';

function AssesmentPage(props) {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state);

    const location = useLocation();
    
    const [questions, setQuestions] = useState('');
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [timeRemaining, setTimeRemaining] = useState(1200);
    const [showModal, setShowModal] = useState(false);
    const [attemptedSubmission, setAttemptedSubmission] = useState(false);
    const [unansweredQuestions, setUnansweredQuestions] = useState([]);
    const [startTime, setStartTime] = useState(false)

    const [show, setShow] = useState(false);

    const handleClose = () => setShowModal(false);
    

    const param = location.state;
    
    const { sessionId, lectureId, testimonialId, SessionId, LectureId, TestimonialId } = getQueryParams(param);
    
    const isLoading = userData.rLoading != undefined ? userData.rLoading.isAssesmentLoading : '';
    const isLoadingOrderProgress = userData.rLoading?.getCourseProgress;
    const isAssesmentSubmit = userData.rLoading != undefined ? userData.rLoading.assesmentSubmit : '';
    const userDetails = userData.rServerData.userDataLogggedIn || [];
    const userAccessToken = userData.rServerData.Usertoken || null;
    const assessmentData = userData.rServerData.assesment != undefined ? userData.rServerData.assesment.allQuestion.assessmentQuestion : [];
    const selectedAnswersArray = Object.values(selectedAnswers).filter(answer => answer !== undefined);
    const isSubmitLoader = userData.rLoading != undefined ? userData.rLoading.assesmentSubmit : '';

    const { orders } = userData.rServerData.IntiallcourseDetails;
    const getCourseProgress = userData.rServerData.getCourseProgress;
    useEffect(() => {
        const obj = {
            token: userAccessToken,
            sessionId: sessionId ? sessionId : SessionId,
            lectureId: lectureId ? lectureId : LectureId,
        };
        dispatch(ActionAssesment(obj));
        !isLoading && setShowModal(true);
        dispatch(ActiongetCourseProgressofCurrentOrder({ userId: userDetails.id, token: userAccessToken }));
    }, []);

    useEffect(() => {
        if (assessmentData) {
            setQuestions(assessmentData);
        }
    }, [assessmentData])


    function getQueryParams(queryString) {
        const params = new URLSearchParams(queryString);
        const sessionId = params.get('sessionId');
        const lectureId = params.get('lectureId');
        const SessionId = params.get('SessionId');
        const LectureId = params.get('LectureId');
        const TestimonialId = params.get('TestimonialId');
        const testimonialId = params.get('testimonialId');
        return { sessionId, lectureId, testimonialId, SessionId, LectureId, TestimonialId };
    }

    const handleSubmit = () => {
        const unanswered = [];
        questions.forEach(question => {
            if (!(question.id in selectedAnswers)) {
                unanswered.push(question.id);
            }
        });
        setUnansweredQuestions(unanswered);
        if (unanswered.length > 0) {
            const firstUnansweredQuestionId = unanswered[0];
            const element = document.getElementById(`questions.${firstUnansweredQuestionId}`);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                const offsetPosition = elementPosition - 250;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            } else {
                console.error('Element not found for ID:', firstUnansweredQuestionId);
            }
            return;
        }
        const obj = {
            token: userAccessToken,
            userId: userDetails.id,
            sessionId: sessionId ? sessionId : SessionId,
            lectureId: lectureId ? lectureId : LectureId,
            answers_list: selectedAnswersArray,
            testimonialId: testimonialId ? testimonialId : TestimonialId
        }
        dispatch(AtionSaveAssesmentResults(obj))
        // Reset error message if all questions are answered 
        setUnansweredQuestions([]);
    };
    useEffect(() => {
        if (startTime && timeRemaining > 0) {
            const timer = setInterval(() => {
                setTimeRemaining(prevTime => {
                    if (prevTime === 0) {
                        clearInterval(timer);
                        return 0;
                    } else {
                        return prevTime - 1;
                    }
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [startTime, timeRemaining]);

    useEffect(() => {
        if (timeRemaining === 0) {
            setShowModal(true);
            setAttemptedSubmission(false);
        }
    }, [timeRemaining]);

    const handleOptionSelect = (questionId, choice, choiceIdx) => {
        setSelectedAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: 'choice' + (choiceIdx + 1)
        }));
        setUnansweredQuestions(prevQuestions => prevQuestions.filter(id => id !== questionId));
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className=''>
            {isSubmitLoader ? <div className="payment_overlay">
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
                    <p className='mt-2 payment_loading' >Please wait...</p>
                </div>
            </div> : ''}
            {showModal && !isLoading && getCourseProgress !== undefined &&
                <AssesmentModal show={showModal} handleClose={handleClose} userToken={userAccessToken}
                    setStartTime={setStartTime}
                    setTimeRemaining={setTimeRemaining}
                    setSelectedAnswers={setSelectedAnswers}
                    sessionId={sessionId ? sessionId : SessionId}
                    LectureId={lectureId ? lectureId : LectureId}
                    testimonialId={testimonialId ? testimonialId : TestimonialId}
                    userId={userDetails.id}
                    orderId={orders.length > 0 ? orders[0].orderid : null}
                    firstname={userDetails.firstname}
                />
            }
            {isLoading ? (
                <div className="">
                    <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
                        <p className='mt-2' >Please wait...</p>
                    </div>
                </div>
            ) : (
                <>
                    <div className='moduleTimeContainer px-3 py-3'>
                        <div className='assesmentFlex'>
                            <div className='assesmentHeading'>
                                <h2>Module <span>{sessionId ? sessionId : SessionId}</span> Unit <span>{lectureId ? lectureId : LectureId} Quiz</span></h2>
                                <p>Please provide correct answer of this Quiz To Continue</p>
                            </div>
                            <div>
                                <h2 className='assesmentTime'>{startTime && formatTime(timeRemaining) + "Remaining"}</h2>
                            </div>
                        </div>
                        <div>
                            <p>*** All questions are mandatory to answer.</p>
                        </div>
                    </div>
                    <div className='questionContainer p-4'>
                        {questions && questions.map((item, questionIdx) => (
                            <div key={questionIdx} id={`questions.${item.id}`}>
                                <label className='question'>{questionIdx + 1}. {item.question}</label>
                                <div className='py-2'>
                                    <ol type='a'>
                                        {item.questions.choices.split(" | ").map((choice, choiceIdx) => (
                                            <li
                                                key={choiceIdx}
                                            >
                                                <p
                                                    className={selectedAnswers[item.id] === 'choice' + (choiceIdx + 1) ? 'active' : ''}
                                                    onClick={() => handleOptionSelect(item.id, choice, choiceIdx)}>{choice}</p>
                                            </li>
                                        ))}
                                    </ol>
                                    {unansweredQuestions.includes(item.id) && <p className="error-message" style={{ color: 'red', fontWeight: '600' }}>Please answer this question.</p>}
                                </div>
                            </div>
                        ))}
                        <div className='assesmentSubmitBtn mb-5'>
                            <button onClick={handleSubmit} style={{ borderRadius: '5px' }}>Submit your answer</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default AssesmentPage;
