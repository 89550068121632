import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Access authentication state from Redux store
  const isAuthenticated = useSelector(state => state);
  const UserAccessToken = isAuthenticated.rSession && isAuthenticated.rSession.data && isAuthenticated.rSession.data.token
  const Orders = isAuthenticated.rServerData.useraccount?.orders || [];
  return (
    <Route
      {...rest}
      render={(props) =>
        UserAccessToken ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
