import React, { useEffect, useState } from 'react';
import './HelpVideo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function HelpVideo() {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [videoUrl, setVideoUrl] = useState('');


    const handleVideoClick = (videoUrl) => {
        setVideoUrl(videoUrl);
        document.querySelector('.help-video-container').scrollIntoView({ behavior: 'smooth' });
    };

    const fetchVideos = async () => {
        try {
            const response = await fetch('https://lmsreactbackend.helenzysinc.com/helpVideos');
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            setVideos(result.data);
            setLoading(false);
            if (result.data.length > 0) {
                setVideoUrl(result.data[0].video_url);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVideos();
    }, []);

    return (
        <div className="help-video-container">
            {loading ? (
                <div className="">
                <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
                    <p className='mt-2' >Please wait...</p>
                </div>
            </div>
            ) : (
                <>
                    <div id="video-container" className="video-container">
                        {videoUrl && (
                            <div className="video-wrapper">
                                <iframe
                                    title={videos.find(video => video.video_url === videoUrl)?.title}
                                    width="100%"
                                    height="500"
                                    src={videoUrl}
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                                <div className="video-title-overlay">{videos.find(video => video.video_url === videoUrl)?.title}</div>
                            </div>
                        )}
                    </div>
                    <div className='container'>
                        <div className='mb-3 mt-3 totalVideos' >
                            <p className='pt-2 pb-2'>Total {videos.length} videos</p>
                        </div>

                        <div className="video-links helpCard">
                            {videos.map((video) => (
                                <div className='cardBox' key={video.id} onClick={() => handleVideoClick(video.video_url)}>
                                    <div className="imageWrapper">
                                        <img className="cardImage" src={video.thumbnail} alt={video.title} />
                                    </div>
                                    <p className='helpTitle p'>{video.title}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default HelpVideo;
