import React from 'react';
import PaymentFailedImg from '../../Assets/images/Payment-Failed.png';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

function PaymentFailed() {
    const MyState = useSelector(state => state);
    const transactionId = MyState.rServerData.paymentComplete != undefined ? MyState.rServerData.paymentComplete : [];
    return (
        <div>
            <div className='p-5 d-flex align-items-center justify-content-center my-3 w-100' >
                <div className='d-flex align-items-center justify-content-center flex-column' style={{ minWidth: '400px' }}>
                    <img src={PaymentFailedImg} alt='img' width='400' style={{ borderRadius: '20px' }} />
                    <h2>Error while processing your order</h2>
                    <p>Error: {transactionId.data?.messageText}</p>
                    <p>Your failed Paymentid: {transactionId.data?.transId}</p>
                    <p className='text-danger'>We encountered an issue processing your payment. Please do not use the back button.</p>
                    <p>Please click the button below to retry your payment.</p>
                    <a href='/allcourse'>
                        <Button className='btn btn-success'>Retry Payment</Button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default PaymentFailed