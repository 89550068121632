import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SocialFooter.css';

function SocialFooter({ social }) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='container-fluid'>
            <div className="socialContent py-3">
                <span className="socialHead">USBIOMAG</span>
                <span className="socialMedia py-2">Social Media</span>
                <div className='socialIcons mt-2'>
                  
                    <a href="https://www.youtube.com/channel/UCFGfG5Wc9SGhbG-o5p6Xhag" target="_blank">  <div  className='roundDiv'><i className="fa-brands fa-youtube"></i> </div></a>
                   
                  
                    <a href="https://www.facebook.com/AmericanBiomagnetism/" target="_blank">  <div className='roundDiv'> <i className="fa-brands fa-facebook-f"></i></div></a>
                    
                    
                    <a href="https://www.linkedin.com/company/dr-garcia-biomagnetism" target="_blank">  <div className='roundDiv'> <i className="fa-brands fa-linkedin-in"></i></div></a>            
                    </div>
                
            </div>
            <div className='container'>
                <Slider {...settings}>
                    {Object.values(social).map(item => (
                        <div key={item.id} className=''>
                            <div className=" p-2" style={{ border: 'none'}}>
                                <div className="card-img-caption" >
                                    <img className="card-img-top" src={item.image} alt={item.title} height='100%' />
                                </div>
                                <br />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default SocialFooter
