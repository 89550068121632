import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function InternetSlowModal({showPopup, handleClosePopup}) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div classNames="">
            <Modal
                show={showPopup}
                onHide={handleClosePopup}
                animation={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title> slow internet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Hi, We have detected a slow internet
                            speed. we recommend you to switch to a better network for seamless experience.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <label for="dsta"><input class="mr-2" type="checkbox" name="dsta" id="dsta"
                        onclick='dontshowagainslowinternet("dontshowagainslowinternet", true, 30)' />Dont show this
                        again</label>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default InternetSlowModal;