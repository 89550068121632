import React from 'react';
import './UserAccountPage.css';
import { useSelector } from 'react-redux';

const OrderInvoice = () => {
    const MyState = useSelector((state) => state);
    const UserOrderInvoice = MyState.rServerData.NormalOrderInvoice ?  MyState.rServerData.NormalOrderInvoice : []

    const formattedDate = new Date(UserOrderInvoice.orderDetails.created_at).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });


  return (
    <div className="invoice-box" style={{ width: '100%', padding: '20px' }} onLoad={window.print()}>
      <table cellPadding="0" cellSpacing="0" style={{ width: '100%' }}>
        <tr className="top">
          <td colSpan="2" className="pb-0 py-5" >
            <table>
              <tr>
                <td className="title pb-0">
                  <img src="https://drgarciabiomagnetism.com/wp-content/uploads/2023/09/logo.png" style={{ width: '100%', maxWidth: '300px', transform: 'scale(1.2)' }} alt="logo" />
                </td>
                <td>
                  <h2 style={{ paddingTop: '30px', paddingRight: '100px', fontSize: '45px' }}>Receipt</h2>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr className="top">
          <td colSpan="2" style={{ padding: '0' }}>
            <table>
              <tr>
                <td className="color-voilet" style={{ width: '50%', padding: '0px', paddingBottom: '10px', paddingRight: '0', textAlign: 'center', color: 'white', paddingTop: '16px' }}>
                  Receipt No. {UserOrderInvoice.orderDetails.orderid}
                </td>
                <td style={{ padding: '0', textAlign: 'center', position: 'relative' }}>
                  <div className="triangle-right" style={{ float: 'left', left: '-2px', position: 'absolute' }}></div>
                </td>
                <td style={{ textAlign: 'center', paddingTop: '6px' }}>
                  Date: {formattedDate}
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr className="information">
          <td colSpan="2">
            <table>
              <tr>
                <td>
                  Receipt To:
                  {UserOrderInvoice.userDetails.firstname}&nbsp;{UserOrderInvoice.userDetails.lastname}.<br />
                  {UserOrderInvoice.userDetails.email}<br />
                  {UserOrderInvoice.userDetails.address},<br />
                  {UserOrderInvoice.userDetails.city},{UserOrderInvoice.userDetails.country}
                </td>

                <td>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr className="heading">
          <td>Payment Method</td>
          <td></td>
        </tr>

        <tr className="details">
          <td>Card</td>
          <td></td>
        </tr>

        <tr className="heading">
          <td className="color-voilet" style={{ color: 'white', padding: '13px' }}>Item</td>
          <td style={{ padding: '0', position: 'relative' }}>
            <div className="triangle-right" style={{ display: 'inline', float: 'left', position: 'absolute', left: '0' }}></div>
            <span style={{ position: 'absolute', top: '10px', right: '45%' }}>Price</span>
          </td>
        </tr>

        <tr className="item">
          <td>Biomagnetism and Beyond Online Training</td>
          <td>$995.00</td>
        </tr>

        <tr className="total">
          <td></td>
          <td>
            <div style={{ float: 'left' }}>Sub Total:</div>
            <div>$995.00</div>
          </td>
        </tr>

        <tr className="total">
          <td></td>
          <td>
            <div style={{ float: 'left' }}>Tax:</div>
            <div>0.00%</div>
          </td>
        </tr>

        <tr className="total">
          <td></td>
          <td className="color-voilet" style={{ color: 'white' }}>
            <div style={{ float: 'left' }}>Total:</div>
            <div>$995.00</div>
          </td>
        </tr>

        <tr>
          <td colSpan="3">
            <table>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  North Brunswick, NJ 08902 | (732) 983-8616 | helenzystech@gmail.com
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr className="top">
          <td className="color-voilet" style={{ color: 'white', paddingTop: '14px', paddingLeft: '20px', width: '50%' }}>
            Thank you
          </td>
          <td style={{ padding: '0' }}>
            <div className="triangle-right"></div>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default OrderInvoice;
