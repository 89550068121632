import React, { useEffect, useState, useRef } from "react";
import headerLogo from "../../Assets/images/headerlogo.png";
import hamberg from "../../Assets/images/hamberg.png";
import "./Home.css";
import welcomeVideo from "../../Assets/welcome_to_biomagnetism_and_beyond_online_seminar_iphone.mp4";
import drgarcia from "../../Assets/images/drgarcia.png";
import footerLogo from "../../Assets/images/footerLogo.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageUrl from "../../Assets/images/magnet.png";
import imageUrlShoes from "../../Assets/images/Biomag_shoes.png";
import SocialFooter from "./SocialFooter";
import FooterLayout from "../FooterLayout/FooterLayout";
import CarouselPage from "./CarouselPage";
import SideBar from "../SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import ProfileModal from "../ProfileModal/ProfileModal";
import userIcon from "../../Assets/images/userIcon.png";
import { ActionGetMediaAndBlogs, ActionIntiallpage } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Home() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [show, setShow] = useState(false);
    const [homeData, setHomeData] = useState([]);
    const [detail, setDetail] = useState([]);
    const [topics, setTopics] = useState([]);
    const [testimonial, setTestimonial] = useState([]);
    const [social, setSocial] = useState([]);
    // const userData = useSelector(state => state.rSession.data);
    const [showProfile, setShowProfile] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const disptach = useDispatch();
    const playVideo = (index) => {
        setActiveIndex(index);
        setIsVideoPlaying(true);
    };

    const handlePrev = () => {
        const newIndex =
            activeIndex === 0 ? testimonial.length - 1 : activeIndex - 1;
        setActiveIndex(newIndex);
        setIsVideoPlaying(false); // Stop video playback when switching testimonials
    };

    const handleNext = () => {
        const newIndex =
            activeIndex === testimonial.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(newIndex);
        setIsVideoPlaying(false); // Stop video playback when switching testimonials
    };

    const MyState = useSelector((state) => state);
    const UserAccessToken = MyState.rServerData.Usertoken
        ? MyState.rServerData.Usertoken
        : null;
    const User = MyState.rServerData.useraccount
        ? MyState.rServerData.useraccount.userDetails
        : [];
    const userData = MyState.rServerData ? MyState.rServerData.home : [];
    const pagecontent = MyState.rServerData.home
        ? MyState.rServerData.home.pagecontent
        : [];
    const HomeSlidercontent =
        MyState.rServerData.home != null ? pagecontent.slidercontent : [];
    const HomeContent =
        MyState.rServerData.home != undefined ? pagecontent.content : [];
    const fashionFeatureContent =
        MyState.rServerData.home != undefined
            ? HomeContent.fashion_feature_content
            : [];
    const HomeTestimonial =
        MyState.rServerData.home != null ? pagecontent.testimonial : [];
    const giannsocialcontent =
        MyState.rServerData.home != undefined ? HomeContent.giannsocialcontent : [];

    const rloading = MyState.rLoading ? MyState.rLoading.home : '';

    var Product_Url = "https://drgarciabiomagnetism.com/products";

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        const carousel = document.querySelector(".carousel");
        if (carousel) {
            const carouselInstance = new window.bootstrap.Carousel(carousel, {
            });
            return () => {
                carouselInstance.dispose();
            };
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (userData) {
            setHomeData(HomeSlidercontent);
            setDetail(HomeContent);
            setTopics(fashionFeatureContent);
            setTestimonial(HomeTestimonial);
            setSocial(giannsocialcontent);
            // setIsLoading(rloading)
        } else {
            disptach(ActionIntiallpage());
            disptach(ActionGetMediaAndBlogs());
        }
    }, [disptach, userData]);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const modalRef = useRef(null);
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowProfile(false);
        }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return (
        <div className="" id="homeHeader">
            {rloading ? (
                <div className="loader-container">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin loader" />
                </div>
            ) : (
                <>
                    <div className="homeInner">
                        <div className="row Innerheader">
                            <div
                                className={`container fixed-top  ${isScrolled ? "scrolled shadow" : ""
                                    }`}
                                id="fixedHeader"
                            >
                                <div className="col-md-6 col-lg-6 headerLogo">
                                    <img src={headerLogo} alt="logo" className="px-3" />
                                </div>
                                <div className="col-md-6 justify-content-end headerRight">
                                    <div className="d-flex align-items-right justify-content-end w-100 gap-1">
                                        {UserAccessToken ? (
                                            <div className="profile" onClick={() => setShowProfile(true)}>
                                                {User?.profile_image === null ? (
                                                    <img src={userIcon} />
                                                ) : (
                                                    <img src={User.profile_image} />
                                                )}
                                            </div>
                                        ) : (
                                            <a href="/login">
                                                <div className="profile">
                                                    <img src={userIcon} />
                                                </div>
                                            </a>
                                        )}
                                        <div ref={modalRef}>
                                            {UserAccessToken && showProfile ? (
                                                <ProfileModal User={User} />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <img
                                            src={hamberg}
                                            alt="logo"
                                            style={{ cursor: "pointer", padding: "10px" }}
                                            onClick={handleShow}
                                        />
                                    </div>
                                </div>
                            </div>
                            {show ? (
                                <SideBar
                                    show={show}
                                    userData={userData}
                                    handleClose={handleClose}
                                />
                            ) : (
                                ""
                            )}
                            <div className="videoContent">
                                <video src={welcomeVideo} controls />
                            </div>
                        </div>

                        <div>
                            <CarouselPage homeData={homeData} />
                        </div>

                        <div id="detailContainer">
                            <div className="row">
                                <div className="col-md-6 drImg">
                                    <img
                                        className="drgarciaImage"
                                        src={drgarcia}
                                        alt="img"
                                        width="420px"
                                        height="420px"
                                    />
                                </div>

                                <div className="col-md-6 py-4">
                                    <img src={footerLogo} alt="img" className="mb-3" />
                                    <h2 className="detailTitle px-4 mb-3">
                                        {detail.learn_fashion_course_title}
                                    </h2>
                                    <div className="detailDescription px-4 mt-5">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: detail.learn_fashion_course_description,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 detailFooter">
                                    {UserAccessToken ? (
                                        <a href="/allCourse">
                                            <button
                                                className="btn btn-primary-outline text-center mb-4 uppercase"
                                                id="applyCourseBtn"
                                            >
                                                {detail.learn_fashion_course_button_text}
                                            </button>
                                        </a>
                                    ) : (
                                        <a href="/login">
                                            <button
                                                className="btn btn-primary-outline text-center mb-4 uppercase"
                                                id="applyCourseBtn"
                                            >
                                                {detail.learn_fashion_course_button_text}
                                            </button>
                                        </a>
                                    )}
                                </div>

                            </div>
                        </div>

                        <div className="bioMag row">
                            <div className="col-md-8 float-left bioMagInner">
                                <div className="container-fluid">
                                    <h2 className="aboutBiomag my-3 px-4">
                                        ABOUT THE BIOMAGNETISM AND BEYOND ONLINE TRAINING LEVEL I &
                                        II
                                    </h2>
                                    <p className="color-white mb-1 px-4">
                                        A leader in the field of Biomagnetism with 15 years of
                                        clinical experience, Dr. Garcia shares his expertise in
                                        practicing Biomagnetism. He explains the basics as well as
                                        how to handle complex issues unlocking the mysteries of each
                                        individual’s unique health concerns.
                                    </p>
                                    <p
                                        className="color-white mb-1 d-block w-100 text-right px-4"
                                        style={{ textAlign: "right" }}
                                    >
                                        This course is on-demand so you may start at any time and
                                        will have access to the videos for 12 months after your
                                        start date.
                                    </p>
                                    <p
                                        className="color-white mb-1 d-block w-100  px-4"
                                        style={{ textAlign: "end" }}
                                    >
                                        You will receive a Certificate of Completion at the end of
                                        the course.{" "}
                                    </p>
                                    <p
                                        className="color-white mb-0 d-block w-100 text-right px-4"
                                        style={{ textAlign: "right" }}
                                    >
                                        This Online Training qualifies you for Dr. Garcia’s Advanced
                                        Training.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="col-md-4 float-left"
                                style={{ display: "grid", marginTop: "130px" }}
                            >
                                <div className="col-md-8 float-left p-0 my-4">
                                    <div className="text-white border-0">
                                        <div className="color-white">
                                            <h5
                                                className="card-title mons-font h1 text-white"
                                                style={{ paddingLeft: "20px" }}
                                            >
                                                25 hours of Material including
                                            </h5>
                                            <p
                                                className="card-text mons-font h5"
                                                style={{
                                                    borderLeft: "1px solid white",
                                                    borderBottom: "1px solid white",
                                                    padding: "0px 0px 20px 20px",
                                                }}
                                            ></p>
                                            <ul style={{ fontSize: "19px" }}>
                                                <li>Biomagnetism Theory</li>
                                                <li>Biomagnetism Pairs</li>
                                                <li>Hands-on Training</li>
                                                <li>Special Topics</li>
                                                <li>Biomagnetism Experiences</li>
                                                <li>Interviews with Dr. Garcia</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="text-center color-white mb-4 text-white">
                                            Cost: $995
                                        </h2>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 text-center">
                                        {UserAccessToken ? (
                                            <a href="/allCourse">
                                                <button
                                                    className="btn btn-primary-outline text-center mb-4 uppercase"
                                                    id="applyCourseBtn"
                                                >
                                                    Register for the course
                                                </button>
                                            </a>
                                        ) : (
                                            <a href="/login">
                                                <button
                                                    className="btn btn-primary-outline text-center mb-4 uppercase"
                                                    id="applyCourseBtn"
                                                >
                                                    Register for the course
                                                </button>
                                            </a>
                                        )}
                                    </div>
                                    <div className="col-md-4">&nbsp;</div>
                                </div>
                            </div>
                        </div>


                        <div className="testimonialContainer">
                            <div>
                                <h2
                                    className="text-center my-5 font-weight-bold uppercase"
                                    style={{ color: "#4c3790" }}
                                >
                                    Topics Included in the Course
                                </h2>
                                <div className="row px-5">
                                    <Slider {...settings}>
                                        {Object.values(topics).map((topic) => (
                                            <div key={topic.id}>
                                                <div className="card p-0" style={{ border: "none" }}>
                                                    <div className="card-img-caption">
                                                        <img
                                                            className="card-img-top"
                                                            src={topic.image}
                                                            alt={topic.title}
                                                            style={{ width: "90%" }}
                                                        />
                                                    </div>
                                                    <br />
                                                    <div className="card-text p-sm-4">
                                                        <h4
                                                            className="mb-4 h4 ritured"
                                                            style={{
                                                                textTransform: "uppercase",
                                                                color: "#4c3790",
                                                                fontWeight: "400",
                                                            }}
                                                        >
                                                            {topic.title}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                                style={{
                                    background:
                                        "linear-gradient(90deg, rgba(255,248,245,1) 0%, rgba(255,248,245,1) 50%, rgba(250,231,230,1) 50%, rgba(250,231,230,1) 100%)",
                                }}
                            >
                                <h2 className="text-uppercase text-center py-5">
                                    <span
                                        className="ritured font-weight-bold"
                                        style={{ color: "#4c3790", fontWeight: "700" }}
                                    >
                                        What our students are saying about our training...
                                    </span>
                                </h2>

                                <div className="d-flex align-items-center justify-content-center">
                                    <div
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                        style={{ width: "760px" }}
                                        id="testimonialCar"
                                    >
                                        <div className="carousel-inner">
                                            {testimonial.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className={
                                                        index === activeIndex
                                                            ? "carousel-item active"
                                                            : "carousel-item"
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            height: "450px",
                                                            maxHeight: "600px",
                                                        }}
                                                    >
                                                        {/* Iframe Video */}
                                                        {isVideoPlaying && activeIndex === index ? (
                                                            <div
                                                                className="video embed-container1 my-0 py-1"
                                                                style={{ position: "relative" }}
                                                            >
                                                                <div className="ratio ratio-16x9">
                                                                    <span data-yt-link={item.link}></span>
                                                                    <iframe
                                                                        className="labelvideoframe videoframe float-left"
                                                                        title="YouTube video player"
                                                                        src={`https://www.youtube-nocookie.com/embed/${item.video_url.split("v=")[1]
                                                                            }?autoplay=1&rel=0&showinfo=0`}
                                                                        frameBorder="0"
                                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                        allowFullScreen
                                                                        style={{ width: "100%", height: "100%" }}
                                                                    ></iframe>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {/* Image */}
                                                                <img
                                                                    src={item.image}
                                                                    alt="testimonial"
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        objectFit: "cover",
                                                                    }}
                                                                />
                                                                {/* Button */}
                                                                <button
                                                                    className="play-button"
                                                                    onClick={() => playVideo(index)}
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "50%",
                                                                        left: "50%",
                                                                        transform: "translate(-50%, -50%)",
                                                                        background: "none",
                                                                        border: "none",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fa fa-play"
                                                                        style={{ fontSize: "48px", color: "white" }}
                                                                    ></i>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <button
                                            className="carousel-control-prev"
                                            type="button"
                                            onClick={handlePrev}
                                        >
                                            <span
                                                className="carousel-control-prev-icon"
                                                aria-hidden="true"
                                            ></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button
                                            className="carousel-control-next"
                                            type="button"
                                            onClick={handleNext}
                                        >
                                            <span
                                                className="carousel-control-next-icon"
                                                aria-hidden="true"
                                            ></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="supplies mb-5">
                            <h2 className="text-center my-5 font-weight-bold uppercase supplieshead">
                                Supplies commonly purchased with the course
                            </h2>
                            <div className="item col-md-12 float-left px-0 mentors">
                                <div className="card p-0">
                                    <div
                                        className="card-img-caption"
                                        style={{
                                            backgroundImage: `url('${imageUrl}')`,
                                            height: "310px",
                                            backgroundSize: "contain",
                                            backgroundPosition: "right",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    ></div>
                                    <div className="card-text p-sm-4">
                                        <h6 className="mb-4  magnet">
                                            Biomagnetism Neodymium Magnets
                                        </h6>
                                        <a
                                            target="_blank"
                                            href={Product_Url}
                                            className="btn btn-primary"
                                            style={{ background: "#6b59a3", textDecoration: "none" }}
                                        >
                                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                                            Buy Magnets
                                        </a>
                                    </div>
                                </div>
                                <div className="card p-0">
                                    <div
                                        className="card-img-caption"
                                        style={{
                                            backgroundImage: `url('${imageUrlShoes}')`,
                                            height: "310px",
                                            backgroundSize: "contain",
                                            backgroundPosition: "right",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    ></div>
                                    <div className="card-text p-sm-4">
                                        <h6 className="mb-4 magnet">Biomagnetism Shoes</h6>
                                        <a
                                            target="_blank"
                                            href={Product_Url}
                                            className="btn btn-primary"
                                            style={{ background: "#6b59a3", border: "none" }}
                                        >
                                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                                            Buy Shoes
                                        </a>
                                    </div>
                                </div>
                                <div className="card p-0">
                                    <div
                                        className="card-img-caption"
                                        style={{
                                            backgroundImage: `url('${imageUrl}')`,
                                            height: "310px",
                                            backgroundSize: "contain",
                                            backgroundPosition: "right",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    ></div>
                                    <div className="card-text p-sm-4">
                                        <h6 className="mb-4 magnet">
                                            Biomagnetism Neodymium Magnets
                                        </h6>
                                        <a
                                            target="_blank"
                                            href={Product_Url}
                                            className="btn btn-primary"
                                            style={{ background: "#6b59a3", border: "none" }}
                                        >
                                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                                            Buy Magnets
                                        </a>
                                    </div>
                                </div>
                                <div className="card p-0">
                                    <div
                                        className="card-img-caption"
                                        style={{
                                            backgroundImage: `url('${imageUrlShoes}')`,
                                            height: "310px",
                                            backgroundSize: "contain",
                                            backgroundPosition: "right",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    ></div>
                                    <div className="card-text p-sm-4">
                                        <h6 className="mb-4 magnet">Biomagnetism Shoes</h6>
                                        <a
                                            target="_blank"
                                            href={Product_Url}
                                            className="btn btn-primary"
                                            style={{ background: "#6b59a3", textDecoration: "none" }}
                                        >
                                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                                            Buy Shoes
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="social_footer">
                            <SocialFooter social={social} />
                        </div>
                    </div>

                    <div style={{ marginTop: "100px" }}>
                        <FooterLayout />
                    </div>
                </>
            )}
        </div>
    );
}

export default Home;