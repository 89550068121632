import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ActionRegister } from '../../store/actions/actions-user';
import { validation, validateEmail } from './Validations';
import './Register.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ActionVerifyCheckEmail, ActiongetCountries, ActiongetStates } from '../../store/actions/actions-server-data';

const LoadingOverlay = () => (
    <div className="payment_overlay">
        <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
            <p className='mt-2 payment_loading'>Please wait...</p>
        </div>
    </div>
);

const Register = () => {
    const [step, setStep] = useState(1);

    const dispatch = useDispatch();
    const userState = useSelector((state) => state);
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [loadingStates, setLoadingStates] = useState(false);
    const [eyeColor, setEyeColor] = useState(false);
    const [eyeCPColor, setEyeCPColor] = useState(false);
    const [updatePasswordType, setupdatePasswordType] = useState("password");
    const [updateConPasswordType, setupdateConPasswordType] = useState("password");
    const verifyEmailLoading = userState.rLoading != undefined ? userState.rLoading.checkEmail : false;
    const verifyEmailCheck = userState.rServerData.checkEmail ? userState.rServerData.checkEmail : false;

    const isLoading = userState.rLoading?.register || false;

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        contact_number: '',
        dob: '',
        password: '',
        confirm_password: '',
        address: '',
        Country: '',
        State: '',
        city: '',
        zipcode: '',
        how_did_you_hear_aboutus: [],
        biomagnetism_interest: "",

    });
    const [errors, setErrors] = useState({
        firstname: '',
        lastName: '',
        email: '',
        contact_number: '',
        dob: '',
        password: '',
        confirm_password: '',
        address: '',
        country: '',
        state: '',
        city: '',
        zipcode: '',
        how_did_you_hear_aboutus: '',
        biomagnetism_interest: "",
        passwordCharacter: ""
    });

    useEffect(() => {
        if (!verifyEmailLoading && formData.email) {
            const emailError = validateEmail(formData.email, verifyEmailCheck);
            setErrors(prevErrors => ({ ...prevErrors, email: emailError }));
        }
    }, [verifyEmailCheck, verifyEmailLoading, formData.email]);

    const [Country, setCountry] = useState(formData.Country);
    const [State, setState] = useState(formData.State);
    const [isAgeValid, setAgeValid] = useState(true);
    const [emailErr, setEmailErr] = useState('');

    const countries = userState.rServerData.countriesList ? userState.rServerData.countriesList : [];
    const states = userState.rServerData.stateList ? userState.rServerData.stateList : [];
    const handleNext = (step, setStep, formData, Country, State, setErrors, verifyEmailCheck) => {
        const isValid = validation(step, setStep, formData, Country, State, setErrors, verifyEmailCheck);
        if (isValid && !errors.email && emailErr == '') {
            setStep(step + 1);  // Only move to the next step if the form is valid
        }
    };

    let formDataLength = formData.password.length;

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const passwordViewHandlerEye = () => {
        if (updatePasswordType === "text") {
            setupdatePasswordType("Password");
            setEyeColor(false);
        } else {
            setupdatePasswordType("text");
            setEyeColor(true);
        }
    };
    const ConformpasswordViewHandlerEye = () => {
        if (updateConPasswordType === "text") {
            setupdateConPasswordType("Password");
            setEyeCPColor(false);
        } else {
            setupdateConPasswordType("text");
            setEyeCPColor(true);
        }
    };

    useEffect(() => {
        dispatch(ActiongetCountries());
        dispatch(ActiongetStates({ country_code: formData.country }))
    }, [])

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;

        if (name === 'email') {
            // Clear error when user starts typing again
            setErrors(prevErrors => ({ ...prevErrors, email: '' }));
        }

        if (type === 'checkbox') {
            const updatedOptions = [...formData.how_did_you_hear_aboutus];
            if (checked) {
                updatedOptions.push(name);
            } else {
                const index = updatedOptions.indexOf(name);
                if (index !== -1) {
                    updatedOptions.splice(index, 1);
                }
            }

            setFormData(prevData => ({
                ...prevData,
                how_did_you_hear_aboutus: updatedOptions
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
        if (name === "country") {
            const selectedCountryObj = countries.find(
                (country) => country.country_name === value
            );
            if (selectedCountryObj) {
                ActiongetStates(selectedCountryObj.country_code);
                setState(selectedCountryObj.country_code)
            }
        }
    };

    const handleCountryChange = (e) => {
        const countryCode = e.target.value;
        setCountry(countryCode)
        dispatch(ActiongetStates({ country_code: countryCode }));
    };

    const handleStateChange = (e) => {
        setState(e.target.value);
    };

    const getMaxDate = () => {
        const today = new Date();
        const maxDate = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());
        return formatDateToLocalISO(maxDate);
    };

    function formatDateToLocalISO(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (step === 3) {
            // Step 3 validation
            if (formData.how_did_you_hear_aboutus.length === 0) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    options: 'Please select an option in How did you hear about us?',
                }));
                return;
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    options: '',
                }))
            }
            if (formData.biomagnetism_interest.trim() === '') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    biomagnetism_interest: 'Please fill the option in Why are you interested in learning about Biomagnetism?',
                }));
                return;
            }
        }
        let str = formData.how_did_you_hear_aboutus.join(',');
        const obj = {
            firstname: formData.firstname,
            lastname: formData.lastname,
            email: formData.email,
            password: formData.password,
            contact_number: formData.contact_number,
            dob: formData.dob,
            address: formData.address,
            country: Country,
            state: State,
            city: formData.city,
            zipcode: formData.zipcode,
            how_did_you_hear_aboutus: str,
            biomagnetism_interest: formData.biomagnetism_interest,
        }
        dispatch(ActionRegister(obj))



    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));

        if (name === 'email') {
            // Immediate validation
            const immediateError = validateEmail(value, []);
            setErrors(prevErrors => ({ ...prevErrors, email: immediateError }));
            // Dispatch API call for server-side validation
            if (!immediateError) {
                dispatch(ActionVerifyCheckEmail({ email: value }))
                .then((response) => {
                  console.log("Email verification success:", response);
                  if (response.success == true) {
                    setEmailErr('')
                  }else{
                    setEmailErr(response.message)
                  }
                })
                .catch((error) => {
                  console.error("Email verification failed:", error);
                  setErrors((prevErrors) => ({ ...prevErrors, email: "Verification failed" }));
                });
            }
        }
    };

    const keyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };
    return (
        <Container fluid>
            <div>
                <h1 className='registerHead'>SIGNUP</h1>
            </div>
            {isLoading && <LoadingOverlay />}
            <Row className="justify-content-md-center">
                <Col md={9}>
                    <Form onSubmit={handleSubmit} id="registerForm">
                        {step === 1 && (
                            <>
                                <div className='row'>
                                    <Form.Group controlId="firstname" className='col-md-6'>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            className='applyFormControl'
                                            type="text"
                                            name="firstname"
                                            value={formData.firstname}
                                            onChange={handleChange}
                                        />
                                        {errors.firstname && <p className="error_text">{errors.firstname}</p>}
                                    </Form.Group>
                                    <Form.Group controlId="lastname" className='col-md-6'>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            className='applyFormControl'
                                            type="text"
                                            name="lastname"
                                            value={formData.lastname}
                                            onChange={handleChange}
                                        />
                                        {errors.lastname && <p className="error_text">{errors.lastname}</p>}
                                    </Form.Group>
                                </div>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <div className='position-relative'>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onBlur={handleBlur}
                                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                        />
                                        {verifyEmailLoading && (
                                            <div className="position-absolute top-50 start-100 translate-middle" style={{ right: '-50px', color: '#6f42c1' }}>
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </div>
                                        )}
                                    </div>
                                    {errors.email && <p className="error_text">{errors.email}</p>}
                                    {emailErr && <p className="error_text">{emailErr}</p>}
                                </Form.Group>
                                <Form.Group controlId="contact_number">
                                    <Form.Label>Contact Number*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="contact_number"
                                        value={formData.contact_number}
                                        onChange={handleChange}
                                    />
                                    {errors.contact_number && <p className="error_text">{errors.contact_number}</p>}
                                </Form.Group>
                                <Form.Group controlId="dob">
                                    <Form.Label>Date of Birth*</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dob"
                                        value={formData.dob}
                                        onChange={handleChange}
                                        min="1900-01-01" // Set min attribute to a reasonable value
                                        max={getMaxDate()} // Set max attribute to 13 years ago
                                        className={`form-control age ${!isAgeValid ? 'is-invalid' : ''}`}
                                        required
                                        autoComplete="dob"
                                        autoFocus
                                    />
                                    {errors.dob && <p className="error_text">{errors.dob}</p>}
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Label>Password*</Form.Label>
                                    <div className="password d-flex ">
                                        <Form.Control
                                            type={updatePasswordType}
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            onKeyDown={keyDown}
                                        />
                                        <span
                                            className="password-toggle eye-icon align-items-center text-center"
                                            onClick={() => passwordViewHandlerEye()}
                                            style={{ cursor: "pointer", position: "relative", zIndex: eyeColor && '100 !important' }}
                                            id={eyeColor ? "eye-color" : ""}
                                        >
                                            <FontAwesomeIcon
                                                className="conform_password"
                                                icon={eyeColor ? faEyeSlash : faEye}
                                                style={{ zIndex: eyeColor && '100 !important' }}
                                            />
                                        </span>
                                    </div>
                                    {errors.password && (
                                        <div>
                                            <p className="error_text">{errors.password}</p>
                                        </div>
                                    )}

                                </Form.Group>
                                <Form.Group controlId="confirm_password">
                                    <Form.Label>Confirm Password*</Form.Label>
                                    <div className="conform_password d-flex ">
                                        <Form.Control
                                            type={updateConPasswordType}
                                            name="confirm_password"
                                            className=""
                                            value={formData.confirm_password}
                                            onChange={handleChange}
                                            onKeyDown={keyDown}
                                        />


                                        <span
                                            className="password-toggle eye-icon align-items-center text-center"
                                            onClick={() => ConformpasswordViewHandlerEye()}
                                            style={{ cursor: "pointer", position: "relative" }}
                                            id={eyeColor ? "eye-color" : ""}
                                        >
                                            <FontAwesomeIcon
                                                className="conform_password"
                                                icon={eyeCPColor ? faEyeSlash : faEye}
                                            />
                                        </span>

                                    </div>

                                    {errors.confirm_password && (
                                        <p className="error_text">{errors.confirm_password}</p>
                                    )}
                                </Form.Group>
                            </>
                        )}
                        {step === 2 && (
                            <>
                                <Form.Group controlId="address">
                                    <Form.Label>Address*</Form.Label>
                                    <Form.Control
                                        className='applyFormControl'
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                    {errors.address && <p className="error_text">{errors.address}</p>}
                                </Form.Group>

                                <Form.Group controlId="country">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select
                                        name="country"
                                        value={Country}
                                        onChange={handleCountryChange}
                                        required
                                    >
                                        <option value="">Select Country</option>
                                        {countries.length > 0 &&
                                            countries.map((items) => (
                                                <option key={items.id} value={items.country_code}>
                                                    {items.country_name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    {errors.country && (
                                        <p className="error_text">{errors.country}</p>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="state">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        name="state"
                                        value={State}
                                        onChange={handleStateChange}
                                        required
                                    >
                                        <option value="">Select State</option>
                                        {states && states.length > 0 &&
                                            states.map((record) => (
                                                <option key={record.id} value={record.state_code}>
                                                    {record.state_name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    {errors.state && <p className="error_text">{errors.state}</p>}
                                </Form.Group>

                                <Form.Group controlId="city">
                                    <Form.Label>City*</Form.Label>
                                    <Form.Control
                                        className='applyFormControl'
                                        type="text"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                    />
                                    {errors.city && <p className="error_text">{errors.city}</p>}
                                </Form.Group>
                                <Form.Group controlId="zipcode">
                                    <Form.Label>Zipcode*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zipcode"
                                        value={formData.zipcode}
                                        onChange={handleChange}
                                    />
                                    {errors.zipcode && <p className="error_text">{errors.zipcode}</p>}
                                </Form.Group>
                            </>
                        )}
                        {step === 3 && (
                            <>
                                <Form.Group controlId="how_did_you_hear_aboutus">
                                    <Form.Label>How did you hear about us? *</Form.Label>
                                    <Form.Group controlId="e_email">
                                        <Form.Check
                                            type='checkbox'
                                            name="e_email"
                                            id="e_email"
                                            label="Email"
                                            checked={formData.how_did_you_hear_aboutus.includes('e_email')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="facebook">
                                        <Form.Check
                                            type='checkbox'
                                            name="facebook"
                                            id="facebook"
                                            label="Facebook"
                                            checked={formData.how_did_you_hear_aboutus.includes('facebook')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="twitter">
                                        <Form.Check
                                            type='checkbox'
                                            name="twitter"
                                            id="twitter"
                                            label="Twitter"
                                            checked={formData.how_did_you_hear_aboutus.includes('twitter')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="google">
                                        <Form.Check
                                            type='checkbox'
                                            name="google"
                                            id="google"
                                            label="Google"
                                            checked={formData.how_did_you_hear_aboutus.includes('google')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="link">
                                        <Form.Check
                                            type='checkbox'
                                            name="link"
                                            id="link"
                                            label="www.drgarciabiomagnetism.com"
                                            checked={formData.how_did_you_hear_aboutus.includes('link')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="youTube">
                                        <Form.Check
                                            type='checkbox'
                                            name="youTube"
                                            label="YouTube"
                                            id="youTube"
                                            checked={formData.how_did_you_hear_aboutus.includes('youTube')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="Colleague">
                                        <Form.Check
                                            type='checkbox'
                                            name="Colleague"
                                            id="Colleague"
                                            label="Colleague/I'm in the industry"
                                            checked={formData.how_did_you_hear_aboutus.includes('Colleague')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="therapy">
                                        <Form.Check
                                            type='checkbox'
                                            name="therapy"
                                            id="therapy"
                                            label="From a therapy session with Dr. Garcia"
                                            checked={formData.how_did_you_hear_aboutus.includes('therapy')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="friend">
                                        <Form.Check
                                            type='checkbox'
                                            name="friend"
                                            id="friend"
                                            label="A friend"
                                            checked={formData.how_did_you_hear_aboutus.includes('friend')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="other">
                                        <Form.Check
                                            type='checkbox'
                                            name="other"
                                            id="other"
                                            label="Other"
                                            checked={formData.how_did_you_hear_aboutus.includes('other')}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Form.Group>
                                {errors.options && <p className="error_text">{errors.options}</p>}
                                <Form.Group controlId="biomagnetism_interest">
                                    <Form.Label>Why are you interested in learning about Biomagnetism? *</Form.Label>
                                    <Form.Control as="textarea" name="biomagnetism_interest" value={formData.biomagnetism_interest} onChange={handleChange} />
                                    {errors.biomagnetism_interest && <p className="error_text">{errors.biomagnetism_interest}</p>}
                                </Form.Group>
                                <div>
                                    <p>By registering you will be accepting terms of use and Privacy policy as well you will be opted for receiving Newsletter</p>
                                </div>
                            </>
                        )}
                        <div className='d-flex justify-content-end mt-3'>
                            {/* Display Previous button for step 2 and 3 */}
                            {step > 1 && <Button className='nextBtn mx-3' onClick={handlePrevious}>Previous</Button>}
                            {/* Display Submit button for step 3 */}
                            {step === 3 && <Button className='nextBtn' type="submit">Submit</Button>}
                            {/* Display Next button for all steps except 3 */}
                            {step < 3 && <Button className='nextBtn' disabled={verifyEmailLoading} onClick={() => handleNext(step, setStep, formData, Country, State, setErrors, formDataLength, verifyEmailCheck)}>Next</Button>}
                        </div>
                    </Form>
                </Col>
            </Row>
            <div className='col-md-12 text-center mt-3 mb-5'>
                <span className='mt-2 mb-5 already'>Already have an Account ? <a href='/login' className='registerLogin'> LOGIN</a></span>
            </div>
        </Container>
    );
}

export default Register
