import React, { useEffect, useState } from 'react';
import './CourseDetailPage.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ActionCourseModule, ActiongetCourseProgressofCurrentOrder, ActiongetCourseVideos, ActiongetCurrentActiveOrderDetails, ActionUpdateCourseSession } from '../../store/actions/actions-server-data';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { store } from '../../store/store';
import axios from 'axios';

function CourseDetailPage() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [modulesCard, setModulesCard] = useState([]);
    const [progress, setProgress] = useState([]);
    const [lecturesBySession, setLecturesBySession] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const userState = useSelector(state => state);
    const courseModule = userState.rServerData.courseModule || [];
    const userDetails = userState.rServerData.userDataLogggedIn || [];
    const userAccessToken = userState.rServerData.Usertoken || null;
    const { orders } = userState.rServerData.IntiallcourseDetails;
    const isLoadingOrder = userState.rLoading?.CurrentOrder || false;
    const isLoadingOrderProgrress = userState.rLoading?.getCourseProgress || false;
    const isWatchLoading = userState?.isWatchLoading ?? false;

    const param = userState.rServerData?.courseModule || null;

    const { watchVideos } = userState.rServerData;
    const { CurrentOrder } = userState.rServerData;
    const { videoData: { videoList: { lectureList, futureLectureList, pastLectureList, currentLecture } } } = watchVideos;
    const concatenatedArray = [...lectureList, ...futureLectureList, ...pastLectureList];

    useEffect(() => {
        if (courseModule && courseModule.data) {
            setModulesCard(courseModule.data.coursesession);
            setProgress(courseModule.data.courseprogress);
        }
    }, [courseModule]);

    useEffect(() => {
        const param = {
            token: userAccessToken,
            id: orders[0].id,
            orderid: orders[0].orderid
        }
        dispatch(ActionCourseModule(param))
        dispatch(ActiongetCourseProgressofCurrentOrder({ userId: userDetails.id, token: userAccessToken }))
        dispatch(ActiongetCurrentActiveOrderDetails({ userId: userDetails.id, courseId: 10, orderId: orders[0].orderid, token: userAccessToken }))
        setLecturesBySession(groupBySessionId(concatenatedArray));
        // const orders = userState.rServerData?.IntiallcourseDetails != undefined ? userState.rServerData.IntiallcourseDetails.orders : [];
        dispatch(ActionUpdateCourseSession({ userId: userDetails.id, courseId: 10, sessionId: CurrentOrder?.session_progress, lectureId: CurrentOrder?.lecture_progress, orderId: orders[0].orderid, token: userAccessToken, testimonialid: null }));
    }, []);

    const handleNavigateToVideo = async (item, type) => {
        dispatch(ActionUpdateCourseSession({ userId: userDetails.id, courseId: 10, sessionId: CurrentOrder?.session_progress, lectureId: CurrentOrder?.lecture_progress, orderId: orders[0].orderid, token: userAccessToken, testimonialid: null }));
        setIsLoading(true);
        localStorage.removeItem('selectedVideo')
        try {
            await new Promise((resolve, reject) => {
                dispatch(ActiongetCourseVideos({
                    courseid: 10,
                    userId: userDetails.id,
                    sessionid: CurrentOrder.session_progress,
                    lectureid: CurrentOrder.lecture_progress,
                    testimonialid: null,
                    videoid: null,
                    token: userAccessToken
                }));

                const unsubscribe = store.subscribe(() => {
                    if (!userState.rLoading?.isWatchLoading) {
                        unsubscribe();
                        resolve();
                    }
                });
            });
            var lastVideoObject;

            const filteredSessions = lecturesBySession.filter(
                session => session.length > 0 && session[0].session_id === item.id
            );

            if (filteredSessions.length > 0) {
                const progressEntry = { user_id: userDetails.id, order_id: orders[0].orderid };

                const response = await axios.post('https://lmsreactbackend.helenzysinc.com/getuser-progress', progressEntry, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userAccessToken}` } });
                if (response.data.success) {
                    const moduleProgress = response.data.data;
                    lastVideoObject = moduleProgress.find(list => list.module_session_id == item.id);
                } else {
                    lastVideoObject = null;
                }
            } else {
                lastVideoObject = null;
            }

            if (type == 'completed') {
                if (lastVideoObject) {
                    if(lastVideoObject.module_sublecture_id){
                       var TabId = item.id;
                       await history.push({ pathname: '/watchVideos', state: { id: TabId, lastVideoObject: lastVideoObject.module_lecture_id, lastSubVideoObject : lastVideoObject.module_sublecture_id } });
                    }else{
                        var TabId = item.id;
                        await history.push({ pathname: '/watchVideos', state: { id: TabId, lastVideoObject: lastVideoObject.module_lecture_id } });
                    }
                }else{
                    const lastVideo = filteredSessions[0][filteredSessions[0].length - 1];
                    var TabId = item.id;
                    await history.push({ pathname: '/watchVideos', state: { id: TabId, lastVideoObject: lastVideo.id } });          
                }
            } else {
                if (lastVideoObject && type !== 'yetstart') {
                    if(lastVideoObject.module_sublecture_id){
                        console.log('lastVideoObject if condionton inside',lastVideoObject);
                        var TabId = item.id;
                        await history.push({ pathname: '/watchVideos', state: { id: TabId, lastVideoObject: lastVideoObject.module_lecture_id, lastSubVideoObject : lastVideoObject.module_sublecture_id } });
                    }else{
                        console.log('lastVideoObject else condionton ',lastVideoObject);
                        var TabId = item.id;
                        await history.push({ pathname: '/watchVideos', state: { id: TabId, lastVideoObject: lastVideoObject.module_lecture_id  } });
                    }
                }else{
                    console.log('direct else',lastVideoObject);
                   var TabId = item.id;
                   await history.push({ pathname: '/watchVideos', state: { id: TabId } });
                }
            }
        } catch (error) {
            console.error("Error navigating to video:", error);
        } finally {
            setIsLoading(false); // Hide loading state
        }
    };

    function groupBySessionId(lectures) {
        return Object.values(lectures.reduce((acc, lecture) => {
            const { session_id, ...lectureData } = lecture;
            if (!acc[session_id]) {
                acc[session_id] = [];
            }
            acc[session_id].push({ session_id, ...lectureData });
            return acc;
        }, {}));
    }

    return (
        <div className='container-fluid mb-5'>
            {(isLoading || isLoadingOrder || isLoadingOrderProgrress || isWatchLoading) ? (
                <div className="payment_overlay">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
                        <p className='mt-2 payment_loading' >Please wait...</p>
                    </div>
                </div>
            ) : ("")}
            <div className='detailContainer'>
                <h1 className='mb-5'>Biomagnetism and Beyond Online Training Level I & II - <span style={{ color: '#402F7B', fontWeight: '700' }}>Dr Luis F Garcia</span></h1>
                <p className='mb-3'>Biomagnetism is the science of utilizing magnetic fields for therapeutic benefits on living organisms.</p>
                <div className='moduleContainer'>
                    <div className='moduleCards row'>
                        {modulesCard.map((item, id) => (
                            <div key={id} className='moduleOne col-md-6 col-lg-4'>
                                {progress.session_progress == 1 && progress.lecture_progress == 1 && id == 0 ? (
                                    <div onClick={() => handleNavigateToVideo(item, 'yetstart')}>
                                        <img src={item.video_image} alt='img' />
                                        <div className='moduleOverlay px-4 py-3'>
                                            <h5 className='fw-bold'>MODULE-{id + 1}</h5>
                                            <h6>{item.session_name}</h6>
                                            <p>STATUS | YET TO START</p>
                                        </div>
                                    </div>
                                ) :
                                    progress.session_progress == item.id && progress.lecture_progress < 57 ? (
                                        <div onClick={() => handleNavigateToVideo(item, 'inprogress')}>
                                            <img src={item.video_image} alt='img' />
                                            <div className='moduleOverlay px-4 py-3'>
                                                <h5 className='fw-bold'>MODULE-{id + 1}</h5>
                                                <h6>{item.session_name}</h6>
                                                <p>STATUS | IN PROGRESS</p>
                                            </div>
                                        </div>
                                    ) : progress.session_progress > item.id || progress.lecture_progress >= 57 ? (
                                        <div onClick={() => handleNavigateToVideo(item, 'completed')}>
                                            <img src={item.video_image} alt='img' />
                                            <div className='moduleOverlay px-4 py-3'>
                                                <h5 className='fw-bold'>MODULE-{id + 1}</h5>
                                                <h6>{item.session_name}</h6>
                                                <p>STATUS | COMPLETED</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <a href="javascript:void(0)">
                                            <img src={item.video_image} alt='img' />
                                            <div className='moduleOverlay px-4 py-3'>
                                                <h5 className='fw-bold'>MODULE-{id + 1}</h5>
                                                <h6>{item.session_name}</h6>
                                                <p>STATUS | LOCKED</p>
                                            </div>
                                        </a>
                                    )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CourseDetailPage;
