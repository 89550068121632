import React, { useEffect, useMemo, useState } from 'react';
import './ProfileModal.css';
import { ActionLogout } from '../../store/actions/actions-user';
import { useDispatch, useSelector } from 'react-redux';
// import { ActiongetUserDetails } from '../../store/actions/actions-server-data';
import userIcon from '../../Assets/images/userIcon.png';

function ProfileModal({ User }) {
    const dispatch = useDispatch();
    const MyState = useSelector((state) => state);
    const MyOrders = MyState.rServerData.IntiallcourseDetails ? MyState.rServerData.IntiallcourseDetails?.orders : [];
    // const userData = MyState.rServerData.userDataLogggedIn ? MyState.rServerData.userDataLogggedIn : null;
    // const ordersData = MyState.rServerData?.mycourse?.orders;
    // const UserAccessToken = MyState.rServerData.Usertoken ? MyState.rServerData.Usertoken : null;
    // const UserDetails = useMemo(() => MyState.rServerData.userDataLogggedIn || [], [MyState.rServerData.userDataLogggedIn]);
    const UserData = MyState.rServerData.useraccount ? MyState.rServerData.useraccount.userDetails : {};
    // const [orders, setOrders] = useState('')


    // useEffect(() => {
    // setOrders(MyOrders)
    // dispatch(ActiongetUserDetails({ userId: UserDetails.id, email: UserDetails.email, token: UserAccessToken }));
    // }, [])
    return (
        <div className='profileModal'>
            <div className='profileInner'>
                <div className='picContainer'>
                    <div className='up_profile'>
                        {UserData?.profile_image == null ? (
                            <img src={userIcon} />
                        ) : (
                            <img src={UserData.profile_image} alt="Profile" />
                        )}
                    </div>
                    <div className='nameAndemail '>
                        <span className='userNameModal'>
                            {UserData.firstname} {UserData.lastname} 
                        </span>
                        <span className='userEmailModal'>
                            {UserData.email?.length > 24 ? UserData.email.substring(0, 20) + '...' : UserData.email}
                        </span>
                    </div>
                </div>
            </div>
            <div className='accountContainer mt-3 w-100'>
                <div>
                    <p className='px-4 py-2'>
                        <a href="/useraccount" >My Account</a>
                    </p>
                    <p className='px-4 py-2 mt-2'>
                        <a href="/myCoursePage">My Course</a>
                    </p>
                    <p
                        className="px-4 py-2 mt-2"
                        onClick={() => dispatch(ActionLogout())}
                        style={{ border: 'none' }}
                    >
                        Logout
                    </p>
                </div>
            </div>
        </div>

    )
}

export default ProfileModal
