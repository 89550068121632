import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CommunityPostDetails.css';
import { ActionCommunityComment, ActionCommunitySubComment, ActionGetCommunity, ActionPostCommentLikes, ActionPostCommunity, ActionPostCommunityDetails, ActionPostCommunityLikes, ActionPostCommunityLikesApi, ActionPostSubCommentLikes } from '../../store/actions/actions-server-data';
import { useParams } from 'react-router';
import CommunityPost from '../CommunityPost/CommunityPost';
import userIcon from '../../Assets/images/userIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function CommunityPostDetails() {
    let { id } = useParams();
    const [comment, setComment] = useState('');
    const [replyBoxMap, setReplyBoxMap] = useState({});
    const [subCommentMap, setSubCommentMap] = useState({});

    const dispatch = useDispatch();
    const userState = useSelector(state => state);
    const isLoading = userState.rLoading ? userState.rLoading?.communityCommentLikes : '';
    const isCommunityPostLoading = userState.rLoading ? userState.rLoading?.communityPost : '';
    const isCommuityPostLoading = userState.rLoading ? userState.rLoading?.communityLikes : '';
    const isSubCommuityLoading = userState.rLoading ? userState.rLoading?.subcommunityCommentLikes : '';
    const isCommentLoading = userState.rLoading ? userState.rLoading?.comment : '';
    const isSubCommentLoading = userState.rLoading ? userState.rLoading?.subcomment : '';
    const userData = userState.rServerData ? userState.rServerData.Usertoken : null;
    const postDetails = userState.rServerData ? userState.rServerData.communityPost?.posts : [];
    const singlePostDetails = userState.rServerData ? userState.rServerData.communityDetail?.posts : [];
    const User = userState.rServerData.useraccount ? userState.rServerData.useraccount.userDetails : [];
    const userDetails = userState.rServerData.userDataLogggedIn || [];
    // const subComments = userState.rServerData ? userState.rServerData.communityDetail?.posts?.data : [];
    const [likeId, setLikeId] = useState(null);

    // Get the entire URL from the browser
    const url = window.location.href;

    // Extract the values after '/thread/'
    const threadValues = url.split('/thread/')[1]; // "1/2/147"

    // If you need these values as an array:
    const threadValuesArray = threadValues.split('/'); // ["1", "2", "147"]
    console.log('threadValuesArray', threadValuesArray.length);
    useEffect(() => {
        const param = {
            categoryid: threadValuesArray ? threadValues[0] : '',
            topicid: threadValuesArray ? threadValuesArray[1] : '',
            token: userData,
            data: threadValuesArray.length    
        };
        if(threadValuesArray.length >= 3){
            dispatch(ActionPostCommunity(param));
        }
        const obj = {
            token: userData,
            postId: threadValuesArray ? threadValuesArray[2] : postDetails[0].id,
            topicid: threadValuesArray ? threadValuesArray[1] : postDetails[0].topic_id,
            categoryid: threadValuesArray ? threadValuesArray[0] : postDetails[0].category_id
        }
        dispatch(ActionPostCommunityDetails(obj));
    }, [isCommuityPostLoading])

    const handleOpenReplyBox = (postId) => {
        setReplyBoxMap(prevState => ({
            ...prevState,
            [postId]: !prevState[postId]
        }));
        setSubCommentMap(false)
    };

    const handleOpenSubCommentBox = (commentId) => {
        setSubCommentMap(prevState => ({
            ...prevState,
            [commentId]: !prevState[commentId]
        }));
        setReplyBoxMap(false)
    };

    const handleSubmit = (e, item) => {
        e.preventDefault();
        const obj = {
            token: userData,
            userId: User.id,
            post_id: id,
            comment: comment,
        };
        if (obj.comment === '' || obj.comment.trim() === '') {
            alert('Please enter something...');
        } else {
            dispatch(ActionCommunityComment(obj));
            const param = {
                categoryid: postDetails[0].category_id,
                topicid: postDetails[0].topic_id,
                token: userData
            }
            dispatch(ActionPostCommunityLikesApi(param))
        }
        setReplyBoxMap(prevState => ({
            ...prevState,
            [item.id]: false
        }));
        setComment('');
    };

    const handleVote = async (item, type) => {
        setLikeId(item.id)
        let obj = {
            token: userData,
            type: type === 'up' ? 'up' : 'down',
            userId: userDetails.id,
            category: "comments",
            note: item.note,
            dataId: item.id
        };

        dispatch(ActionPostCommentLikes(obj));
    };


    const handlemainVote = async (item, type) => {
        setLikeId(item.id)
        let obj = {
            token: userData,
            type: type === 'up' ? 'up' : 'down',
            userId: userDetails.id,
            category: "posts",
            note: item.note,
            dataId: item.id
        };

        dispatch(ActionPostCommunityLikes(obj));
    };

    const handleSubCommentSubmit = (e, commentId, items) => {
        e.preventDefault();
        const obj = {
            token: userData,
            userId: User.id,
            comment_id: commentId,
            comments: comment
        }
        if (obj.comments === '' || obj.comments.trim() === '') {
            alert('Please enter something...');
        } else {
            dispatch(ActionCommunitySubComment(obj));
            const param = {
                categoryid: postDetails[0].category_id,
                topicid: postDetails[0].topic_id,
                token: userData
            }
            dispatch(ActionPostCommunityLikesApi(param))
        }
        setSubCommentMap(prevState => ({
            ...prevState,
            [commentId]: false
        }));
    };

    const handleSubCommentLike = (subItem) => {
        setLikeId(subItem.id);
        const data = {
            token: userData,
            type: "up",
            userId: userDetails.id,
            category: "commentsloop",
            note: subItem.note,
            dataId: subItem.id
        }
        dispatch(ActionPostSubCommentLikes(data));
        const param = {
            categoryid: postDetails[0].category_id,
            topicid: postDetails[0].topic_id,
            token: userData
        }
        dispatch(ActionPostCommunityLikesApi(param))
    }

    const handleSubCommentDislike = (subItem) => {
        const data = {
            token: userData,
            type: "down",
            userId: userDetails.id,
            category: "commentsloop",
            note: subItem.note,
            dataId: subItem.id
        }
        dispatch(ActionPostSubCommentLikes(data));
        const param = {
            categoryid: postDetails[0].category_id,
            topicid: postDetails[0].topic_id,
            token: userData
        }
        dispatch(ActionPostCommunityLikesApi(param))
    }

    // useEffect(() => {
    //     const param = {
    //         categoryid: postDetails[0].category_id,
    //         topicid: postDetails[0].topic_id,
    //         token: userData
    //     }
    //     dispatch(ActionPostCommunityLikesApi(param));
    // }, [isLoading]);
    const filteredDetails = postDetails && postDetails.filter(item => item.id == id);
    return (
        <div className='p-5'>
            <div className='container-fluid'>
                {isCommentLoading || isSubCommentLoading || isCommunityPostLoading ? (
                    <div className="payment_overlay">
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
                            <p className='mt-2 payment_loading' >Please wait...</p>
                        </div>
                    </div>) : ''
                }
                {isCommunityPostLoading ? <div className='postDetailsContainer'>Please wait.</div> : postDetails && postDetails.length > 0 && postDetails.map((item, idx) => {
                    return (
                        <div key={idx}>
                            <div className='row w-100'>
                                <div className='col-md-1'>
                                    <div className='d-flex align-items-center justify-content-center flex-column text-center'>
                                        <div className='profile'>
                                            {singlePostDetails.filter(item => item.id == id).map(items =>
                                                items?.user?.profile_image === null ? <img src={userIcon} /> : <img src={items?.user?.profile_image} />)}
                                        </div>
                                        <div>
                                            {singlePostDetails.filter(item => item.id == id).map(items => (
                                                <p key={items.user.id}>from {items.user.firstname}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-10'>
                                    <p className='postTitle'>{item.post_description}</p>
                                    <div className='repliesContainer'>
                                        <div>
                                            <i className="fa-solid fa-thumbs-up px-1" onClick={() => handlemainVote(item, 'up')}>
                                                <span className='px-1' style={{ color: 'black' }}>{item.up_vote}</span>
                                            </i>
                                            <i className="fa-solid fa-thumbs-down px-1" onClick={() => handlemainVote(item, 'down')}>
                                                <span className='px-1' style={{ color: 'black' }}>{item.down_vote}</span>
                                            </i>
                                            {likeId == item.id && isCommuityPostLoading ? <span className="px-1" style={{ color: '#6f42c1' }}>
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </span> : ''}
                                        </div>
                                        <div className='px-2'>
                                            <p className=' replyBtn' onClick={() => handleOpenReplyBox(item.id)}><i className="fa fa-reply px-0">
                                            </i> Reply</p>
                                        </div>
                                    </div>
                                    <hr className='mt-3' />
                                </div>
                            </div>
                            {replyBoxMap[item.id] && (
                                <div>
                                    <form onSubmit={(e) => handleSubmit(e, item)}>
                                        <div>
                                            <textarea
                                                name='comment'
                                                rows={4}
                                                className='postDtailTextarea'
                                                placeholder='Add a comment ..'
                                                onChange={(e) => setComment(e.target.value)}
                                                style={{ borderColor: '#baa3a3', outline: 'none' }}
                                            />
                                        </div>
                                        <button type='submit' className='commentBtn mt-3'>ADD COMMENT</button>
                                    </form>
                                </div>
                            )}
                        </div>
                    )
                })}
                {isCommunityPostLoading ? '' : filteredDetails && filteredDetails.length > 0 && filteredDetails.map(items => {
                    return (
                        <div key={items.id}>
                            <div className='row w-100' style={{ paddingLeft: '110px' }}>
                                <div className='col-md-12'>
                                    {items.comments && items.comments.length > 0 ? (
                                        items.comments.map(data => {
                                            return (
                                                <div className='repliesContainer row' key={data.id}>
                                                    <div className='col-md-1 text-center'>
                                                        <div className='d-flex align-items-center justify-content-center flex-column'>
                                                            <div className='profile'>
                                                                {data?.user?.profile_image === null ? <p className='userFirstLetter pt-3'>{data?.user?.firstname?.split('')[0]}</p> : <img src={data?.user?.profile_image} />}
                                                            </div>
                                                            <div>
                                                                <p>by {data.user.firstname}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-10'>
                                                        <p className='postTitle'>{data.comment}</p>
                                                        <div className='px-2'>
                                                            <i className="fa-solid fa-thumbs-up px-1" onClick={() => handleVote(data, 'up')}>
                                                                <span className='px-1' style={{ color: 'black' }}>{data.up_vote}</span>
                                                            </i>
                                                            <i className="fa-solid fa-thumbs-down px-1" onClick={() => handleVote(data, 'down')}>
                                                                <span className='px-1' style={{ color: 'black' }}>{data.down_vote}</span>
                                                            </i>
                                                            {likeId == data.id && isLoading ? <span className="px-2" style={{ color: '#6f42c1' }}>
                                                                <i className="fa fa-spinner fa-spin"></i>
                                                            </span> : ''}
                                                            <span className='mt-3 replyBtn' style={{ color: 'black' }} onClick={() => handleOpenSubCommentBox(data.id)}><i className="fa fa-reply px-0">
                                                            </i> Reply</span>
                                                        </div>
                                                    </div>

                                                    <hr className='mt-3' />

                                                    {/* sub comments loop */}
                                                    <div style={{ paddingLeft: '130px' }}>
                                                        {data.comments_loops.map((sub) => sub && sub.comments ? (
                                                            <div className='row' key={sub.id}>
                                                                <div className='col-md-1 text-center'>
                                                                    <div className='d-flex align-items-center justify-content-center flex-column'>
                                                                        <div className='profile'>
                                                                            {sub?.user?.profile_image === null ? <p className='userFirstLetter pt-3'>{User?.firstname?.split('')[0]}</p> : <img src={User.profile_image} />}
                                                                        </div>
                                                                        <div>
                                                                            <p>by {User.firstname}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-10'>
                                                                    <p className='postTitle'>{sub.comments}</p>
                                                                    <div className='px-2'>
                                                                        <i className="fa-solid fa-thumbs-up px-1" onClick={() => handleSubCommentLike(sub)}>
                                                                            <span className='px-1' style={{ color: 'black' }}>{sub.up_vote}</span>
                                                                        </i>
                                                                        <i className="fa-solid fa-thumbs-down px-1" onClick={() => handleSubCommentDislike(sub)}>
                                                                            <span className='px-1' style={{ color: 'black' }}>{sub.down_vote}</span>
                                                                        </i>
                                                                        {likeId == sub.id && isSubCommuityLoading ? <span className="px-2" style={{ color: '#6f42c1' }}>
                                                                            <i className="fa fa-spinner fa-spin"></i>
                                                                        </span> : ''}
                                                                    </div>
                                                                </div>
                                                                <hr className='mt-3' />
                                                            </div>
                                                        ) : '')}
                                                    </div>

                                                    {subCommentMap[data.id] && (
                                                        <div className="col-md-12" style={{ paddingLeft: "100px", paddingTop: "20px" }}>
                                                            {/* Render sub-comment reply box here */}
                                                            <form onSubmit={(e) => handleSubCommentSubmit(e, data.id, items)}>
                                                                <div>
                                                                    <textarea
                                                                        name='comment'
                                                                        rows={4}
                                                                        className='postDtailTextarea'
                                                                        placeholder='Add a comment ..'
                                                                        onChange={(e) => setComment(e.target.value)}
                                                                        style={{ borderColor: '#baa3a3', outline: 'none' }}
                                                                    />
                                                                </div>
                                                                <button type='submit' className='commentBtn mt-3'>ADD COMMENT</button>
                                                            </form>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })) : ''}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CommunityPostDetails;
