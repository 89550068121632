import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import ROUTES from "./configs/routes";
import Login from "../src/pages/Login/Login";
import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import ForgotPassword from "./pages/Forgot_Pwd/ForgotPassword";
import Layout from "./pages/Layout/Layout";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import AllCourse from "./pages/AllCoursePage/AllCourse";
import HeaderLayout from "./pages/HeaderLayout/HeaderLayout";
import FooterLayout from "./pages/FooterLayout/FooterLayout";
import ApplyCourse from "./pages/ApplyCoursePage/ApplyCourse";
import MyCoursePage from "./pages/MyCourse/MyCoursePage";
import banner from '../src/Assets/images/pages/my-course/banner.png';
import commonBanner from '../src/Assets/images/banner.png';
import CourseDetailPage from "./pages/CourseDetail/CourseDetailPage";
import WatchVideoPage from "./pages/WatchVideo/WatchVideoPage";
import UserAccountPage from "./pages/UserAccount/UserAccountPage";
import AboutUs from "./pages/AboutPage/AboutUs";
import ContactUs from "./pages/ContactUsPage/ContactUs";
import CourseOverview from "./pages/CourseOverviewPage/CourseOverview";
import MediaBlog from "./pages/Media_BlogPage/MediaBlog";
import HelpVideo from "./pages/HelpVideoPage/HelpVideo";
import PrivateRoute from './PrivateRoute';
import Terms_Condition from "./pages/Terms_Condtions/Terms_Condition";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RefundCancel from "./pages/RefundCancellation/RefundCancel";
import Faq from "./pages/FAQPage/Faq";
import faqBanner from "./Assets/images/pages/frequently-asked-questions/banner.png";
import Community from "./pages/CommunityPage/Community";
import CommunityPost from "./pages/CommunityPost/CommunityPost";
import CommunityPostDetails from "./pages/CommunityPostDetail/CommunityPostDetails";
import AssesmentPage from "./pages/Assesments/AssesmentPage";
import AssesmentResult from "./pages/AssesmentResult/AssesmentResult";
import assesmentResultPic from './Assets/images/pages/assessment-result/banner.png';
import AuthorizePay from "./pages/AuthorizePayPage/AuthorizePay";
import PaymentComplete from "./pages/PaymentComplete/PaymentComplete";
import OrderInvoice from "./pages/UserAccount/OrderInvoice";
import RenwedOrderInvoice from "./pages/UserAccount/RenwedOrderInvoice";
import GiftedInvoice from "./pages/UserAccount/GiftedInvoice";
import CommunitySearch from "./pages/CommunitySearch/CommunitySearch";
import { useSelector } from "react-redux";
import PaymentFailed from "./pages/PaymentFailed/PaymentFailed";
import FeedbackPage from "./pages/FeedbackPage/FeedbackPage";
import DownladAssesmentAnswers from "./pages/Assesment_Answers/DownladAssesmentAnswers";

const Routes = (props) => {
  const isAuthenticated = useSelector((state) => state);
  const UserOrder = isAuthenticated.rServerData.IntiallcourseDetails?.orders || [];
  const accessToPaymentPage = props.history.location.state;
  const accessToAssesmentPage = props.history?.location.search;
  const userChoices = isAuthenticated.rServerData.userChoices ? isAuthenticated.rServerData.userChoices.answers_list : '';
  // const resetPasswordAuth = isAuthenticated.router.location.state?.token;
  const Usertoken = isAuthenticated?.rServerData?.Usertoken;
  return (
    <Switch>
      <Route exact path={ROUTES.Home}>
        <Home />
      </Route>
      <Route exact path={ROUTES.Login}>
        <Layout route="login">
          <Login />
        </Layout>
      </Route>

      <Route exact path={ROUTES.Register}>
        <Layout route="register">
          <Register />
        </Layout>
      </Route>
      <Route exact path={ROUTES.ForgotPassword}>
        <Layout route="forgotPassword">
          <ForgotPassword />
        </Layout>
      </Route>
      <Route exact path={ROUTES.ResetPassword}>
        <Layout>
          <ResetPassword />
        </Layout>
      </Route>
      <Route exact path={ROUTES.AllCourse}>
        <HeaderLayout data="ALL COURSES" backgroundImage={commonBanner} />
        <PrivateRoute exact path={ROUTES.AllCourse} component={AllCourse} />
        <FooterLayout />
      </Route>
      <Route exact path={ROUTES.ApplyCourse}>
        <HeaderLayout data="APPLY FOR COURSE" backgroundImage={commonBanner} />
        <PrivateRoute exact path={ROUTES.ApplyCourse} component={ApplyCourse} />
        <FooterLayout />
      </Route>
      <Route exact path={ROUTES.MyCoursePage}>
        <HeaderLayout data="MY COURSE" backgroundImage={banner} />
        <PrivateRoute
          exact
          path={ROUTES.MyCoursePage}
          component={MyCoursePage}
        />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.DownladAssesmentAnswers}>
        <HeaderLayout data="QUIZ - DOWNLOADS" backgroundImage={commonBanner} />
        <PrivateRoute
          exact
          path={ROUTES.DownladAssesmentAnswers}
          component={DownladAssesmentAnswers}
        />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.CourseDetailPage}>
        <HeaderLayout data="COURSE DETAIL" backgroundImage={commonBanner} />
        {UserOrder && UserOrder.length > 0 ? (
          <PrivateRoute
            exact
            path={ROUTES.CourseDetailPage}
            component={CourseDetailPage}
          />
        ) : (
          <Redirect to={ROUTES.AllCourse} />
        )}
        <FooterLayout />
      </Route>
      <Route exact path={ROUTES.WatchVideoPage}>
        <HeaderLayout data="WATCH VIDEO" backgroundImage={commonBanner} />
        {UserOrder && UserOrder.length > 0 ? (
          <PrivateRoute
            exact
            path={ROUTES.WatchVideoPage}
            component={WatchVideoPage}
          />
        ) : (
          <Redirect to={ROUTES.AllCourse} />
        )}
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.UserAccountPage}>
        <HeaderLayout data="USER PROFILE" backgroundImage={banner} />
        <PrivateRoute
          exact
          path={ROUTES.UserAccountPage}
          component={UserAccountPage}
        />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.AboutUs}>
        <HeaderLayout data="ABOUT US" backgroundImage={commonBanner} />
        <AboutUs />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.ContactUs}>
        <HeaderLayout data="CONTACT US" backgroundImage={commonBanner} />
        <ContactUs />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.CourseOverview}>
        <HeaderLayout data="COURSE OVERVIEW" backgroundImage={commonBanner} />
        <CourseOverview />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.MediaBlog}>
        <HeaderLayout data="MEDIA-BLOGS" backgroundImage={commonBanner} />
        <MediaBlog />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.HelpVideo}>
        <HeaderLayout data="HELP VIDEOS" backgroundImage={commonBanner} />
        <HelpVideo />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.Terms_Condition}>
        <HeaderLayout data="TERMS OF USE" backgroundImage={banner} />
        <Terms_Condition />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.PrivacyPolicy}>
        <HeaderLayout data="PRIVACY POLICY" backgroundImage={commonBanner} />
        <PrivacyPolicy />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.RefundCancel}>
        <HeaderLayout
          data="REFUND-CANCELLATION"
          backgroundImage={commonBanner}
        />
        <RefundCancel />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.FeedbackPage}>
        <HeaderLayout
          data="FEEDBACK"
          backgroundImage={commonBanner}
        />
        {UserOrder && UserOrder.length > 0 ? (
          <PrivateRoute exact path={ROUTES.FeedbackPage} component={FeedbackPage} />
        ) : (
          <Redirect to={ROUTES.AllCourse} />
        )}
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.Faq}>
        <HeaderLayout
          data="FREQUENTLY ASKED QUESTIONS"
          backgroundImage={faqBanner}
        />
        <Faq />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.Community}>
        <HeaderLayout data="COMMUNITY" backgroundImage={commonBanner} />
        {UserOrder && UserOrder.length > 0 ? (
          <PrivateRoute exact path={ROUTES.Community} component={Community} />
        ) : (
          <Redirect to={ROUTES.AllCourse} />
        )}
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.CommunityPost}>
        <HeaderLayout
          commonData="COMMUNITY"
          data="COMMUNITY POSTS"
          subData="COMMUNITY"
          backgroundImage={commonBanner}
        />
        {UserOrder && UserOrder.length > 0 ? (
          <PrivateRoute
            exact
            path={ROUTES.CommunityPost}
            component={CommunityPost}
          />
        ) : (
          <Redirect to={ROUTES.AllCourse} />
        )}
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.CommunityPostDetails}>
        <HeaderLayout
          commonData="COMMUNITY"
          data="COMMUNITY POST DETAILS"
          subData="COMMUNITY"
          backgroundImage={commonBanner}
        />
        {Usertoken ? (UserOrder.length > 0 ? <PrivateRoute exact path={ROUTES.CommunityPostDetails} component={CommunityPostDetails} /> : <Redirect to={ROUTES.AllCourse} />) : (<Redirect to={ROUTES.Login} />)}
        <FooterLayout />
      </Route>
      {/* </> 
        : <>
          <Redirect to={ROUTES.Login} /> */}
      {/* </> */}
      {/* } */}

      <Route exact path={ROUTES.AssesmentPage}>
        <HeaderLayout data="QUIZ" backgroundImage={commonBanner} />
        {/* {(() => {
          if (UserOrder && UserOrder.length > 0) {
            // Parse the URL query parameters to check if sessionId and lectureId have numeric values
            const params = new URLSearchParams(accessToAssesmentPage);
            const sessionId = parseInt(params.get('sessionId'));
            const lectureId = parseInt(params.get('lectureId'));
            if (!isNaN(sessionId) && !isNaN(lectureId)) {
              return (
                <PrivateRoute
                  exact
                  path={ROUTES.AssesmentPage}
                  component={AssesmentPage}
                />
              );
            } else {
              return <Redirect to={ROUTES.MyCoursePage} />;
            }
          } else {
            return <Redirect to={ROUTES.AllCourse} />;
          }
        })()} */}
        <AssesmentPage />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.AssesmentResult}>
        <HeaderLayout
          data="QUIZ-RESULT"
          backgroundImage={assesmentResultPic}
        />
        {UserOrder && UserOrder.length > 0 ? userChoices ? (
          <PrivateRoute
            exact
            path={ROUTES.AssesmentResult}
            component={AssesmentResult}
          />
        ) : (<Redirect to={ROUTES.MyCoursePage} />) : (
          <Redirect to={ROUTES.AllCourse} />
        )}
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.AuthorizePay}>
        <HeaderLayout data="AUTHORIZEPAY" backgroundImage={commonBanner} />
        {accessToPaymentPage ? (
          <PrivateRoute
            exact
            path={ROUTES.AuthorizePay}
            component={AuthorizePay}
          />
        ) : (
          <Redirect to={ROUTES.ApplyCourse} />
        )}
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.PaymentComplete}>
        <HeaderLayout data="PAYMENT-COMPLETE" backgroundImage={commonBanner} />
        <PrivateRoute
          exact
          path={ROUTES.PaymentComplete}
          component={PaymentComplete}
        />
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.normalOrderInvoice}>
        <PrivateRoute
          exact
          path={ROUTES.normalOrderInvoice}
          component={OrderInvoice}
        />
      </Route>

      <Route exact path={ROUTES.RenewedOrderInvoice}>
        <PrivateRoute
          exact
          path={ROUTES.RenewedOrderInvoice}
          component={RenwedOrderInvoice}
        />
      </Route>

      <Route exact path={ROUTES.GiftingOrderInvoice}>
        <PrivateRoute
          exact
          path={ROUTES.GiftingOrderInvoice}
          component={GiftedInvoice}
        />
      </Route>

      <Route exact path={ROUTES.CommunitySearch}>
        <HeaderLayout data="COMMUNITY SEARCH" backgroundImage={commonBanner} />
        {UserOrder && UserOrder.length > 0 ? (
          <PrivateRoute
            exact
            path={ROUTES.CommunitySearch}
            component={CommunitySearch}
          />
        ) : (
          <Redirect to={ROUTES.AllCourse} />
        )}
        <FooterLayout />
      </Route>

      <Route exact path={ROUTES.PaymentFailed}>
        <HeaderLayout data="PAYMENT-FAILED" backgroundImage={commonBanner} />
        <PrivateRoute
          exact
          path={ROUTES.PaymentFailed}
          component={PaymentFailed}
        />
        <FooterLayout />
      </Route>
    </Switch>
  );
};

export default Routes;
