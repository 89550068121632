import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Container, Row, Col, Accordion, ListGroup, Tab } from 'react-bootstrap';
import './WatchVideoPage.css';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAskQuery, ActionCheckvalidSession, ActionGetCommunity, ActionUpdateCourseProgressCurrentOrder, ActionUpdateCourseSession, ActionUpdateCourseSubLectureTestmonialSession, ActionUpdateLectureTestmonialSession, ActionUpdateTestmonialVideos, ActiongetCourseProgressofCurrentOrder, ActiongetCourseVideos, ActiongetCurrentActiveOrderDetails } from '../../store/actions/actions-server-data';
import winner from '../../Assets/images/winner.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import PostQuestionModal from '../CommunityPage/PostQuestionModal';
import { useHistory, useLocation } from 'react-router';
import GuideBookModal from '../SideBar/GuideBookModal';
import Vimeo from '@u-wave/react-vimeo';
import LegalModel from '../Modals/LegalModel';
import CompleteModal from '../Modals/CompleteModal';
import InternetModal from '../Modals/InternetModal';
import InternetSlowModal from '../Modals/InternetSlowModal';
import InactiveModal from '../Modals/InactiveModal';
import SomethingError from '../Modals/SomethingError';
import { Player } from '@vime/react';
import axios from 'axios';
import Cookies from 'js-cookie';

const RemoveSpecialCharacters = ({ text }) => {
  // Replace all non-alphanumeric characters with an empty string, except for hyphens
  const cleanedText = text.replace(/[^\w\s-?]/g, '');
  return <>{cleanedText}</>;
};


function WatchVideoPage(props) {
  const MyStaterServer = useSelector((state) => state.rServerData);
  const MyStaterLoading = useSelector((state) => state.rLoading);
  const updatesessionLoader = MyStaterServer.rLoading?.updateCourseProgress;
  const nextassessmentLoader = MyStaterServer.rLoading?.nextassessmentstatusattempt;
  const assessmentIconVisible = MyStaterServer.assessmentIconVisible;
  const updatesessionVideo = MyStaterServer?.updatesession;

  const dispatch = useDispatch();
  const history = useHistory();
  const activeTabRef = useRef(null);
  const activeItemRef = useRef(null);
  const UserDetails = useMemo(() => MyStaterServer?.userDataLogggedIn || [], [MyStaterServer?.userDataLogggedIn]);
  const location = useLocation();
  const param = location.state;
  const { id, testMonialId, lastVideoObject, LectureId, SessionId, lastSubVideoObject } = getQueryParams(param);
  const [activeMainTabId, setActiveMainTabId] = useState(null);
  const [testimonialTabId, setTestimonialTabId] = useState(null);
  const [activeSubTabId, setActiveSubTabId] = useState(null);
  const [testArrowId, setTestArrowId] = useState(null);
  const [lecturesBySession, setLecturesBySession] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [show, setShow] = useState(false);
  const [ProcessLoad, setProcessLoad] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [currentSubVideoId, setCurrentSubVideoId] = useState(null);
  const [videoQueue, setVideoQueue] = useState([]);
  const [visibleModules, setVisibleModules] = useState({});
  const [questionSet, setQuestionSet] = useState();
  const [SESSION_ID, SET_SESSION_ID] = useState("");
  const [LECTURE_ID, SET_LECTURE_ID] = useState("");
  const [ASSESSMENT, SET_ASSESSEMENT] = useState("");
  const [showLegency, setLegency] = useState(false);
  const [vimeoPaused, setVimeoPaused] = useState(false);
  const [acceptPlay, setAcceptPlay] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [showModal, setModalShow] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSlowConnection, setIsSlowConnection] = useState(false);
  const [hasBuffered, setHasBuffered] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [showWrong, setShowWrong] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('')
  const [completedVideos, setCompletedVideos] = useState([]);
  const [isPlayingButton, setIsPlayingButton] = useState(true);
  const [startTime, setStartTime] = useState(0);
  const playerRef = useRef(null);
  const containerRef = useRef(null);

  function getQueryParams(queryString) {
    const params = new URLSearchParams(queryString);
    const id = params.get('id');
    const testMonialId = params.get('testMonialId');
    const SessionId = params.get('SessionId');
    const LectureId = params.get('LectureId');
    const testimonialid = params.get('testimonialid');
    const lastVideoObject = params.get('lastVideoObject');
    const lastSubVideoObject = params.get('lastSubVideoObject');
    return { id, testMonialId, SessionId, LectureId, testimonialid, lastVideoObject, lastSubVideoObject };
  }

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const iconStyle = {
    color: 'black',
    background: 'white',
    borderRadius: '80px',
    padding: '5px',
    paddingRight: '6px',
    boxShadow: '0px 0px 20px -3px rgba(0,0,0,0.75)',
    cursor: 'pointer',
    width: "89px",
    height: "89px"
  };

  const playBtnStyle = {
    display: 'block',
    // position: 'absolute',
    // top: '83%',
    // left: '82%',
    // transform: 'translate(-41%, -50%)',
    visibility: 'visible',
    // backgroundColor: 'red'
  };

  const handleLegencyClose = () => {
    setLegency(false)
    setVimeoPaused(true);
  }

  const handleOpenDownloadClose = () => setModalVisible(true);

  const handleCloseDownloadClose = () => setModalVisible(false);

  const handleClosePopup = () => {
    setShowPopup(false);
    setInactive(false);
  };

  const handleWrongClose = () => {
    setShowWrong(false)
  }

 
  const isLoading = MyStaterLoading?.isWatchLoading;
  const isLoadingOrderProgress = MyStaterLoading?.getCourseProgress;
  const UserAccessToken = MyStaterServer?.Usertoken || null;
  const orders = MyStaterServer?.IntiallcourseDetails?.orders;
  const { watchVideos, getCourseProgress, CurrentOrder } = MyStaterServer;
  const { SubVideoList, videoData: { videoList: { lectureList, futureLectureList, pastLectureList } } } = watchVideos;
  const concatenatedArray = [...lectureList, ...futureLectureList, ...pastLectureList];
  const sortedConcatenatedArray = [...concatenatedArray].sort((a, b) => a.id - b.id);
  const { sessionId, lectureId, assessmentlecture } = getCourseProgress;
  const postCard = MyStaterServer?.community?.post_topics;
  const GuideBookDownloadUrl = MyStaterServer.GuideBook ? MyStaterServer.GuideBook : null;

  const [showCertificateModal, setCertificateShowModal] = useState(false);
  const handleCertificateShowModal = () => setCertificateShowModal(true);
  const handleCertificateCloseModal = () => setCertificateShowModal(false);

  useEffect(() => {
    const mainElement = document.getElementById("mainPage");
    const container = document.getElementById('watchVideoLists');

    if (mainElement && container && activeItemRef.current) {
      const lectureElements = container.querySelectorAll('[data-lecture-id]');
      const activeLecture = Array.from(lectureElements).find(el => el.dataset.lectureId === activeSubTabId.toString());

      if (activeLecture) {
        // Scroll the active item into view
        setTimeout(() => {
          activeItemRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 300); // Adjust timing if needed
      }
    }

  }, [activeSubTabId]);

  useEffect(() => {
    // Listen for changes in history
    const unlisten = history.listen((newLocation, action) => {
      if (action === 'POP') { // 'POP' indicates the back/forward navigation
        console.log('Previous pathname:', newLocation.pathname);
        if (newLocation.pathname === '/assesmentPage') {
          history.replace('/courseDetail');
        }
      }
    });

    // Cleanup the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (getCourseProgress) {
      SET_SESSION_ID(getCourseProgress.sessionId);
      SET_LECTURE_ID(getCourseProgress.lectureId);
      SET_ASSESSEMENT(getCourseProgress.assessmentlecture);
    }
  }, [getCourseProgress, isLoadingOrderProgress]);
  // useEffect(() => {
    
  // }, [UserDetails.id, orders]);

  useEffect(() => {
    // Get the stored time for the current video when video ID changes
    const savedTime = Cookies.get(`video_${currentVideoId}_time_${UserDetails?.id}`);
    if (savedTime) {
      setStartTime(savedTime ? parseFloat(savedTime) : 0);
    }
  }, [currentVideoId]);

  const getSafeStartTime = () => {
    if (startTime === null || isNaN(startTime) || startTime < 0) {
      return 0;  // Default to 0 if the start time is invalid
    }
    return startTime;
  };

  const handleVideoPlay = () => {
    setIsPlaying(true);
    document.getElementById('playBtn').style.display = 'none';
    document.getElementById('playBtn').style.visibility = 'hidden';
  };

  const handleTimeUpdate = (event) => {
    const currentTime = event.seconds;
    if (!isNaN(currentTime) && currentTime > 0) {
      Cookies.set(`video_${currentVideoId}_time_${UserDetails?.id}`, currentTime.toString(), { expires: 365 });
    }
  };


  useEffect(() => {
    handleCallApi();
    dispatch(ActiongetCourseProgressofCurrentOrder({ userId: UserDetails.id, token: UserAccessToken }));
    if (getCourseProgress) {
      SET_SESSION_ID(getCourseProgress.sessionId);
      SET_LECTURE_ID(getCourseProgress.lectureId);
      SET_ASSESSEMENT(getCourseProgress.assessmentlecture);
    }
  }, [SessionId, LectureId, id]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (selectedVideo) {
        localStorage.setItem('selectedVideo', JSON.stringify(selectedVideo));
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [selectedVideo]);

  const handleCallApi = async () => {
    if (getCourseProgress.sessionId == 0 && getCourseProgress.lectureId == 0) {
      setLegency(true);
    }
    const currentSessionId = getCourseProgress.sessionId == 0 ? 1 : getCourseProgress.sessionId;
    const currentLectureId = getCourseProgress.lectureId + 1;

    await dispatch(ActiongetCourseVideos({ courseid: 10, userId: UserDetails.id, sessionid: currentSessionId, lectureid: currentLectureId, testimonialid: null, videoid: null, token: UserAccessToken }));

    const videoData = MyStaterServer?.watchVideos?.videoData?.videoList;
    const question = MyStaterServer?.watchVideos?.videoData?.questionSet;
    const SubVideoList = MyStaterServer?.watchVideos?.SubVideoList;
    const currentLecture = MyStaterServer?.watchVideos?.videoData.videoList?.currentLecture;

    if (videoData && question && SubVideoList && currentLecture) {
      const { lectureList, futureLectureList, pastLectureList } = videoData;
      const concatenatedArray = [...lectureList, ...futureLectureList, ...pastLectureList];
      const sortedConcatenatedArray = [...concatenatedArray].sort((a, b) => a.id - b.id);

      if (SessionId && LectureId && testMonialId == null && id) {
        // without testimonial go back watch again scenario        
        dispatch(ActiongetCourseProgressofCurrentOrder({ userId: UserDetails.id, token: UserAccessToken }));
        const PreviousVideo = sortedConcatenatedArray.find(lecture => lecture.id == LectureId);
        setActiveMainTabId(parseInt(PreviousVideo.iframeURL ? PreviousVideo.sessionid : PreviousVideo.session_id));
        setSelectedVideo(PreviousVideo);
        setActiveSubTabId(PreviousVideo.iframeURL ? PreviousVideo.lectureid : PreviousVideo.id);
        setVisibleModules(PreviousVideo.iframeURL ? PreviousVideo.sessionid : PreviousVideo.session_id);
        setCurrentVideoId(PreviousVideo.iframeURL ? PreviousVideo.lectureid : PreviousVideo.id);
        if (PreviousVideo.iframeURL) {
          setCurrentSubVideoId(PreviousVideo.id);
          setTestimonialTabId(PreviousVideo.id);
          setShow(true);
          setTestArrowId(PreviousVideo.iframeURL ? PreviousVideo.lectureid : PreviousVideo.id);
        }

      } else if (SessionId && LectureId && testMonialId && id) {
        // with testimonial go back watch again scenario         
        dispatch(ActiongetCourseProgressofCurrentOrder({ userId: UserDetails.id, token: UserAccessToken }));
        const subVideo = SubVideoList.find(sublecture => sublecture.id == testMonialId);
        setActiveMainTabId(parseInt(subVideo.iframeURL ? subVideo.sessionid : subVideo.session_id));
        setSelectedVideo(subVideo);
        setActiveSubTabId(subVideo.iframeURL ? subVideo.lectureid : subVideo.id);
        setVisibleModules(subVideo.iframeURL ? subVideo.sessionid : subVideo.session_id);
        setCurrentVideoId(subVideo.iframeURL ? subVideo.lectureid : subVideo.id);
        if (subVideo.iframeURL) {
          setCurrentSubVideoId(subVideo.id);
          setTestimonialTabId(subVideo.id);
          setShow(true);
          setTestArrowId(subVideo.iframeURL ? subVideo.lectureid : subVideo.id);
        }
      } else {
        let initialVideo;
        let savedVideo = localStorage.getItem('selectedVideo');
        if (id && lastVideoObject) {
          if (id && lastVideoObject && lastSubVideoObject) {
            const subVideo = SubVideoList.find(sublecture => sublecture.id == lastSubVideoObject);
            if (savedVideo) {
              initialVideo = JSON.parse(savedVideo);
            } else {
              initialVideo = subVideo;
              console.log('subVideo', initialVideo)
            }
          } else {
            const LastVideo = sortedConcatenatedArray.find(lecture => lecture.id == lastVideoObject);
            if (savedVideo) {
              initialVideo = JSON.parse(savedVideo);
            } else {
              initialVideo = LastVideo;
            }
          }
        } else {
          if (savedVideo) {
            initialVideo = JSON.parse(savedVideo);
          } else {
            initialVideo = currentLecture[0];
          }
        }
        setActiveMainTabId(parseInt(initialVideo.iframeURL ? initialVideo.sessionid : initialVideo.session_id));
        setSelectedVideo(initialVideo);
        setActiveSubTabId(initialVideo.iframeURL ? initialVideo.lectureid : initialVideo.id);
        setVisibleModules(initialVideo.iframeURL ? initialVideo.sessionid : initialVideo.session_id);
        setCurrentVideoId(initialVideo.iframeURL ? initialVideo.lectureid : initialVideo.id);
        if (initialVideo.iframeURL) {
          setCurrentSubVideoId(initialVideo.id);
          setTestimonialTabId(initialVideo.id);
          setShow(true);
          setTestArrowId(initialVideo.iframeURL ? initialVideo.lectureid : initialVideo.id);
        }
      }

      setQuestionSet(question);
      setLecturesBySession(groupBySessionId(sortedConcatenatedArray));
      setVideoQueue(sortedConcatenatedArray);
      SET_SESSION_ID(getCourseProgress.sessionId);
      SET_LECTURE_ID(getCourseProgress.lectureId);
      SET_ASSESSEMENT(getCourseProgress.assessmentlecture);

    } else {
      alert('VideoList not found in API response');
    }
  };

  useEffect(() => {
    dispatch(ActiongetCourseProgressofCurrentOrder({ userId: UserDetails.id, token: UserAccessToken }));
  }, [dispatch]);

  const handleSubmit2 = (postDescription, setError, selectedTopic) => {
    const trimmedDescription = postDescription.trim();
    if (!trimmedDescription) {
      setError('Fill the question.');
      return;
    } else if (trimmedDescription[0] == ' ') {
      setError('Question cannot start with a space.');
      return;
    }
    dispatch(ActionAskQuery({ token: UserAccessToken, userId: UserDetails.id, topics: selectedTopic, askquestion: postDescription }));
    dispatch(ActionGetCommunity({ token: UserAccessToken }));
    handleClose();
  }

  const groupBySessionId = (lectures) => {
    return lectures.reduce((acc, lecture) => {
      const { session_id, ...lectureData } = lecture;
      if (!acc[session_id]) {
        acc[session_id] = [];
      }
      acc[session_id].push({ session_id, ...lectureData });
      return acc;
    }, {});
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} Mins`;
  };

  const ucwords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const isAssessmentPresentForLecture = (lectureId) => {
    const questionSetArray = questionSet ? Object.values(questionSet) : [];
    return questionSetArray.some(element => element == lectureId);
  };

  const handleToggleArrowOfSubVideo = (videoId, e) => {
    e.stopPropagation();
    setShow(!show);
    setTestArrowId(videoId);
    setActiveSubTabId(videoId)
  };

  const handleModuleClick = (sessionId) => {
    setVisibleModules((prevVisibleItemId) => (prevVisibleItemId == sessionId ? null : sessionId));
    setActiveMainTabId((prevId) => (prevId == sessionId ? null : sessionId));
    setActiveSubTabId(null);
  }


  const getContatDurations = (video) => {
    var testimonial = video.testimonial ? video.testimonial : null;
    if (testimonial) {
      const SubListTestimonial = SubVideoList.filter(record => record.name.includes(testimonial));
      var MainvideoDurations = minutesToSeconds(video.duration)
      const durations = SubListTestimonial.map(item => item.duration);
      const TotalDurations = [...durations, MainvideoDurations];
      const totalDurationInSeconds = TotalDurations.reduce((total, duration) => total + duration, 0);
      const minutes = Math.floor(totalDurationInSeconds / 60);
      const seconds = totalDurationInSeconds % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} Mins`;
    } else {
      return formatDuration(video.duration * 60)
    }
  };

  function minutesToSeconds(minutes, seconds = 0) {
    return (minutes * 60) + seconds;
  }


  const handleSubVideoSelect = async (video, type) => {
    setProcessLoad(true);
    // New Implementations module based propregress
    SET_SESSION_ID(sessionId);
    SET_LECTURE_ID(lectureId);
    SET_ASSESSEMENT(assessmentlecture);
    setSelectedVideo(video);

    const nextVideoId = video ? video.id : null;
    if (nextVideoId) {
      const nextSavedTime = Cookies.get(`video_${nextVideoId}_time_${UserDetails?.id}`);
      setStartTime(nextSavedTime ? parseFloat(nextSavedTime) : 0);
    }

    if (type == 'MainVideo') {
      setCurrentVideoId(video.id);
      setActiveSubTabId(video.id);
      setTestimonialTabId(null);
      setCurrentSubVideoId(null);
      setTestArrowId(null);
      setActiveMainTabId(parseInt(video.session_id));
      setVisibleModules(video.session_id);
    } else {
      const MainVideo = sortedConcatenatedArray.find(lecture => lecture.id == video.lectureid);
      setCurrentSubVideoId(video.id);
      setActiveSubTabId(MainVideo.id);
      setCurrentVideoId(MainVideo.id);
      setTestimonialTabId(video.id);
      setShow(true);
      setTestArrowId(MainVideo.id);
      setActiveMainTabId(parseInt(video.sessionid));
      setVisibleModules(video.sessionid);
    }

    let progressEntry = { user_id: UserDetails.id, order_id: orders[0].orderid, module_session_id: video.iframeURL ? video.sessionid : video.session_id, module_lecture_id: video.iframeURL ? video.lectureid : video.id, module_sublecture_id: video.iframeURL ? video.id : null, status: 'in_progress' };
    // Save this new entry to the database
    try {
      const response = await axios.post('https://lmsreactbackend.helenzysinc.com/updateuser-progress', progressEntry, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${UserAccessToken}` } });

      if (response.data.success) {
        console.log('Selected Video Call', response.data.data);
      } else {
        console.error('Failed to save progress entry');
      }
    } catch (error) {
      console.error('Error saving progress entry:', error);
    }
    setProcessLoad(false);

  };

  const handleEndVideo = async () => {
    let nextVideo;
    // Update session, lecture, and assessment states
    SET_SESSION_ID(sessionId);
    SET_LECTURE_ID(lectureId);
    SET_ASSESSEMENT(assessmentlecture);

    if (currentVideoId) {
      setCompletedVideos(prev => [...prev, currentVideoId]);
    }

    Cookies.remove(`video_${currentVideoId}_time_${UserDetails?.id}`);

    const currentMainVideo = videoQueue.find(v => v.id === currentVideoId);
    const subVideos = SubVideoList.filter(record => record.name.includes(currentMainVideo.testimonial));

    const nextVideoId = nextVideo ? nextVideo.id : null;
    if (nextVideoId) {
      const nextSavedTime = Cookies.get(`video_${nextVideoId}_time_${UserDetails?.id}`);
      setStartTime(nextSavedTime ? parseFloat(nextSavedTime) : 0);
    }

    if (currentSubVideoId) {
      // Currently playing a sub-video
      const currentSubVideoIndex = subVideos.findIndex(v => v.id === currentSubVideoId);
      if (currentSubVideoIndex < subVideos.length - 1) {
        nextVideo = subVideos[currentSubVideoIndex + 1];
        setCurrentSubVideoId(nextVideo.id);
        setTestimonialTabId(nextVideo.id);
        setShow(true);
        setTestArrowId(currentMainVideo.id);
      } else {
        // All sub-videos completed
        if (isAssessmentPresentForLecture(currentMainVideo.id) && currentMainVideo.id > ASSESSMENT) {
          return navigateToAssessment(currentMainVideo, subVideos[subVideos.length - 1].id);
        } else {
          // Move to next main video
          const currentMainIndex = videoQueue.findIndex(v => v.id === currentVideoId);
          if (currentMainIndex < videoQueue.length - 1) {
            nextVideo = videoQueue[currentMainIndex + 1];
            setCurrentVideoId(nextVideo.id);
            setActiveSubTabId(nextVideo.id);
            setCurrentSubVideoId(null);
            setTestimonialTabId(null);
            setShow(false);
            setTestArrowId(null);
          }
        }
      }
    } else {
      // Currently playing a main video
      if (currentMainVideo.testimonial && subVideos.length > 0) {
        // Start playing first sub-video
        nextVideo = subVideos[0];
        setCurrentSubVideoId(nextVideo.id);
        setTestimonialTabId(nextVideo.id);
        setShow(true);
        setTestArrowId(currentMainVideo.id);
      } else {
        // Move to next main video or assessment
        if (isAssessmentPresentForLecture(currentMainVideo.id) && currentMainVideo.id > ASSESSMENT) {
          return navigateToAssessment(currentMainVideo, 777);
        } else {
          const currentMainIndex = videoQueue.findIndex(v => v.id === currentVideoId);
          if (currentMainIndex < videoQueue.length - 1) {
            nextVideo = videoQueue[currentMainIndex + 1];
            setCurrentVideoId(nextVideo.id);
            setActiveSubTabId(nextVideo.id);
            setShow(false);
            setTestArrowId(null);
          }
        }
      }
    }

    if (nextVideo) {
      // New Implementations module based propregress
      let progressEntry = { user_id: UserDetails.id, order_id: orders[0].orderid, module_session_id: nextVideo.iframeURL ? nextVideo.sessionid : nextVideo.session_id, module_lecture_id: nextVideo.iframeURL ? nextVideo.lectureid : nextVideo.id, module_sublecture_id: nextVideo.iframeURL ? nextVideo.id : null, status: 'in_progress' };
      // Save this new entry to the database
      try {
        const response = await axios.post('https://lmsreactbackend.helenzysinc.com/updateuser-progress', progressEntry, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${UserAccessToken}` } });

        if (response.data.success) {
          console.log('End Video Call', response.data.data);
        } else {
          console.error('Failed to save progress entry');
        }
      } catch (error) {
        console.error('Error saving progress entry:', error);
      }

      setSelectedVideo(nextVideo);
      updateActiveTabsAndModules(nextVideo);
    } else {
      console.log("All videos completed");
      setShowComplete(true);
    }
    await updateProgress(currentMainVideo, nextVideo);
  };

  const updateActiveTabsAndModules = (video) => {
    if (video.session_id) {
      setActiveMainTabId(parseInt(video.session_id));
      setVisibleModules(video.session_id);
      setActiveSubTabId(video.id);
    } else {
      setActiveMainTabId(parseInt(video.sessionid));
      setVisibleModules(video.sessionid);
      setActiveSubTabId(video.lectureid);
    }
  };

  const updateProgress = async (currentVideo, nextVideo) => {
    if (currentVideo.id >= LECTURE_ID) {
      await dispatch(ActionUpdateCourseProgressCurrentOrder({ userId: UserDetails.id, cour_status: currentVideo.id == 57 ? 'completed' : 'inprogress', sessionId: currentVideo.session_id, lectureId: currentVideo.id, asessmentlecture: ASSESSMENT, token: UserAccessToken }));
    }

    if (nextVideo && nextVideo.iframeURL) { // Sub Video Block
      await dispatch(ActionCheckvalidSession());
      await dispatch(ActionUpdateCourseSession({ userId: UserDetails.id, courseId: 10, sessionId: nextVideo.sessionid, lectureId: nextVideo.lectureid, orderId: orders[0].orderid, token: UserAccessToken, testimonialid: nextVideo.videoId }));
      await dispatch(ActionUpdateLectureTestmonialSession({ userId: UserDetails.id, courseId: 10, orderId: orders[0].orderid, testimonialid: nextVideo.videoId, sessionId: nextVideo.sessionid, token: UserAccessToken }));
      await dispatch(ActionUpdateTestmonialVideos({ userId: UserDetails.id, sessionId: SESSION_ID, lectureId: LECTURE_ID, assessmentlecture: ASSESSMENT, subTestVideo: nextVideo.id, token: UserAccessToken }));
    } else { // Main Video Block
      if (nextVideo) {

        await dispatch(ActionCheckvalidSession());
        await dispatch(ActionUpdateCourseSession({ userId: UserDetails.id, courseId: 10, sessionId: nextVideo.session_id, lectureId: nextVideo.id, orderId: orders[0].orderid, token: UserAccessToken, testimonialid: null }));
        await dispatch(ActionUpdateLectureTestmonialSession({ userId: UserDetails.id, courseId: 10, orderId: orders[0].orderid, testimonialid: null, sessionId: nextVideo.session_id, token: UserAccessToken }));
      }
    }
    await dispatch(ActiongetCourseProgressofCurrentOrder({ userId: UserDetails.id, token: UserAccessToken }));
  };

  const navigateToAssessment = async (nextVideo, currentsubVideoId) => {
    const subVideos = SubVideoList.filter(record => record.name.includes(nextVideo.testimonial || nextVideo.videoId));
    const lastTestimonial = subVideos.length > 0 ? subVideos[subVideos.length - 1].id : null;

    await dispatch(ActiongetCourseProgressofCurrentOrder({ userId: UserDetails.id, token: UserAccessToken }));

    if (lastTestimonial == currentsubVideoId) {
      await dispatch(ActionUpdateTestmonialVideos({ userId: UserDetails.id, sessionId: SESSION_ID, lectureId: LECTURE_ID, assessmentlecture: ASSESSMENT, subTestVideo: currentsubVideoId, token: UserAccessToken }));
      await history.push({ pathname: '/assesmentPage', state: { sessionId: nextVideo.sessionid ? nextVideo.sessionid : nextVideo.session_id, lectureId: nextVideo.lectureid ? nextVideo.lectureid : nextVideo.id, testimonialId: currentsubVideoId } });
    } else {
      await history.push({ pathname: '/assesmentPage', state: { sessionId: nextVideo.sessionid ? nextVideo.sessionid : nextVideo.session_id, lectureId: nextVideo.lectureid ? nextVideo.lectureid : nextVideo.id } });
    }
  };

  const getVimeoIdFromUrl = (url) => {
    const regex = /vimeo\.com\/video\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const handleDownloadCertificate = () => {
    var seconds = 10;
    var countdown = setInterval(function () {
      seconds--;
      if (seconds <= 10) {
        document.getElementById("printmycertificate").innerHTML = 'Your Certifcate is getting generated.';
      }
      if (seconds <= 9) {
        document.getElementById("printmycertificate").innerHTML = 'Your Certifcate is getting generated. Till time we appreicate if you can share your feedback';
      }
      if (seconds <= 0) {
        document.getElementById("printmycertificate").innerHTML = 'Print my certificate | ';
        document.getElementById("printmycertificate").removeAttribute("onClick");
        window.location.href = "/downloadCertificate";
        document.getElementById("goback").style.display = 'inline';
        clearInterval(countdown);
      }
    }, 1000);
  }


  const handlegetFeedback = () => {
    window.open("https://lmsreactfrontend.helenzysinc.com/feedback", "_blank", "width=1200,height=1200");
  }

  useEffect(() => {
    if (UserDetails?.id && UserAccessToken) {
      dispatch(ActiongetCourseProgressofCurrentOrder({ userId: UserDetails.id, token: UserAccessToken }));
    }
  }, [dispatch, UserDetails?.id, UserAccessToken]);

  useEffect(() => {
    if (location.state?.fromAssessment) {
      dispatch(ActiongetCourseProgressofCurrentOrder({ userId: UserDetails.id, token: UserAccessToken }));
      window.history.replaceState({}, document.title); // Remove state to prevent loop
    }
  }, [dispatch, location.state, UserDetails.id, UserAccessToken]);

  return (
    <div className="container-fluid px-5 py-5">
      <Container>
        {isUpdating ? (
          <div className="video-loading-overlay">
            <div className='mb-2'>
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
            </div>
            <div style={{ position: 'absolute', top: '55%' }}>
              <p className='mt-2 payment_loading'>Updating progress...</p>
            </div>
          </div>
        ) : (
          <Row style={{
            maxHeight: '100vh',
            overflowY: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none'
          }} id='mainPage'>
            <Col md={8}>
              <div className='videoFrame px-5'>
                <div className='VIFRAME' style={isLoadingOrderProgress ? { width: '100%', height: '527px', borderRadius: '5px', backgroundColor: 'black', position: 'relative' } : {}}>
                  {(() => {
                    // Step 1: Check if data is loading
                    if (isLoadingOrderProgress) {
                      return (
                        <div className="video-loading-overlay">
                          <div className='mb-2'>
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
                          </div>
                          <div style={{ position: 'absolute', top: '55%' }}>
                            <p className='mt-2 payment_loading'>Please wait...</p>
                          </div>
                        </div>
                      );
                    } else {
                      // Step 2: Check if selectedVideo exists and is valid
                      if (!selectedVideo || typeof selectedVideo !== 'object' || Object.keys(selectedVideo).length == 0) {
                        return <p>Please select a video from the list</p>;
                      }
                      // Step 3: Render the appropriate video player
                      if (selectedVideo.iframeURL) {
                        return (
                          <div className='ratio ratio-16x9 mb-3'>
                            <Vimeo
                              key={currentVideoId}
                              video={getVimeoIdFromUrl(selectedVideo.iframeURL)}
                              autoplay={true}
                              width={828}
                              onEnd={handleEndVideo}
                              onPlay={handleVideoPlay}
                              onPause={() => {
                                setIsPlaying(false);
                                document.getElementById('playBtn').style.display = 'block';
                                document.getElementById('playBtn').style.visibility = 'visible';
                              }}
                              paused={!isPlaying}
                              showTitle={false}
                              showByline={false}
                              showPortrait={false}
                              start={startTime}
                              onTimeUpdate={handleTimeUpdate}
                            />
                            <div id="playBtn" style={playBtnStyle} onClick={togglePlayPause}>
                              <i className="fa fa-play-circle fa-5x" id="playIcon" aria-hidden="true" style={iconStyle}></i>
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <div className='ratio ratio-16x9 mb-3'>
                            {getCourseProgress.sessionId == 0 ? (
                              <>
                                <Vimeo
                                  key={currentVideoId}
                                  video={selectedVideo.link}
                                  autoplay={vimeoPaused}
                                  width={828}
                                  onEnd={handleEndVideo}
                                  onPlay={handleVideoPlay}
                                  onPause={() => {
                                    setIsPlaying(false);
                                    document.getElementById('playBtn').style.display = 'block';
                                    document.getElementById('playBtn').style.visibility = 'visible';
                                  }}
                                  paused={!isPlaying}
                                  showTitle={false}
                                  showByline={false}
                                  showPortrait={false}
                                  start={startTime}
                                  onTimeUpdate={handleTimeUpdate}
                                />
                                <div id="playBtn" style={playBtnStyle} onClick={togglePlayPause}>
                                  <i className="fa fa-play-circle fa-5x" id="playIcon" aria-hidden="true" style={iconStyle}></i>
                                </div>
                              </>
                            ) : (
                              <>
                                <Vimeo
                                  key={currentVideoId}
                                  video={selectedVideo.link}
                                  autoplay={showComplete ? false : true}
                                  width={828}
                                  onEnd={handleEndVideo}
                                  onPlay={handleVideoPlay}
                                  onPause={() => {
                                    setIsPlaying(false);
                                    document.getElementById('playBtn').style.display = 'block';
                                    document.getElementById('playBtn').style.visibility = 'visible';
                                  }}
                                  paused={!isPlaying}
                                  showTitle={false}
                                  showByline={false}
                                  showPortrait={false}
                                  start={startTime}
                                  onTimeUpdate={handleTimeUpdate}
                                />
                                <div id="playBtn" style={playBtnStyle} onClick={togglePlayPause}>
                                  <i className="fa fa-play-circle fa-5x" id="playIcon" aria-hidden="true" style={iconStyle} ></i>
                                </div>
                              </>
                            )}

                          </div>
                        );
                      }
                    }
                  })()}
                </div>
                <div className='TIFrame'>
                  {selectedVideo && (
                    <div className="video-details">
                      <div className="my-3" style={{ background: 'url(https://dev.onlinetraining.drgarciabiomagnetism.com/img/result-bg.png)', position: 'relative', bottom: '13px', padding: '1px 25px' }}>
                        <div className="col-md-12 px-0 my-3 d-flex justify-content-between">
                          <h5 className="mons-font" style={{ fontStyle: 'normal', lineHeight: '0.92', letterSpacing: '-0.18px', textAlign: 'left', color: '#101010', fontWeight: 700, textTransform: 'uppercase', display: 'inline' }}>
                            {selectedVideo.iframeURL ? concatenatedArray.find(video => video.id === selectedVideo.lectureid).lecture_name : selectedVideo.lecture_name}
                          </h5>
                          <span className="float-right" data-toggle="tooltip" data-placement="top" title="This unit consists of 1 video / sub video.">
                            {isLoading && (
                              <span className="start-100 translate-middle" style={{ right: '-50px', color: '#6f42c1', marginRight: '10px' }}>
                                <i className="fa fa-spinner fa-spin"></i>
                              </span>
                            )}
                            <i className="fa fa-font-awesome" aria-hidden="true"></i>
                          </span>
                        </div>
                        <p id="lecture_name" className="text-justify small" style={{ fontSize: "80%", fontWeight: "400" }}>
                          {/* {selectedVideo.iframeURL ? selectedVideo.title : selectedVideo.description} */}
                          {selectedVideo.iframeURL ? <RemoveSpecialCharacters text={selectedVideo.title} /> : <RemoveSpecialCharacters text={selectedVideo.description} />}
                        </p>
                        <div className="col-md-12 px-0 my-3">
                          <span className="text-right">
                            {getCourseProgress.sessionId == 0 && LECTURE_ID == 0 && (
                              <a className="btn btn mons-font font-weight-bold uppercase" style={{ color: 'white', marginRight: '10px', boxShadow: "0 6.5px 25px 0 rgba(72, 48, 140, 0.32)", border: "solid 1px #48308c", backgroundColor: '#48308c' }} data-toggle="modal" data-target="#courseOutlineModal" href="javascript:void(0)"
                                onClick={() => setCertificateShowModal(true)}>
                                Download Biomagnetism practitioner guidebook
                              </a>
                            )}
                            <button className="btn btn-outline-danger font-weight-bold" onClick={handleShow} style={{ border: '1px solid #48308c', color: '#48308c', boxShadow: '0 6.5px 25px 0 rgba(72, 48, 140, 0.32)', background: '#fff', fontWeight: '700', fontFamily: 'Montserrat, sans-serif' }}>
                              Ask your Query
                            </button>
                          </span>

                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md={4} style={{ maxHeight: '100vh', overflowY: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none' }} id="watchVideoLists">
              <Tab.Container activeKey={activeMainTabId} onSelect={(key) => setActiveMainTabId(parseInt(key))}>
                <Row>
                  <Col sm={12}>
                    {lecturesBySession && (
                      <Accordion activeKey={activeMainTabId ? activeMainTabId.toString() : null}>
                        {Object.keys(lecturesBySession).map(sessionId => (
                          <Accordion.Item key={sessionId} eventKey={sessionId}>
                            <Accordion.Header
                              className={`mb-1 py-1 border float-left w-100 d-flex justify-content-between`}
                              style={sessionId < SESSION_ID || sessionId < updatesessionVideo.session || (sessionId == SESSION_ID && LECTURE_ID == lecturesBySession[sessionId][lecturesBySession[sessionId].length - 1].id) || (SESSION_ID == 6 && LECTURE_ID == 57) ?
                                { backgroundColor: '#28a7457a', color: 'white', cursor: 'pointer' } :
                                { backgroundColor: '#6B59A3', color: 'white', cursor: 'pointer' }}
                              onClick={() => handleModuleClick(sessionId)}
                            >
                              <div className="col-md-1 text-lg-center p-1 float-left d-none d-sm-none d-md-block">
                                <span className="color-white" style={{ textDecoration: 'none', color: 'white' }}>
                                  <i className="fa fa-play-circle" style={{ cursor: 'pointer' }} aria-hidden="true"></i>
                                </span>
                              </div>
                              <div className="col-md-11 d-flex justify-content-between text-left pr-45 pl-0">
                                <span className="color-white" style={{ textDecoration: 'none' }}>
                                  <h5 className="mt-2 py-2" style={{ fontWeight: 500, fontSize: 17, color: 'white' }}> Module {sessionId}</h5>
                                </span>
                                <div>
                                  {sessionId > (SESSION_ID == 0 ? 1 : SESSION_ID) && (sessionId > updatesessionVideo.session) && (selectedVideo?.sessionid || selectedVideo?.session_id) !== parseInt(sessionId) &&
                                    <i className="fa fa-lock py-2 px-2" style={{ color: 'white' }} aria-hidden="true"></i>
                                  }
                                  {activeMainTabId == sessionId ? (
                                    <i className="fa fa-sort-down fa-2x py-2 px-2" style={{ color: 'white' }} aria-hidden="true"></i>
                                  ) : (
                                    <i className="fa fa-caret-right fa-2x py-2 px-2" style={{ color: 'white' }} aria-hidden="true"></i>
                                  )}
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <ListGroup>
                                {lecturesBySession[sessionId].map((lecture) => {
                                  const isAssessmentPresent = isAssessmentPresentForLecture(lecture.id);
                                  const ValidId = SESSION_ID == 0 ? 1 : SESSION_ID;
                                  return (
                                    <div key={lecture.id} data-lecture-id={lecture.id}>
                                      <ListGroup.Item
                                        action
                                        active={activeSubTabId == lecture.id && activeMainTabId == sessionId}
                                        onClick={() => {
                                          if (updatesessionVideo !== undefined ? updatesessionVideo.lecture == lecture.id : '') {
                                            handleSubVideoSelect(lecture, 'MainVideo');
                                          } else if ((lecture.session_id > SESSION_ID) || (lecture.session_id === SESSION_ID && lecture.id > LECTURE_ID)) {
                                            // Do nothing if lecture is locked
                                          } else {
                                            handleSubVideoSelect(lecture, 'MainVideo');
                                          }
                                        }}
                                      >
                                        {(lecture.session_id > SESSION_ID || (lecture.session_id === SESSION_ID && lecture.id > LECTURE_ID)) && (updatesessionVideo != undefined ? lecture.id > updatesessionVideo.lecture : !completedVideos.includes(lecture.id)) && (selectedVideo?.iframeURL ? selectedVideo.lectureid : selectedVideo.id) !== lecture.id ? (
                                          // Lock Section
                                          <div id="mylock" className="w-100 mb-2 py-2" ref={activeSubTabId == lecture.id ? activeItemRef : null} style={{ border: 'solid 1px #c1ceff', ...(activeSubTabId == lecture.id ? { backgroundColor: '#48308c', color: 'white', cursor: 'pointer' } : { backgroundColor: '#eef1ff', color: 'black' }) }}>
                                            <div className='d-flex justify-content-between'>
                                              <div className="col-md-1 text-lg-center p-1 float-left d-none d-sm-none d-md-block">
                                                <i className="fa fa-play-circle" style={{ cursor: 'pointer' }} aria-hidden="true"></i>
                                              </div>
                                              <div className="col-md-11 float-left text-left pr-45 pl-0">
                                                <div className='d-flex justify-content-between'>
                                                  <div>
                                                    <h5 className="mt-1" style={{ fontWeight: 500, fontSize: 17 }}>
                                                      {ucwords(lecture.lecture_name)}
                                                      <i className="fa fa-lock px-2" aria-hidden="true"></i>
                                                      {isAssessmentPresent && lecture.id > ASSESSMENT && !completedVideos.includes(lecture.id) && (<i className="fa fa-question-circle px-2" aria-hidden="true"></i>)}
                                                    </h5>
                                                    {lecture.testimonial && <div className="d-flex"><p style={{ fontSize: '14px', margin: 0 }}>{lecture.description} {' [ ' + formatDuration(lecture.duration * 60) + ' ]'}</p></div>}
                                                  </div>
                                                  <div>
                                                    {lecture.testimonial && (<i className={`fa ${show && testArrowId == lecture.id ? "fa-angle-down" : "fa-angle-right"} fa-2x px-3`} aria-hidden="true" onClick={(e) => handleToggleArrowOfSubVideo(lecture.id, e)}></i>)}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between py-2">
                                              <p className="small m-0 float-left px-4">{getContatDurations(lecture)}</p>
                                              <p className="small m-0 float-right px-4">Module 0{lecture.session_id} | Unit {lecture.id} {lecture.testimonial && '| Video 1'}</p>
                                            </div>
                                          </div>
                                        ) : (
                                          // Unlock Section
                                          <div id="mytask" className="w-100 mb-2 py-2" ref={activeSubTabId == lecture.id ? activeItemRef : null} style={{ border: 'solid 1px #c1ceff', ...(activeSubTabId == lecture.id ? { backgroundColor: '#48308c', color: 'white', cursor: 'pointer' } : { backgroundColor: '#eef1ff', color: 'black' }) }}>
                                            <div className='d-flex justify-content-between'>
                                              <div className="col-md-1 text-lg-center p-1 float-left d-none d-sm-none d-md-block">
                                                <i className="fa fa-play-circle" style={{ cursor: 'pointer' }} aria-hidden="true"></i>
                                              </div>
                                              <div className="col-md-11 float-left text-left pr-45 pl-0">
                                                <div className='d-flex justify-content-between'>
                                                  <div>
                                                    <h5 className="mt-1" style={{ fontWeight: 500, fontSize: 17 }}>
                                                      {ucwords(lecture.lecture_name)}
                                                      {isAssessmentPresent && lecture.id > ASSESSMENT && (<i className="fa fa-question-circle px-2" aria-hidden="true"></i>)}
                                                    </h5>
                                                    {lecture.testimonial && <div className="d-flex"><p style={{ fontSize: '14px', margin: 0 }}>{lecture.description} {' [ ' + formatDuration(lecture.duration * 60) + ' ]'}</p></div>}
                                                  </div>
                                                  <div>
                                                    {lecture.testimonial && (<i className={`fa ${show && testArrowId == lecture.id ? "fa-angle-down" : "fa-angle-right"} fa-2x px-3`} aria-hidden="true" onClick={(e) => handleToggleArrowOfSubVideo(lecture.id, e)}></i>)}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between py-2">
                                              <p className="small m-0 float-left px-4">{getContatDurations(lecture)}</p>
                                              <p className="small m-0 float-right px-4">Module 0{lecture.session_id} | Unit {lecture.id} {lecture.testimonial && '| Video 1'}</p>
                                            </div>
                                          </div>
                                        )}
                                      </ListGroup.Item>
                                      {show && testArrowId == lecture.id && activeMainTabId == sessionId && (
                                        <div style={{ color: 'black', paddingLeft: '30px' }}>
                                          {SubVideoList.filter(record => record.name.includes(lecture.testimonial)).map((item) => (
                                            <ListGroup.Item key={item.id} action active={testimonialTabId == item.id}
                                              onClick={() => {
                                                const isMainVideoUnlocked = (lecture.session_id > SESSION_ID || (lecture.session_id === SESSION_ID && lecture.id > LECTURE_ID)) 
                                                && (updatesessionVideo != undefined ? lecture.id > updatesessionVideo.lecture : !completedVideos.includes(lecture.id)) && (selectedVideo?.iframeURL ? selectedVideo.lectureid : selectedVideo.id) !== lecture.id;
                                                if (isMainVideoUnlocked) {
                                                  console.log('still locked');
                                                } else {
                                                  handleSubVideoSelect(item, "subVideo");
                                                }
                                              }}
                                            >
                                              <div className="w-100 mb-2 py-2" style={{ border: 'solid 1px #c1ceff', ...(testimonialTabId == item.id ? { backgroundColor: 'rgb(140 140 240)', color: 'white', cursor: 'pointer' } : { backgroundColor: '#FFF', color: 'black' }) }}>
                                                <div className='d-flex justify-content-between'>
                                                  <div className="col-md-1 text-lg-center p-1 float-left d-none d-sm-none d-md-block">
                                                    <i className="fa fa-play-circle" style={{ cursor: 'pointer', color: testimonialTabId == item.id ? 'white' : '#402F7B' }} aria-hidden="true"></i>
                                                  </div>
                                                  <div className="col-md-11 float-left text-left pr-45 pl-0">
                                                    <div className='d-flex justify-content-between'>
                                                      <h5 className="mt-1" style={{ fontWeight: 'bold', fontSize: 15, color: testimonialTabId == item.id ? 'white' : '#402F7B' }}>
                                                        {/* {item.title} */}
                                                        <RemoveSpecialCharacters text={item.title} />
                                                        {/* {lecture.session_id > SESSION_ID || (lecture.session_id === SESSION_ID && lecture.id > LECTURE_ID)
                                                         && (updatesessionVideo != undefined ? lecture.id > updatesessionVideo.lecture : !completedVideos.includes(lecture.id)) && (selectedVideo?.iframeURL ? selectedVideo.lectureid : selectedVideo.id) !== lecture.id ? <i className="fa fa-lock px-2" style={{ color: testimonialTabId == item.id ? 'white' : '#402F7B' }} aria-hidden="true"></i> : ''} */}
                                                        {/* {lecture.session_id >= SESSION_ID && lecture.id > LECTURE_ID && (selectedVideo.iframeURL ? selectedVideo.lectureid : selectedVideo.id) !== lecture.id && updatesessionVideo !== selectedVideo.id && <i className="fa fa-lock px-2" style={{ color: testimonialTabId == item.id ? 'white' : '#402F7B' }} aria-hidden="true"></i>} */}
                                                        {(lecture.session_id > SESSION_ID || (lecture.session_id === SESSION_ID && lecture.id > LECTURE_ID)) 
                                                        && (updatesessionVideo != undefined ? lecture.id > updatesessionVideo.lecture : !completedVideos.includes(lecture.id)) && (selectedVideo?.iframeURL ? selectedVideo.lectureid : selectedVideo.id) !== lecture.id ? 
                                                        <i className="fa fa-lock px-2" style={{ color: testimonialTabId == item.id ? 'white' : '#402F7B' }} aria-hidden="true"></i> : ''}
                                                      </h5>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="d-flex justify-content-between py-2">
                                                  <p className="small m-0 float-left px-4" style={{ color: testimonialTabId == item.id ? 'white' : '#402F7B' }}>
                                                    {formatDuration(item.duration)}
                                                  </p>
                                                  <p className="small m-0 float-right px-4" style={{ color: testimonialTabId == item.id ? 'white' : '#402F7B' }}>
                                                    Module 0{lecture.session_id} | Unit {lecture.id} | Video {SubVideoList.filter(record => record.name.includes(lecture.testimonial)).indexOf(item) + 2}
                                                  </p>
                                                </div>
                                              </div>
                                            </ListGroup.Item>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </ListGroup>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    )}
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        )}
      </Container>

      <PostQuestionModal
        show={showModal}
        handleClose={handleClose}
        onSubmit2={handleSubmit2}
        postCard={postCard}
        title="Ask Query"
        placeholder="Ask Your Query"
        cancel="Close"
        post="Send Enquiry"
      />

      <GuideBookModal show={showCertificateModal} handleClose={() => setCertificateShowModal(false)} Url={GuideBookDownloadUrl} />

      <div className="modal fade" id="completedModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h2 className="text-bold  uppercase">Congratulations</h2>
              <img className="w-100" src={winner} alt="Congratulations" /><br /> <br />
              <div className="h6">
                <span id="printmycertificate" style={{ textDecoration: 'underline', 'color': '#007bff' }} onClick={handleDownloadCertificate}>Download my certificate | </span>
                <a style={{ textDecoration: "underline" }} onClick={handlegetFeedback}>Share your feedback</a> | <a id="goback" href="/myCourse">Go Back</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showLegency && <LegalModel show={showLegency} handleClose={handleLegencyClose} />}
      </div>
      <div className='ms-2'>
        {showComplete && <CompleteModal show={showComplete} handleClose={handleClose} />}
      </div>
      {showPopup && isOffline && (
        <div className="offline-popup">
          <InternetModal showPopup={showPopup} handleClosePopup={handleClosePopup} />
        </div>
      )}
      {isSlowConnection && (
        <div className="slow-connection-popup">
          <InternetSlowModal showPopup={showPopup} handleClosePopup={handleClosePopup} />
        </div>
      )}
      {inactive && (
        <div className="inactivity-popup">
          <InactiveModal showPopup={showPopup} handleClosePopup={handleClosePopup} />
        </div>
      )}
      {
        showWrong && <SomethingError showWrong={showWrong} handleWrongClose={handleWrongClose} />
      }
    </div>
  );
}

export default WatchVideoPage;