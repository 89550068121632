import React, { useEffect, useMemo, useState } from 'react';
import './MyCoursePage.css';
import clock from '../../Assets/images/clock.png';
import { useDispatch, useSelector } from 'react-redux';
import { ActionDownloadGuideBook, ActionGetCommunity, ActionGetCourseCompletionCertificate, ActiongetCourseVideos, ActiongetCurrentActiveOrderDetails, ActiongetIntiallcourseDetails, ActionUpdateCourseSession } from '../../store/actions/actions-server-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import CourseDetailPage from '../CourseDetail/CourseDetailPage';

function MyCoursePage(props) {
    const [courseCard, setCourseCard] = useState(null);
    const [renewed, setRenewed] = useState('');
    const [orderDetail, setOrderDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const userData = useSelector(state => state);
    const UserAccessToken = userData.rServerData.Usertoken || null;
    const isLoading = userData.rLoading ? userData.rLoading.isWatchLoading : null;
    const isLoadingOrder = userData.rLoading?.CurrentOrder || false;
    const watchVideo = userData.rServerData.watchVideos != undefined ? userData.rServerData.watchVideos.videoData : [];
    const dispatch = useDispatch();
    const UserDetails = useMemo(() => userData.rServerData.userDataLogggedIn || [], [userData.rServerData.userDataLogggedIn]);
    const renewalButtons = UserDetails;
    const { getCourseProgress, CurrentOrder } = userData.rServerData;
    const Certifcate = userData.rServerData.completionCertificate ? userData.rServerData.completionCertificate : null;
    const renewalBtns = userData.rServerData?.IntiallcourseDetails;

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userData.rServerData.IntiallcourseDetails) {
                    const { coursedetails, orders, Renewed } = userData.rServerData.IntiallcourseDetails;
                    setCourseCard(coursedetails);
                    setOrderDetail(orders);
                    setRenewed(Renewed);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [userData.rServerData, dispatch, orderDetail]);

    useEffect(() => {
        const param = { token: UserAccessToken };
        dispatch(ActionDownloadGuideBook(param));
        const orders = userData.rServerData?.IntiallcourseDetails != undefined ? userData.rServerData.IntiallcourseDetails.orders : [];
     
        if(orders.length > 0){
            dispatch(ActiongetIntiallcourseDetails(userData.rServerData.IntiallcourseDetails));
            dispatch(ActiongetCurrentActiveOrderDetails({ userId: UserDetails.id, courseId: 10, orderId: orders[0].orderid, token: UserAccessToken }));
        }
        if (!Certifcate) {
            if (orders) {
                dispatch(ActionGetCourseCompletionCertificate({ userId: UserDetails.id, token: UserAccessToken }));
            }
        }
    }, [])

    useEffect(() => {
        dispatch(ActionGetCommunity({token: UserAccessToken}));
        if (UserDetails.id && orderDetail !== null) {
            const param1 = {
                courseid: 10,
                userId: UserDetails.id,
                sessionid: orderDetail && orderDetail[0]?.session_progress == null ? 1 : orderDetail[0]?.session_progress,
                lectureid: orderDetail && orderDetail[0]?.lecture_progress == null ? 1 : orderDetail[0]?.lecture_progress,
                testimonialid: null,
                videoid: null,
                token: UserAccessToken,
            };
            if (watchVideo && watchVideo.length == 0) {
                dispatch(ActiongetCourseVideos(param1));
            }
        }
    }, [orderDetail])

  

    const formatPurchaseDate = (timestamp) => {
        const date = new Date(timestamp);
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const expiryDateString = orderDetail ? new Date(orderDetail[0].expired_at) : null;
    const currentDate = new Date();
    const expiryDate1 = new Date(formatPurchaseDate(expiryDateString));
    let expirationMessage = "";
    if (currentDate < expiryDate1) {
        expirationMessage = `Your course is going to expire on ${formatPurchaseDate(expiryDate1)}. You can renew it `;
    } else {
        expirationMessage = `Your course has expired on ${formatPurchaseDate(expiryDate1)}. You can renew it `;
    }

    const expiryDate = orderDetail ? new Date(orderDetail[0].expired_at) : null;
    if (expiryDate) {
        expiryDate.setDate(expiryDate.getDate() + 1);
    }
    const noticePeriodDate = orderDetail ? new Date(orderDetail[0].expired_at) : null;
    if (noticePeriodDate) {
        noticePeriodDate.setMonth(noticePeriodDate.getMonth() - 1);
    }

    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        const pad = (num) => String(num).padStart(2, '0');

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };

    function getOneMonthBefore(expirationDate) {
        // Parse the expiration date
        const date = new Date(expirationDate);

        // Subtract one month
        date.setMonth(date.getMonth() - 1);

        // Format the date to Y-M-D
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        // Return the formatted date
        return `${year}-${month}-${day}`;
    }

    const expirationDate = renewalBtns?.orders[0]?.expired_at;
    const oneMonthBefore = getOneMonthBefore(expirationDate);
    const newDate = new Date().toISOString().split('T')[0];
    const showButton = newDate >= oneMonthBefore;
    return (
        <>
            {isLoading || isLoadingOrder ?
                <div className="">
                    <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
                        <p className='mt-2' >Please wait...</p>
                    </div>
                </div> :
                <div className={`container-fluid ${orderDetail?.length > 0 ? 'myCourse' : 'noCourse'}`} >
                    <div className='px-3'>
                        <div className='myCourseContainer'>
                            <div className='py-4'>
                                <h1 className='myCourseHeading'>MY PURCHASED <span>COURSES</span></h1>
                            </div>
                            {courseCard && orderDetail.length > 0 ? (
                                    <>
                                        <div className='myCourseCardCont mt-3 mb-5'>
                                            <a href={expiryDate && expiryDate <= currentDate && orderDetail.length > 0 ? "javascript:void(0);" : "/courseDetail"} style={expiryDate && expiryDate <= currentDate ? { opacity: 0.5, textDecoration: 'none', cursor: 'not-allowed', pointerEvents: 'none' } : { textDecoration: 'none' }}>
                                                <div className='myCardInner row'>
                                                    <div className='myCourseLeft col-md-3'>
                                                        <img src={courseCard.course_image} alt='img' />
                                                    </div>
                                                    <div className='myCourseRight col-md-9 px-4 my-2'>
                                                        <div>
                                                            <h3 className='my_cardHeading'>{courseCard.course_name}
                                                                {renewed && renewed.Renewed ? renewed.Renewed : ''}
                                                            </h3>
                                                            <p>
                                                                <span style={{ paddingRight: '10px', color: 'black' }}>
                                                                    <img src={clock} alt='img' />
                                                                </span><span style={{ paddingRight: '10px', color: 'black' }}>{formatDuration(courseCard.course_duration)}</span>
                                                            </p>
                                                            <p style={{
                                                                color: "#777777",
                                                                fontSize: "0.89rem",
                                                                fontWeight: "500"
                                                            }}>{courseCard.course_description}</p>
                                                        </div>
                                                        <div className='subCards'>
                                                            <div className='purchaseCard'>
                                                                <h2>PURCHASE DATE</h2>
                                                                {orderDetail && orderDetail.length > 0 && orderDetail[0].created_at && (
                                                                    <p>{formatPurchaseDate(orderDetail[0].created_at)}</p>
                                                                )}
                                                            </div>
                                                            <div className='myCourseLine'></div>
                                                            {orderDetail && orderDetail.length > 0 && (
                                                                <div className='purchaseCard'>
                                                                    <h2>COURSE STATUS</h2>
                                                                    {CurrentOrder['session_progress'] == 6 && CurrentOrder['lecture_progress'] >= 57 ? (
                                                                        <p>Completed</p>
                                                                    ) : (
                                                                        CurrentOrder['session_progress'] == 1 && CurrentOrder['lecture_progress'] == 1 ? (
                                                                            <p>Not Yet Started</p>
                                                                        ) : (
                                                                            <p>{CurrentOrder['assessment_session_status']}</p>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}

                                                            <div className='myCourseLine'></div>
                                                            {orderDetail && orderDetail.length > 0 && (
                                                                <div className='purchaseCard'>
                                                                    <h2>EXPIRATION</h2>
                                                                    <p>{formatPurchaseDate(orderDetail[0].expired_at)}</p>
                                                                </div>
                                                            )}
                                                            <div className='myCourseLine'></div>
                                                            {orderDetail && orderDetail.length > 0 && (
                                                                <div className='purchaseCard'>
                                                                    <h2>CURRENT PROGRESSION</h2>
                                                                    <p>MODULE {CurrentOrder.session_progress} - UNIT {CurrentOrder.lecture_progress}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        {/* || showButton && */}
                                        {renewalButtons && renewalButtons.is_notice_enabled === 1 &&
                                            <div className='renewalButtons'>
                                                <p>
                                                    {expirationMessage}
                                                    <button className='mx-2'>
                                                        <a href="/allCourse">1 year for $ 250</a>
                                                    </button>
                                                    <button className='mx-2'>
                                                        <a href="/allCourse">3 years for $ 500</a>
                                                    </button>
                                                </p>
                                            </div>
                                        }
                                    </>
                                ) : (
                                    <div className='noOrdersDiv'>
                                        <p>No orders currently present. <span id="goahead"><a href="/allCourse">Goahead you can purchase one <i class="fa-solid fa-arrow-right" id="arrowCircle"></i></a></span></p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default MyCoursePage;
