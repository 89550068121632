import React, { useEffect, useState } from 'react';
import './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import InputText from '../../components/Form/InputText';
import { loginFormValidationSchema } from '../../utils/utils-validation';
import Blowfish from 'blowfish';
import { Buffer } from 'buffer';
import { NotificationContainer } from 'react-notifications';
import { useHistory } from 'react-router';
import { ActionLogin } from '../../store/actions/actions-user';

function Login() {
    const [email, setEmail] = useState(''); 
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const user = useSelector((state) => state.rLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(user.login ? false : true);
        const rememberedEmail = localStorage.getItem('rememberedEmail');
        const rememberedPassword = localStorage.getItem('rememberedPassword');
        if (rememberedEmail && rememberedPassword) {
            try {
                const base64Decode = (str) => {
                    const decoded = Buffer.from(str, 'base64').toString('utf-8');
                    return decoded.replace(/\0/g, ''); // Remove NUL characters
                };
                const bf = new Blowfish('R1iN1EuGA');
                const decryptedPassword = bf.decrypt(base64Decode(rememberedPassword));
                var Password = decryptedPassword.replace(/[^a-zA-Z0-9@~!`#$%^&*()]/g, '');
                setEmail(rememberedEmail);
                setPassword(Password);
                setRemember(true);
            } catch (error) {
                console.error('Error decrypting password:', error);
            }
        }

    }, [user, remember]);

    const handleLogin = (values) => {
        if (values.rememberMe) {
            var bf = new Blowfish('R1iN1EuGA');
            var encryptedPassword = bf.encrypt(values.password);
            var BufferePassword = Buffer.from(encryptedPassword).toString('base64');
            localStorage.setItem('rememberedEmail', values.email);
            localStorage.setItem('rememberedPassword', BufferePassword);
        } else {
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('rememberedPassword');
        }
        dispatch(ActionLogin(values));
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 2500);
    };

    return (
        <div className="d-flex" id="loginPage">
            <div className="col-md-12 col-sm-12 float-left loginForm">
                <h3 className="color-white text-center py-2 text-bold welcome">WELCOME</h3>
                <div className="card-body px-5" style={{ color: '#77E3DE', width: '100%' }}>
                    <Formik
                        initialValues={{ email: email, password: password, rememberMe: remember }}
                        validationSchema={loginFormValidationSchema}
                        onSubmit={(values) => handleLogin(values)}
                        enableReinitialize
                    >
                        {({ values, handleChange, handleBlur, isSubmitting }) => (
                            <Form className="w-100">
                                <div className="mb-2" id="f-26">
                                    E-mail Address
                                </div>
                                <div className="position-relative login-input">
                                    <InputText
                                        name="email"
                                        placeholder="Enter your Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        autoComplete="email"
                                    />
                                </div>
                                <div className="mb-2 py-2" id="f-26">
                                    Password
                                </div>
                                <div className="login-input">
                                    <InputText
                                        name="password"
                                        type="password"
                                        placeholder="Enter your Password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <div className="d-flex justify-content-between w-100 pt-2 pb-2 forgot">
                                    <div className="position-relative login-input">
                                        <input
                                            type="checkbox"
                                            name="rememberMe"
                                            checked={values.rememberMe}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="rememberMe" className="form-check-label px-2" id="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                    <p style={{ color: '#FFFFF' }}>
                                        {' '}
                                        <a href="/ForgotPassword">Forgot Password?</a>
                                    </p>
                                </div>
                                <div className="d-flex justify-content-center mt-2">
                                    <button className="nextBtn" type="submit" disabled={isButtonDisabled}>
                                        {loading ? 'LOGIN' : 'Logging in...'}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div className="col-md-12 text-center mt-4">
                        <span className="registerLink">
                            Don't have an Account? <a href="/register" id="registerHere">REGISTER HERE</a>
                        </span>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
}

export default Login;
