import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button, Row, Col, FormCheck } from 'react-bootstrap';
import applyforCourse from '../../Assets/images/apply-course-img.png';
import './ApplyCourse.css';
import { useDispatch, useSelector } from 'react-redux';
import { ActionApplyCourse, ActionVerifyGiftEmail, ActiongetCountries, ActiongetStates } from '../../store/actions/actions-server-data';
import { ActionRouteNavigate } from '../../store/actions/actions-route';

function ApplyCourse(props) {
    const coursePrice = props.history.location.state;
    const [isGiftChecked, setIsGiftChecked] = useState(false);
    // const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [errors, setErrors] = useState('');
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [loadingStates, setLoadingStates] = useState(false);
    const [errorAgree, setErrorAgree] = useState('');
    const [mainCoursePrice, setMainCoursePrice] = useState(995);
    const [isChecked, setIsChecked] = useState(false);


    const userState = useSelector(state => state);
    const UserAccessToken = userState.rServerData.Usertoken || null;
    const verifyEmailLoading = userState.rLoading != undefined ? userState.rLoading.verifygiftemail : false;
    const userLoggedIn = userState.rServerData.userDataLogggedIn ? userState.rServerData.userDataLogggedIn : '';
    const userDetails = userState.rServerData.useraccount ? userState.rServerData.useraccount.userDetails : [];
    const notifyMessage = userState.rServerData.applyCourse ? userState.rServerData.applyCourse.notice : [];
    const orderDetails = userState.rServerData.useraccount ? userState.rServerData.useraccount.orders : [];
    const verifygiftemail = userState.rServerData.verifygiftemail != undefined ? userState.rServerData.verifygiftemail : '';
    const applyCourse = userState.rServerData.applyCourse ? userState.rServerData.applyCourse.coursedetails : [];
    const order = userState.rServerData.IntiallcourseDetails ? userState.rServerData.IntiallcourseDetails : [];
    const Countries = userState.rServerData.useraccount ? userState.rServerData.useraccount.userDetails.country : [];

    const dispatch = useDispatch();
    const countries = useMemo(
        () => userState.rServerData.countriesList || [],
        [userState.rServerData.countriesList]
    );

    const states = useMemo(
        () => userState.rServerData.stateList || [],
        [userState.rServerData.stateList]
    );

    const [formData, setFormData] = useState({
        name: userDetails.firstname || '',
        lastname: userDetails.lastname || '',
        email: userDetails.email || '',
        phoneNumber: userDetails.contact_number || '',
        address: userDetails.address || '',
        country: userDetails.country || '',
        state: userDetails.state || '',
        city: userDetails.city || '',
        zipCode: userDetails.zipcode || '',
        promocode: '',
        gift_firstName: '',
        gift_email: '',
        gift_date: '',
        agree: ''
    });

    const [formErrors, setFormErrors] = useState({
        gift_firstName: '',
        gift_email: '',
        gift_date: '',
        agree: ''
    });

    const [Country, setCountry] = useState(formData.country || "");
    const [State, setState] = useState(formData.state || "");
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name === "country") {
            const selectedCountryObj = countries.find((country) => country.country_name === value);
            if (selectedCountryObj) {
                setSelectedCountry(selectedCountryObj.Countries);
                dispatch(ActiongetStates({ country_code: selectedCountryObj.country_code }));
            }
        }
        setFormErrors({ ...formErrors, [name]: "" });
        setErrorAgree("");

        document.getElementById('purchaseSubmit').disabled = !isChecked;
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        const errors = {};
        if (!formData.gift_email.trim()) {
            errors.gift_email = 'Please enter the gifting person\'s email';
        } else if (!/\S+@\S+\.\S+/.test(formData.gift_email)) {
            errors.gift_email = 'Please enter a valid email address';
        } else {
            if (name === 'gift_email') {
                const giftUserEmail = value;
                const obj = {
                    token: UserAccessToken,
                    email: giftUserEmail
                };
                dispatch(ActionVerifyGiftEmail(obj));
            }
        }
        setFormErrors({ ...formErrors, [name]: "" });
    };

    const handleCountryChange = (e) => {
        const countryCode = e.target.value;
        setCountry(countryCode);
        dispatch(ActiongetStates({ country_code: countryCode }));
    };

    const handleStateChange = (e) => {
        setState(e.target.value);
    };

    useEffect(() => {
        dispatch(ActiongetCountries());
        dispatch(ActiongetStates({ country_code: formData.country }));
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const obj = {
            name: formData.name,
            email: formData.email,
            price: coursePrice || applyCourse.course_price,
            zipCode: formData.zipCode,
            InputAddress: formData.address,
            InputCity: formData.city,
            gifting_option: isGiftChecked ? 'on' : 'off',
            gifting_name: formData.gift_firstName,
            gifting_email: formData.gift_email,
            gifting_date: formData.gift_date
        };

        if (isGiftChecked) {
            if (validateForm()) {
                dispatch(ActionRouteNavigate('authorizePay', obj));
            }
        } else {
            if (isChecked && userDetails.is_course_purchased == 0) {
                dispatch(ActionRouteNavigate('authorizePay', obj));
                console.log('clikced');
            }
            if (coursePrice == 250 || coursePrice == 500) {
                dispatch(ActionRouteNavigate('authorizePay', obj))
                return ''
            }
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.gift_firstName.trim()) {
            errors.gift_firstName = 'Please enter the gifting person\'s first name';
        }
        if (!formData.gift_email.trim()) {
            errors.gift_email = 'Please enter the gifting person\'s email';
        } else if (!/\S+@\S+\.\S+/.test(formData.gift_email)) {
            errors.gift_email = 'Please enter a valid email address';
        } else if (verifygiftemail.data !== null) {
            errors.gift_email = verifygiftemail.message;
        }
        if (!formData.gift_date) {
            errors.gift_date = 'Please select a gifting date';
        }
        setFormErrors(errors);
        const hasErrors = Object.keys(errors).length > 0;
        return !hasErrors;
    };
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (verifygiftemail.data !== null) {
            setFormErrors({ ...formErrors, gift_email: verifygiftemail.message });
        } else {
            setFormErrors({ ...formErrors, gift_email: '' });
        }
        document.getElementById('purchaseSubmit').disabled = !isChecked;
    }, [verifygiftemail.data, isChecked]);

    return (
        <div className='container-fluid py-5 applyContainer'>
            <div className='container'>
                <div className='row'>
                    <div className="col-md-6">
                        {order && order.orders?.length > 0 ? coursePrice == 250 || coursePrice == 500 ? '' :
                            <div className='alert alert-danger' role='alert'>
                                {notifyMessage.NotifyMessage}
                            </div> : ''
                        }
                        <div>
                            <h1 className='applyHeading'>ENTER YOUR DETAILS</h1>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className='mb-3'>
                                        <FormLabel htmlFor="name">FirstName:</FormLabel>
                                        <FormControl
                                            className='applyFormControl'
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Enter your name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className='mb-3'>
                                        <FormLabel htmlFor="lastname">LastName:</FormLabel>
                                        <FormControl
                                            className='applyFormControl'
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            placeholder="Enter your lastname"
                                            value={formData.lastname}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup className='mb-3'>
                                <FormLabel htmlFor="email">Email address:</FormLabel>
                                <FormControl
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Enter your email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <FormLabel htmlFor="phoneNumber">Phone Number:</FormLabel>
                                <FormControl
                                    className='applyFormControl'
                                    type="tel"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    placeholder="Enter your phone number"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup className='mb-3'>
                                <FormLabel htmlFor="address">Address:</FormLabel>
                                <FormControl
                                    className='applyFormControl'
                                    type="text"
                                    name="address"
                                    id="address"
                                    placeholder="Enter your address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    disabled
                                />
                            </FormGroup>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="country">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Select
                                            name="country"
                                            value={Country}
                                            onChange={handleCountryChange}
                                            required
                                            disabled
                                        >
                                            <option value="">Select Country</option>
                                            {countries.length > 0 &&
                                                countries.map((items) => (
                                                    <option key={items.id} value={items.country_code}>
                                                        {items.country_name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="state">
                                        <Form.Label>State</Form.Label>
                                        <Form.Select
                                            name="state"
                                            value={State}
                                            onChange={handleStateChange}
                                            required
                                            style={{ padding: '9px 5px' }}
                                            disabled
                                        >
                                            <option value="">Select State</option>
                                            {states.length > 0 &&
                                                states.map((record) => (
                                                    <option key={record.id} value={record.state_code}>
                                                        {record.state_name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className='mb-3'>
                                        <FormLabel htmlFor="city">City:</FormLabel>
                                        <FormControl
                                            className='applyFormControl'
                                            type="text"
                                            name="city"
                                            id="city"
                                            placeholder="Enter your city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className='mb-3'>
                                        <FormLabel htmlFor="zipCode">Zip Code:</FormLabel>
                                        <FormControl
                                            className='applyFormControl'
                                            type="text"
                                            name="zipCode"
                                            id="zipCode"
                                            placeholder="Enter your zip code"
                                            value={formData.zipCode}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div>
                                        Promo Code (Optional)
                                    </div>
                                    <FormGroup className='mb-3'>
                                        <FormControl
                                            className='applyFormControl'
                                            type="text"
                                            name="promocode"
                                            id="promocode"
                                            placeholder="Enter Promo code"
                                            value={formData.promocode}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <button type="button" className="applyBtn">Apply Promocode</button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <h2 className='pt-1' style={{ fontSize: '16px' }}>Total</h2>
                                </Col>
                                <Col md={6}>
                                    <p className='pt-1'>{coursePrice ? "$" + coursePrice : "$" + mainCoursePrice}</p>
                                </Col>
                            </Row>
                            {coursePrice == 250 || coursePrice == 500 ? '' :
                                <>
                                    <FormGroup>
                                        <FormCheck
                                            type='checkbox'
                                            label='Want to gift this to your special one!'
                                            onChange={(e) => {
                                                setIsGiftChecked(e.target.checked);
                                                handleChange(e);
                                            }}
                                            checked={isGiftChecked}
                                        />
                                    </FormGroup>
                                    {isGiftChecked && (
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className='mt-3'>
                                                    <FormLabel htmlFor="gift_firstName">Gifting person name:</FormLabel>
                                                    <FormControl
                                                        className='applyFormControl'
                                                        type="text"
                                                        name="gift_firstName"
                                                        id="gift_firstName"
                                                        placeholder="Enter gifting person name"
                                                        value={formData.gift_firstName}
                                                        onChange={handleChange}
                                                    />
                                                </FormGroup>
                                                {formErrors.gift_firstName && <p className="text-danger">{formErrors.gift_firstName}</p>}
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className='mt-3'>
                                                    <FormLabel htmlFor="gift_email">Gifting person email</FormLabel>
                                                    <div className="position-relative">
                                                        <FormControl
                                                            type="email"
                                                            name="gift_email"
                                                            id="gift_email"
                                                            placeholder="Enter gifting person email"
                                                            value={formData.gift_email}
                                                            onBlur={handleBlur}
                                                            onChange={(e) => setFormData({ ...formData, gift_email: e.target.value })}
                                                        />
                                                        {verifyEmailLoading && (
                                                            <div className="position-absolute top-50 start-100 translate-middle" style={{ right: '-50px', color: '#6f42c1' }}>
                                                                <i className="fa fa-spinner fa-spin"></i>
                                                            </div>
                                                        )}
                                                    </div>
                                                </FormGroup>
                                                <p className='' style={{ color: 'red' }}>
                                                    {userLoggedIn.email === verifygiftemail.data?.email ? 'It seems you want to gift it to yourself' : ''}
                                                </p>
                                                {formErrors.gift_email && <p className="text-danger">{formErrors.gift_email}</p>}
                                            </Col>
                                            <FormGroup className='mt-1'>
                                                <FormLabel htmlFor="gift_date">On which date to gift?</FormLabel>
                                                <FormControl
                                                    type="date"
                                                    name="gift_date"
                                                    id="gift_date"
                                                    value={formData.gift_date}
                                                    onChange={handleChange}
                                                    min={getCurrentDate()}
                                                />
                                            </FormGroup>
                                            {formErrors.gift_date && <p className="text-danger">{formErrors.gift_date}</p>}
                                        </Row>
                                    )}
                                </>
                            }
                            <FormGroup className='mb-1'>
                                <FormCheck
                                    type='checkbox'
                                    label={
                                        <>
                                            I Agree with the{' '}
                                            <a href="#">Terms & Conditions</a>{' '}
                                            &{' '}
                                            <a href="#">Privacy Policy</a>
                                        </>
                                    }
                                    name="agree"
                                    id="agree"
                                    checked={isChecked}
                                    onChange={(e) => {
                                        setIsChecked(e.target.checked);
                                        handleChange(e);
                                    }}
                                />
                            </FormGroup>
                            <p className='text-danger px-4'>{errorAgree ? errorAgree : ''}</p>
                            <FormGroup>
                                <button type="submit" className="applySubmit w-100" id='purchaseSubmit'
                                    style={{ backgroundColor: isChecked ? '#6B59A3' : 'rgb(107 89 163 / 73%)' }}
                                >
                                    Buy the Course
                                </button>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className="col-md-6">
                        <div className='applyRight'>
                            <img src={applyforCourse} alt="img" className='applyRightImg' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplyCourse;
