import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default function InactiveModal({showPopup,handleClosePopup}) {
    return (
        <div>
            <div>
                <Modal show={showPopup} onHide={handleClosePopup} animation={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>

                        <Modal.Title>
                            <h2 class="text-bold  uppercase">In active for long time </h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="modal-body">

                            <p><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Due to no activity for a long time,
                                this session has been logged out automatically</p>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClosePopup}>close</Button>

                    </Modal.Footer>
                </Modal>
            </div >
        </div>
    )
}