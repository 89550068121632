import React, { useEffect, useRef, useState } from 'react';
import './HeaderLayout.css';
import headerLogo from '../../Assets/images/headerlogo.png';
import hamberg from '../../Assets/images/hamberg.png';
import SideBar from '../SideBar/SideBar';
import ProfileModal from '../ProfileModal/ProfileModal';
import { useSelector } from 'react-redux';
import userIcon from '../../Assets/images/userIcon.png';


function HeaderLayout({ commonData, data, subData, backgroundImage }) {
    const MyState = useSelector((state) => state);
    const User = MyState.rServerData.useraccount ? MyState.rServerData.useraccount.userDetails : [];
    const UserAccessToken = MyState.rServerData.Usertoken
        ? MyState.rServerData.Usertoken
        : null;
    const [show, setShow] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const modalRef = useRef(null);
    const handleModal = () => {
        setProfileModal(true)
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setProfileModal(false);
        }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return (
        <div className='container-fluid headerContainer ' style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className='container'>
                <div className='row pt-3'>
                    <div className='leftPart col-md-12'>
                        <div className='row'>
                            <div className={`container fixed-top  ${isScrolled ? 'scrolled shadow' : ''}`} id="fixedHeader">
                                <div className='row w-100'>
                                    <div className='col-md-6 col-lg-6'>
                                        <a href='/'><img src={headerLogo} alt="logo" /></a>
                                    </div>
                                    <div className='col-md-6 justify-content-end'>
                                        <div className='d-flex align-items-center justify-content-end w-100 gap-3'>
                                            <div className='profile' onClick={handleModal}>
                                                {UserAccessToken ? (
                                                    User?.profile_image === null ? <img src={userIcon} /> : <img src={User.profile_image} />
                                                ) : (
                                                    <a href="/login">
                                                        <div className="profile">
                                                            <img src={userIcon} />
                                                        </div>
                                                    </a>
                                                )}
                                            </div>
                                            <img src={hamberg} alt="logo" style={{ cursor: 'pointer', padding: '3px' }} onClick={handleShow} />
                                        </div>
                                        <div ref={modalRef}>
                                            {UserAccessToken && profileModal ? <ProfileModal User={User} /> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=' col-md-8 col-lg-8' style={{ paddingTop: '110px', paddingLeft: '100px' }}>
                                <div className=''>
                                    <h1 className='dataHead'>{data}</h1>
                                    {data == 'WATCH VIDEO' ? (
                                        <span className='headerAnchor'>
                                           <a href="/" style={{ color: 'white' }}>HOME</a> /
                                           <a href='/courseDetail'> COURSE DETAIL /</a>
                                           {subData && '/'} {subData || data ? <span className='subDataItem'>{data}</span> : ''}
                                        </span>
                                    ):(
                                        <span className='headerAnchor'>
                                           <a href="/" style={{ color: 'white' }}>HOME</a> /
                                           <a href='/community'>{commonData}</a>
                                           {subData && '/'} {subData || data ? <span className='subDataItem'>{data}</span> : ''}
                                        </span>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {show ? <SideBar show={show} handleClose={handleClose} /> : ''}
        </div>
    )
}
export default HeaderLayout;
