import React, { useState } from 'react';
import footerLogoPic from '../../Assets/images/footerLogo.png';
import './FooterLayout.css';
import message from '../../Assets/images/message.png';
import youtube from '../../Assets/images/youtube.svg';
import facebook from '../../Assets/images/facebook.svg';
import linkedIn from '../../Assets/images/linkedIn.svg';
import calender from '../../Assets/images/calendar.png';
import head from '../../Assets/images/human.jpg';
import human from '../../Assets/images/diabetes.webp';
import footerPic from '../../Assets/images/biomagnetism.webp';
import kmel from '../../Assets/images/kmel.png';
import copyRight from '../../Assets/images/copyRight.png';
import { useDispatch, useSelector } from 'react-redux';
import { ActionSaveSubscriber } from '../../store/actions/actions-server-data';
import { Formik, Form } from 'formik';
import { subscriberValidationSchema } from '../../utils/utils-validation';
import InputText from '../../components/Form/InputText';

function FooterLayout() {

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.rLoading.savesubscriber);

    const [loading, setLoading] = useState(false);


    const handleSaveSubscriber = async (values) => {
        setLoading(true);
        const param = { email: values.email };
        await dispatch(ActionSaveSubscriber(param));
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    return (
        <div className='container-fluid' id="footer">
            <div className='' style={{ padding: '0px 50px', marginBottom: '30px' }}>
                <div className='row'>
                    <div className='leftSection col-md-4'>
                        <div className='leftInner'>
                            <img src={footerLogoPic} alt='logo' style={{ width: '300px', height: '100px' }} />
                            <h3 className='pt-2'>North Brunwick, NJ 08902</h3>
                            <div className='d-flex gap-2 align-items-center' style={{ height: '40px', width: '100%' }}>
                                <img src={message} alt='img' className='leftMessage' />
                                <a href="/contact-us" className='leftContact'>Contact Us</a>
                            </div>
                            <div className='d-flex gap-2 align-items-center mb-2'>
                                <img src={message} alt='img' className='leftMessage' />
                                <a href="mailto:info@usbiomag.com" className='leftContact'>info@usbiomag.com</a>
                            </div>
                            <div className='d-flex gap-3'>
                                <a href="https://www.youtube.com/channel/UCFGfG5Wc9SGhbG-o5p6Xhag" target="_blank"><img src={youtube} alt='img' /></a>
                                <a href="https://www.facebook.com/AmericanBiomagnetism/" target="_blank"><img src={facebook} alt='img' /></a>
                                <a href="https://www.linkedin.com/company/dr-garcia-biomagnetism" target="_blank"><img src={linkedIn} alt='img' /></a>
                            </div>
                        </div>
                    </div>
                    <div className='centerSection col-md-4'>
                        <div className='mb-4'>
                            <h2 className='centerHeading'>RECENT POSTS</h2>
                        </div>
                        <div className='d-flex gap-2 mb-4 recent'>
                            <div>
                                <img src={human} alt='img' className='human' />
                            </div>
                            <div className='revlutionContainer'>
                                <a href="https://drgarciabiomagnetism.com/diabetes-and-biomagnetism/" target='_blank'>
                                    <h3 className='revlution'>Diabetes and Biomagnetism</h3>
                                </a>

                                <p className='revlutionPara'>
                                    <span>
                                        <img src={calender} alt="img" className='footImg' />
                                    </span>March 4, 2024</p>
                            </div>
                        </div>
                        <div className='d-flex gap-2 mb-4 recent'>
                            <div>
                                <img src={head} alt='img' className='human' />
                            </div>
                            <div className='revlutionContainer'>
                                <a href="https://drgarciabiomagnetism.com/revolutionizing-chiropractic/" target="_blank">
                                    <h3 className='revlution'>Revolutionizing Chiropractic</h3>
                                </a>
                                <p className='revlutionPara'>
                                    <span>
                                        <img src={calender} alt="img" className='footImg' />
                                    </span>February 29, 2024</p>
                            </div>
                        </div>
                        <div className='d-flex gap-2  recent'>
                            <div>
                                <img src={footerPic} alt='img' className='human' />
                            </div>
                            <div className='revlutionContainer'>
                                <a href="https://drgarciabiomagnetism.com/biomagnetism-and-magnetic-therapy-are-they-different-therapies/" target="_blank">
                                    <h3 className='revlution'>Biomagnetism and Magnetic Therapy: Are they different therapies?</h3>
                                </a>
                                <p className='revlutionPara'>
                                    <span>
                                        <img src={calender} alt="img" className='footImg' />
                                    </span>
                                    February 28, 2024
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='rightSection col-md-4'>
                        <div>
                            <h2 className='centerHeading'>STAY CONNECTED</h2>
                        </div>
                        <div>
                            <p className='revlutionPara1'>To receive regular updates on Biomagnetism Therapy, subscribe to our e-newsletter by entering your email address here.</p>
                        </div>
                        <div>
                            <Formik
                                initialValues={{ email: '' }}
                                validationSchema={subscriberValidationSchema}
                                onSubmit={(values, actions) => { handleSaveSubscriber(values); actions.resetForm(); }}
                                enableReinitialize
                            >
                                {({ values, handleChange, handleBlur }) => (
                                    <Form className="w-100">
                                        <div className="position-relative login-input mb-2">
                                            <InputText
                                                name="email"
                                                placeholder="Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autoComplete="email"
                                                id="footerInp"
                                            />
                                        </div>
                                        <div className="d-flex justify-content-start mt-3">
                                            <button
                                                className='btn btn-outline-primary text-white'
                                                style={{ fontWeight: '600', border: '1px solid white' }}
                                                type="submit"
                                                disabled={loading}
                                            >
                                                {loading ? 'Please wait...' : 'SUBMIT'}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <div className='innerFooCont py-3'>
                <div className='container-fluid px-4'>
                    <div className='innerFooter d-flex justify-content-between'>
                        <div className='poweredBy'>
                            <div className='d-flex align-items-center gap-4' id="inFooter">
                                <div>
                                    <a href="https://helenzys.com/" target='_blank'>
                                        <p className='inFootPara'>Powered by Helenzys</p>
                                    </a>
                                </div>
                                <div className='line'></div>
                                <div>
                                    <a href='/privacy-policy'>
                                        <p className='inFootPara'>Privacy Policy</p>
                                    </a>
                                </div>
                                <div className='line'></div>
                                <div>
                                    <a href='/terms-condition'>
                                        <p className='inFootPara'>Terms & Condition</p>
                                    </a>
                                </div>
                                <div className='line'></div>
                                <div>
                                    <a href="/refund-cancellation">
                                        <p className='inFootPara'>Refund & Cancellation</p>
                                    </a>
                                </div>
                            </div>
                            <div className=''>
                                <p className='footLastPara'>
                                    <span className='mx-1'>
                                        <img src={copyRight} alt='img' />
                                    </span>
                                    2024 Garcia Group LLC. All rights reserved. Video and/or text material may not be reproduced without written permission.</p>
                            </div>
                        </div>
                        {/* <div className='text-end'>
                            <div className='mt-1'>
                                <p className='kmelPara'>Videography and Graphic Design by:
                                    <span className='px-2'>
                                        <img src={kmel} alt="img" />
                                    </span>
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterLayout
