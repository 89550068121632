import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function InternetModal({ showPopup, handleClosePopup }) {
  return (
    <div>
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title font-weight-bold" id="exampleModalLabel">Internet Issue</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please check your network connection and try again.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
