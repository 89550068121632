import React, { useState, useEffect, useCallback } from 'react';
import { Accordion } from 'react-bootstrap';
import './Faq.css';
import { useDispatch, useSelector } from 'react-redux';
import { ActionFaq } from '../../store/actions/actions-server-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Faq() {
    const [activeKey, setActiveKey] = useState("");
    const dispatch = useDispatch();
    const userState = useSelector(state => state);
    const faqData = userState.rServerData?.faq || [];
    const faqLoading = userState.rLoading?.faq;
    const [loading, setLoading] = useState(!faqData);

    const handleAccordionToggle = useCallback((eventKey) => {
        setActiveKey(activeKey === eventKey ? "" : eventKey);
    }, [activeKey]);

    useEffect(() => {
        if (!faqData.length) {
            dispatch(ActionFaq());
        } else {
            setLoading(false);
        }
    }, [dispatch, faqData.length]);

    return (
        <div className='faqContainer'>
            {
                faqLoading ? <div className="">
                    <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
                        <p className='mt-2' >Please wait...</p>
                    </div>
                </div> :
                    <div className='faqInner'>
                        <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
                            {loading ? (
                                <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '350px' }}>
                                    <p style={{ fontWeight: '600', fontSize: '20px' }}>Loading...</p>
                                </div>
                            ) : (
                                faqData.map((item, idx) => (
                                    <div key={idx} style={{ background: 'white' }}>
                                        <div className='pt-3 px-3'>
                                            <h3>{item.faqhead}</h3>
                                        </div>
                                        {item.faqrecord.map((record, index) => (
                                            <Accordion.Item key={record.id} eventKey={`${idx}-${index}`} >
                                                <Accordion.Header onClick={() => setActiveKey(activeKey === `${idx}-${index}` ? "" : `${idx}-${index}`)}>
                                                    <div className='w-100 d-flex justify-content-between accheader px-2'>
                                                        <p>{record.questions}</p>
                                                        <span className="accordion-toggle-icon text-end" onClick={(e) => { e.stopPropagation(); setActiveKey(activeKey === `${idx}-${index}` ? "" : `${idx}-${index}`); }}>
                                                            {activeKey === `${idx}-${index}` ? (
                                                                <span style={{ color: '#48308c' }}>
                                                                    <i className="fas fa-times"></i>
                                                                </span>
                                                            ) : (
                                                                <span style={{ color: '#48308c' }}>
                                                                    <i className="fas fa-plus-circle"></i>
                                                                </span>
                                                            )}
                                                        </span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='faqAnswers'>
                                                        <div className="accordion-content" dangerouslySetInnerHTML={{ __html: record.answers }} style={{ fontWeight: '400', fontSize: 'smaller' }} />
                                                        <div className="accordion-indicator"></div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </div>
                                ))
                            )}
                        </Accordion>
                    </div>
            }
        </div>
    );
}

export default Faq;
