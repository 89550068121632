import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ActionRefundCancellation } from '../../store/actions/actions-server-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function RefundCancel() {
    const [refund, setRefund] = useState(null);
    const dispatch = useDispatch();
    const MyState = useSelector(state => state);
    const refundData = MyState.rServerData.refundCancel != undefined ? MyState.rServerData.refundCancel : null;
    const isLoading = MyState.rLoading ? MyState.rLoading.refundCancel : false; 
    useEffect(() => {
        if(!refundData){
            dispatch(ActionRefundCancellation());
        }
    },[refundData, dispatch]);
    return (
        <div className='p-3'>
            {
                isLoading ? (
                <div className="">
                    <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
                        <p className='mt-2' >Please wait...</p>
                    </div>
                </div> 
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: refundData && refundData.content }} />
                )}
        </div>
    )
}

export default RefundCancel;

