export default {
  LOGIN: "/userLogin",
  REGISTER: '/userRegister',
  UPDATE_PASSWORD: '/userchangePassword',
  UPDATE_USER_ACC: '/userprofileUpdate',
  ForgotPassword: '/userForgotpassword',
  ResetPassword: '/userUpdatepassword',
  GET_USER: '/userAccount',
  HOME: '/Intiallpage',
  COURSEOVERVIEW: '/getCourseOverview',
  ABOUTUS: '/aboutUs',
  HELPVIDEOS:'/helpVideos',
  TERMSCONDITION:'/termsCondition',
  PRIVACYPOLICY:'/privacyPolicy',
  REFUNDCANCELLATION:'/refundCancellation',
  FAQ:'/faq',
  MEDIABLOGS:'/mediaBlogs',
  CONTACTUS:'/contactUs',
  CREATESUBSCRIBER:'/createSubscriber',
  NORMALORDERINVOICE:'/generateInvoice',
  RENWEDORDERINVOICE:'/generateRenewedInvoice',
  GIFTEDORDERINVOICE:'/generateGiftedInvoice', 
  ALLCOURSE:'/allCourses',
  APPLYCOURSE:'/applyCourse',
  USERPROFILE:'/userprofileUpload',
  HANDLEPAY: '/dopay/online',
  UPDATESESSION:'/update-session',
  UPDATESUBSESSION:'/update-sublecture',
  // :'/usersPast7Days    '
  // :'/userPast7DayaPaid    '
  // :'/subscriptionPaidpast7Days50    '
  // :'/subscriptionPaidpast7Days100    '
  // :'/usersPastcurrentMonth    '
  // :'/usersPast30Days    '
  // :'/userPastall    '
  // :'/userPurchasedPastall    '
  // :'/userOnlyRegisterPastall    '
  // :'/allUserCurrentprogress    '

  USERLOGOUT:'/userLogout',
  VALIDSESSION:'/validateUsersession',
  VALIDATEOLDPASSWORD:'/verifyOldPassword',
  // :'/authorizepay    '
  GETFEEDBACK:'/getfeedback',
  POSTFEEDBACK:'/postfeedback',
  
  // :'/verifyCouponCode    '
  MYCOURSE:'/myCourse',
  COURSEDETAIL:'/courseDetail',
  ASSESSMENTDOWNLOADURL: '/getassementDownload',
  DOWNLOADCERTIFICATE:'/downloadCertificate',
  DOWNLOADGUIDEBOOK:'/downloadGuidebook',
  COURSEMATERIAL:'/courseMaterial',
  VERIFYEMAIL:'/verifyEmail',
  // GETCOURSEVIDEO:'/getcourseVideos',
  GETSUBVIDEOS:'/getsubVideos',
  GETCOUNTRIES:'/getCountries',
  GETSTATES:'/getStates',
  POSTQUERY:'/userPosts',
  ASKQUERY:'/askQuery',
  COMMUNITY:'/community',
  COMMUNITYPOSTS:'/communityPosts',
  COMMUNITYPOSTSLIKES:'/userlikesDislikesonPost',
  COMMUNITYPOSTSDETAILS:'/communityPostDetails', 
  COMMUNITYCOMMENTLIKES:'/userlikesDislikesonComment',
  COMMUNITYSUBCOMMENTLIKES:'/userlikesDislikessubComments',
  USERASSESMENT:'/userAssessment',
  GETCOURSEVIDEO:'/course-detail/mainvideo',
  ASSESMENTRESULT: '/userAssessmentresult',
  DOWNLOADASSESMENT: '/downloadAnswersheet',
  RETAKEASSESMENT:'/attemptUpdate',
  NextEASSESMENTattemptUpdate:'/reUpdateattempt',
  SEARCHPOST: '/searchbyPostname',
  ADDCOMMUNITYCOMMENT: '/userCommentonPost',
  ADDCOMMUNITYSUBCOMMENT:'/userCommentonComment',
  VERIFYGIFTEMAIL:'/verifyEmail',
  VERIFYCODE : '/verify_Code',
  UPDATECODE : '/updateOtp',
  GETCOURSEPROGRESS:'/getCourseStatus',
  UPDATECOURSEPROGRESS:'/updatecourseprogress',
  TESTMONIALVIDEO:'/updatetestmonialVideosQue',
  GETORDERDETAILS :'/orderDetails',
  CHECKEMAIL:'/checkEmail',



  // :'/userCommentonPost'
  // :'/userCommentonComment    '

  // :'/searchbyPostname    '
  // :'/userlikesDislikesonComment    '
  // :'/userlikesDislikessubComments    '
  
  // :'/downloadAnswersheet    '
  // :'/attemptUpdate    '
};
