import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CongratulationsImg from "../../Assets/images/winner.jpg";
import { useDispatch, useSelector } from "react-redux";
import { ActionGetCourseCompletionCertificate, ActionGetFeedbackForm } from "../../store/actions/actions-server-data";

export default function CompleteModal({ show, handleClose }) {
  
  const MyState = useSelector((state) => state);
  const dispatch = useDispatch();
  const UserAccessToken = MyState.rServerData.Usertoken ? MyState.rServerData.Usertoken : null;
  const userDetails = MyState.rServerData.userDataLogggedIn || [];
  useEffect(() => {
    dispatch(ActionGetCourseCompletionCertificate({ userId: userDetails.id, token: UserAccessToken }));
  },[])


  const Certifcate = MyState.rServerData.completionCertificate ? MyState.rServerData.completionCertificate : null;
  
  const handleDownloadCertificate = async () => {
    try {
        if(Certifcate.success == true){
          var Url = Certifcate && Certifcate.certificate_url || '';
          const response = await fetch(Url);
          const blob = await response.blob()
          const url = window.URL.createObjectURL(blob);
  
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'BioMagnetism-and-Beyond-Certificate.pdf');
          link.style.display = 'none';
          document.body.appendChild(link);
  
          link.click();
  
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }else{
          alert(Certifcate.message)
        }
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

const handleGetFeedback = () => {
    dispatch(ActionGetFeedbackForm())
}

  return (
    <div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Title className="text-center">
            <h2 className="text-bold pt-2" style={{textTransform:'uppercase'}}>Congratulations</h2>
          </Modal.Title>
          <div>
            <div className="modal-body">
              <img
                className="w-100"
                src={CongratulationsImg}
                alt="Congratulations"
              />
            </div>
            <div className="pb-2 text-center">
              <span
                id="printmycertificate"
                style={{ textDecoration: "underline", color: "#007bff", borderRight:'1px solid blue', cursor:'pointer' }}
                onClick={handleDownloadCertificate}
                className="mx-1 px-1"
              >
                Download my certificate
              </span>

              <a className="mx-1 px-1" style={{ textDecoration: "underline", color:'black', borderRight:'1px solid black', cursor:'pointer'  }} onClick={handleGetFeedback}>Share your feedback</a>
             
              <a id="goback" href="/myCoursePage">
                Go Back
              </a>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}