import React, { useEffect, useState } from 'react';
import './AboutUs.css';
import about_banner from '../../Assets/images/pages/about-us/who-we-are.png';
import talk_bubble_image from '../../Assets/images/pages/about-us/tmp-625cec9ddc376.png';
import certificateimage from '../../Assets/images/pages/about-us/certificate.png';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAboutUs } from '../../store/actions/actions-user';

function AboutUs() {
    const [aboutUsData, setAboutUsData] = useState(null);
    const [sucess, setSuccess] = useState(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const aboutUsDataFromState = useSelector(state => state.router.location.state);
    const MyState = useSelector((state) => state);
    const UserAccessToken = MyState.rServerData.Usertoken ? MyState.rServerData.Usertoken : null;
    useEffect(() => {
        if (!aboutUsDataFromState) {
            dispatch(ActionAboutUs());
        } else {
            setAboutUsData(aboutUsDataFromState.content);
            setSuccess(aboutUsDataFromState.successcontent[1]);
            setLoading(false);
        }
    }, [aboutUsDataFromState, dispatch]);

    return (
        <div className='aboutContainer'>
            <div className='aboutBanner'>
                <div className='row'>
                    {loading ? (
                        <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '350px' }}>
                            <p style={{ fontWeight: '600', fontSize: '20px', color: 'white' }}>Loading...</p>
                        </div>
                    ) : (
                        <>
                            <h2 className="text-left aboutus-whoweare-title about_head py-5">WHO WE ARE</h2>
                            <div className='col-md-6'>
                                <h2 className='about_Title mb-4'>{aboutUsData.whowearetitle}</h2>
                                <p className='about_description mb-2'>{aboutUsData.whowearedescription}</p>
                                {UserAccessToken ? <a href="allCourse"><button className='mt-5 regCourse'>Register for the course</button></a> : <a href="login"><button className='mt-5 regCourse'>Register for the course</button></a>}
                            </div>
                            <div className='col-md-6'>
                                <img src={about_banner} alt="Who We Are" className='whowerImg' />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="aboutStudent">
                <div className="col-md-12 p-5 mt-4 float-left">
                    <h2 className="text-left studentHead">What students say about our seminars</h2>
                </div>
                <div className="row">
                    <div className="col-md-4 text-center">
                        <div className="talk-bubble tri-right btm-right">
                            <div className="talktext">
                                <p><strong>I loved it all. Fantastic</strong>. <br />Thank you so very much for sharing this knowledge with me. It was absolutely amazing.</p>
                            </div>
                        </div>
                        <div className="talk-bubble tri-right btm-right">
                            <div className="talktext">
                                <p><strong>Dr Luis F Garcia is a natural teacher.</strong></p>
                            </div>
                        </div>
                        <div className="talk-bubble tri-right btm-right">
                            <div className="talktext">
                                <p><strong>I loved learning from Dr Luis F Garcia!</strong>. <br />All the fabulous resources and wealth of knowledge openly shared.</p>
                            </div>
                        </div>
                        <div className="talk-bubble tri-right btm-right">
                            <div className="talktext">
                                <p><strong>The seminar was amazing, enlightening</strong> <br />Feeling so blessed to have gained this knowledge! and beyond excited to put it to good use.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 text-center">
                        <img src={talk_bubble_image} alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-4 text-center">
                        <div className="talk-bubble tri-right left-top">
                            <div className="talktext">
                                <p><strong>Fantastic, professional, well-organized, informative</strong> <br />Best conference/training I have ever been to.</p>
                            </div>
                        </div>
                        <div className="talk-bubble tri-right left-top">
                            <div className="talktext">
                                <p><strong>Dr Luis F Garcia's command of his medical knowledge-</strong> <br />Dr Luis F Garcia has such a command of medical knowledge. It was so helpful in explaining Biomagnetism!</p>
                            </div>
                        </div>
                        <div className="talk-bubble tri-right left-top">
                            <div className="talktext">
                                <p><strong>It was an eye-opening experience!</strong> <br />I enjoyed each day and I’m grateful for having gained exposure to this information.</p>
                            </div>
                        </div>
                        <div className="talk-bubble tri-right left-top">
                            <div className="talktext">
                                <p><strong>Life-changing Exceeded all expectations.</strong><br />The Best seminar ever!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='certificate'>
                <div className='shadow'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-6'>
                                {aboutUsData && (
                                    <>
                                        <h2 className='certificationHead py-3 mb-5'>Our <span>Certification</span></h2>
                                        <p className='certificateDescrp py-4'>{aboutUsData.certificatedescription}</p>
                                    </>
                                )}
                            </div>
                            <div className='col-md-6 text-center'>
                                {aboutUsData && (
                                    <img src={certificateimage} alt='Certificate' className='img-fluid' />
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section float-left w-100 h-100 join-the-mission-section">
                <div>
                    <h2 className="uppercase ritured color-white font-weight-bold">JOIN THE MISSION</h2>
                </div>
                <div>
                    <h2 className="uppercase ritured color-white font-weight-bold text-center px-1">To take biomagnetism to the world</h2>
                </div>
            </div>

            <div className="section float-left result-section">
                <div className=''>
                    <div className="float-left" style={{ color: 'black' }}>
                        {
                            sucess && (
                                <div className=''>
                                    <h2 className="text-center w-100 my-5 result-heading">
                                        {sucess.section_title.split(' ').map((word, index) => {
                                            if (index === 0) {
                                                // First word "About" is black
                                                return <span key={index} className="black-text">{word} </span>;
                                            } else {
                                                // Rest of the words "Dr. Luis F. Garcia" are blue
                                                return <span key={index} className="blue-text">{word} </span>;
                                            }
                                        })}
                                    </h2>
                                    <div className="container float-left w-100 mb-5 pl-5">
                                        <div className="small result-description">
                                            {sucess.description}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='knowMore'>
                                            <h2>{sucess.title}</h2>
                                            <a href={sucess.link} className='btn btn-light d-flex align-items-center justify-content-center' target='_blank'>
                                                <span className='' style={{color:'black'}}>{sucess.button_text}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
