import React, { useEffect } from 'react';
import thankyou from '../../Assets/images/thankyou.png';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAssessmentDownload, ActionCourseModule, ActionDownloadCourseMaterial, ActionGetCommunity, ActiongetIntiallcourseDetails } from '../../store/actions/actions-server-data';
// import { useSelector } from 'react-redux';

function PaymentComplete() {
    const MyState = useSelector(state => state);
    const TransactionInfo = MyState.rServerData.paymentComplete ? MyState.rServerData.paymentComplete : [];
    const userAccessToken = MyState.rServerData.Usertoken || null;
    const userDetails = MyState.rServerData.userDataLogggedIn || [];

    const dispatch = useDispatch();

    useEffect(() => {
        if (TransactionInfo) {
            var Response = TransactionInfo?.res?.data?.details;
            dispatch(ActiongetIntiallcourseDetails(Response));
        }
        if (Response) {
            dispatch(ActionCourseModule({ token: userAccessToken, id: Response.orders[0].id, orderid: Response.orders[0].orderid }));
            dispatch(ActionAssessmentDownload({ token: userAccessToken, orderid: Response.orders[0].orderid, userId: userDetails.id }));
        }
        const param = { token: userAccessToken };
        dispatch(ActionDownloadCourseMaterial(param));
        dispatch(ActionGetCommunity(param));

    }, []);

    const handlenavigateCoursePage = () => {
        window.location.href = '/myCoursePage'
    }
    return (
        <div>
            <div className='p-5 d-flex align-items-center justify-content-center my-3' >
                <div className='' style={{ width: '400px' }}>
                    <img src={thankyou} alt='img' width='400' style={{ borderRadius: '20px' }} />
                    {TransactionInfo?.params?.type == "gifting" ?
                        <p className='py-3 text-center'> Congratulations on successfully placing your Order.<br />
                            The Order details will be sent to your friend <span style={{textTransform: 'capitalize', color:'black'}}>{TransactionInfo?.params?.gifting_name}</span> on {TransactionInfo?.params?.gifting_date} day!!
                            <button type='button' style={{ border: 'none', background: 'transparent', color: 'blue', textDecoration: 'underline', }} onClick={handlenavigateCoursePage}>Course</button>
                        </p> :
                        <p className='py-3 text-center'>Congratulations on successfully
                            {TransactionInfo?.params?.type == "Subscription" ? " renewing " : " placing "}  
                            your Order. Your Transaction ID is {TransactionInfo?.res?.data?.transid }  You can start watching the
                            <button type='button' style={{ border: 'none', background: 'transparent', color: 'blue', textDecoration: 'underline', }} onClick={handlenavigateCoursePage}>Course</button>
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}

export default PaymentComplete
