import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-bootstrap/Pagination';
import { useHistory } from 'react-router';
import { ActionPostCommunityDetails } from '../../store/actions/actions-server-data';

function CommunitySearch() {
    const [showReplies, setShowReplies] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
    const userState = useSelector(state => state)
    const UserAccessToken = userState.rServerData ? userState.rServerData.Usertoken : null;
    const posts = userState.rServerData.searchPost != undefined ? userState.rServerData.searchPost.posts : "";
    const isCommunityPostLoading = userState.rLoading ? userState.rLoading?.communityPost : ''; 
    const toggleReplies = () => {
        setShowReplies(!showReplies);
    };
    const history = useHistory();
    const dispatch = useDispatch()
    useEffect(() => {
        setCurrentPage(1);
    }, [posts]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts && posts.slice(indexOfFirstPost, indexOfLastPost);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear().toString().slice(-2);
        return `${day} ${month} ${year}`;
    };

    const handleNavigateToPost = async (item) => {
        const { category_id, topic_id, id } = item;
        const obj = {
            token: UserAccessToken,
            postId: id,
            topicid: topic_id,
            categoryid: category_id
        }
        await dispatch(ActionPostCommunityDetails(obj));
        
        if(isCommunityPostLoading){
            setTimeout(() => {
                const route = `/community/thread/${category_id}/${topic_id}/${id}`;
                history.push(route);                
            }, 2500);    
        }
    }
    return (
        <div className='p-5'>
            <div className='container-fluid'>
                <div>
                    {currentPosts && currentPosts.length > 0 ? (
                        currentPosts.map((item, idx) => {
                            return (
                                <div className='row w-100' key={item.id}>
                                    <div className='col-md-1'>
                                        <div className='response'>
                                            <span>{item.comments ? item.comments.length : 0}</span>
                                            <span>Response</span>
                                        </div>
                                    </div>
                                    <div className='col-md-9'>
                                        <span className='postTitle' onClick={() => handleNavigateToPost(item)}>{item.post_name}</span>
                                        <div className='repliesContainer'>
                                            <div>
                                                <i className="fa-solid fa-thumbs-up px-1">
                                                    <span className='px-1' style={{ color: 'black' }}>{item.up_vote}</span>
                                                </i>
                                                <i className="fa-solid fa-thumbs-down px-1">
                                                    <span className='px-1' style={{ color: 'black' }}>{item.down_vote}</span>
                                                </i>
                                            </div>
                                            <div>
                                                {item.comments && item.comments.length > 0 ? (
                                                    !showReplies ? (
                                                        <div className='viewReplies' onClick={toggleReplies}>
                                                            <i className="fa fa-chevron-down" style={{ marginLeft: '5px' }}></i> View {item.comments.length} reply
                                                        </div>
                                                    ) : (
                                                        <div className='hideReplies' onClick={toggleReplies}>
                                                            <i className="fa fa-chevron-up" style={{ marginLeft: '5px' }}></i> Hide replies
                                                        </div>
                                                    )
                                                ) : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='postRightSide'>
                                            <div style={{ width: '70px', height: '50px', borderRadius: '50%', background: 'slategrey' }}>
                                                <img src={item.user.profile_image} width='100%' height='100%' style={{ borderRadius: '50%' }} />
                                            </div>
                                            <div>
                                                <p>On {formatDate(item.created_at)} from {item.user.firstname}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {showReplies && item.comments.length > 0 && (
                                        <div className="col-md-12" style={{ paddingLeft: "100px", paddingTop: "20px" }}>
                                            {item.comments.map(comment => (
                                                <div key={comment.id}>
                                                    <div className='commentDiv mb-3'>
                                                        <div style={{ width: '40px', height: '40px', borderRadius: '50%', background: 'slategrey' }}>
                                                            <img src={comment.user.profile_image} width='100%' height='100%' style={{ borderRadius: '50%' }} />
                                                            <p>{comment.user.firstname}</p>
                                                        </div>
                                                        <div>
                                                            <p>{comment.comment}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <hr className='mt-3' />
                                </div>
                            )
                        })

                    ) : (<div className='text-center py-5'>
                        <p style={{ fontSize: '20px', fontWeight: '600' }}>No posts available.</p>
                    </div>)}
                </div>

            </div>
            <div style={{ display: 'flex', width: '100%', alignItems: 'end', justifyContent: 'end' }}>
                { posts &&  <Pagination>
                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                    {Array.from({ length: Math.ceil(posts.length / postsPerPage) }, (_, i) => (
                        <Pagination.Item key={i + 1} active={currentPage === i + 1} onClick={() => paginate(i + 1)}>
                            {i + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(posts.length / postsPerPage)} />
                </Pagination> }
            </div>
        </div>
    )
}

export default CommunitySearch
