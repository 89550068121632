import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Community.css';
import communityPic from '../../Assets/images/communityInamge.png';
import { ActionPostCommunity, ActionGetCommunity, ActionSearchPost, ActionPostQuery } from '../../store/actions/actions-server-data';
import PostQuestionModal from './PostQuestionModal';
import { useNavigate } from 'react-router-dom';
import { ActionRouteNavigate } from '../../store/actions/actions-route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingOverlay = () => (
    <div className="payment_overlay">
        <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
            <p className='mt-2 payment_loading'>Please wait...</p>
        </div>
    </div>
);

function Community() {
    const [searchPost, setSearchPost] = useState('');
    const [postCard, setPostCard] = useState([]);
    const [postCategory, setPostCategory] = useState([]);
    const [count, setCount] = useState([]);
    const [loading, setLoading] = useState(true);
    const userState = useSelector(state => state);
    const isLoadingCommunity = userState.rLoading?.community || false;
    const isLoadingPostQuery = userState.rLoading?.postQuery || false;
    const isLoadingSearchPost = userState.rLoading?.searchPost || false;
    const isLoadinghandleCard = userState.rLoading?.communityDetail || false;
    const communityData = userState.rServerData ? userState.rServerData.community : [];
    const userData = userState.rServerData ? userState.rServerData.Usertoken : null;
    const userId = userState.rSession.data.user;
    const dispatch = useDispatch();
    const topicCountsMap = {};
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoadingCommunity && communityData) {
            fetchData();
        } else {
            const param = { token: userData };
            dispatch(ActionGetCommunity(param));
        }
    }, [communityData]);

    useEffect(() => {
        const param = { token: userData };
        dispatch(ActionGetCommunity(param));
    }, [])

    const fetchData = () => {
        setCount(communityData.PostCount);
        setPostCard(communityData.post_topics);
        setPostCategory(communityData.post_category);
        setLoading(false);
    };



    const handleSearch = (searchValue) => {
        if (!searchValue.trim()) {
            alert('Search field is empty');
            return;
        }
        const obj = {
            token: userData,
            searchPost: searchValue
        };
        dispatch(ActionSearchPost(obj));
    };

    const handleSubmit1 = async (postDescription, setError, selectedTopic) => {
        const trimmedDescription = postDescription.trim();
        if (!trimmedDescription) {
            setError('Fill the question.');
            return;
        }
        const obj = {
            token: userData,
            userId: userId.id,
            topic: selectedTopic,
            post_description: postDescription
        };

        try {
            await dispatch(ActionPostQuery(obj));
            handleClose();
            fetchData();
        } catch (error) {
            console.error('Error posting query:', error);
        }
    };


    count.forEach(item => {
        topicCountsMap[item.topic_id] = item.count;
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const formatDate = (dateString) => {
        return dateString?.substring(0, 10);
    };

    const handleCardId = (e, category, topic) => {
        e.preventDefault();
        const param = {
            categoryid: category.id,
            topicid: topic.id,
            token: userData
        };
        dispatch(ActionPostCommunity(param));
        navigate(`/community/topics/${category.id}/${topic.id}`);
    };
    const loader = isLoadingCommunity || isLoadingPostQuery || isLoadingSearchPost || isLoadinghandleCard;
    return (
        <div className='p-4 communityContainer'>
            {loader && <LoadingOverlay />}
            <div>
                <div className='communityFormDiv'>
                    <form onSubmit={(e) => e.preventDefault()} style={{ width: '100%' }}>
                        <div className='inpMagnifier'>
                            <input type='text' className='communityInp'
                                onChange={(e) => setSearchPost(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch(searchPost);
                                    }
                                }}
                                placeholder='Search by Post...' />
                            <i className="fas fa-search" style={{ cursor: 'pointer' }} onClick={() => handleSearch(searchPost)}></i>
                        </div>
                    </form>
                    <button className='communityBtn' onClick={handleShow}>Post a question</button>
                </div>
            </div>
            {show && <PostQuestionModal show={show} handleClose={handleClose} onSubmit1={handleSubmit1} postCard={postCard} title="POST A QUESTION" placeholder="Start your question with 'What', 'How', 'Why', etc." cancel="Cancel" post="Post" />}
            <div>
                {!loading && postCategory.map(category => (
                    <div key={category.id}>
                        <h2 className='py-3' style={{ fontWeight: '700' }}>{category.name}</h2>
                        <div className='cardOuter'>
                            {postCard
                                .filter(topic => topic.category_id === category.id)
                                .map(topic => (
                                    <div key={topic.id} className='card p-3' id="communityCard" style={{ width: '22rem', cursor: 'pointer' }}
                                        onClick={(e) => handleCardId(e, category, topic)}
                                    >
                                        <div className='d-flex gap-4'>
                                            <img src={communityPic} alt="img" />
                                            <p className='topics'>{topic.name}</p>
                                        </div>
                                        <hr />
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <b>{topicCountsMap[topic.id] || 0}</b><br />
                                                <p className='p-0'>Posts</p>
                                            </div>
                                            <div>
                                                <b>{formatDate(topic.posts_updated_at)}</b>
                                                <p>Last Updated</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Community;
