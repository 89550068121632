import React, { useEffect, useState } from 'react';
import './ProgressComponent.css'; 

const ProgressComponent = ({data}) => {
    const [currentValue, setCurrentValue] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const animateProgress = () => {
            const bars = document.querySelectorAll(".progress");
            bars.forEach(bar => {
                const bar1 = bar.querySelector(".bar1");
                const barElement = bar.querySelector(".bar");
                const val = bar.querySelector("span");
                const currentvalue = document.querySelector(".markpercentage").textContent;
                const perc = parseInt(val.textContent, 10);

                let p = 0;
                const interval = setInterval(() => {
                    if (p === perc) clearInterval(interval);
                    barElement.style.transform = `rotate(${45 + p * 1.8}deg)`;
                    let value = 1.3;
                    if (currentvalue > 60) value = 1.1;
                    bar1.style.transform = `rotate(${parseFloat(value) + p * 1.85}deg)`;
                    val.textContent = `${p}%`;
                    p++;
                }, 30); 
            });
        };

        animateProgress();
    }, [data]);


    const getBorderStyle = () => {
        switch (data.evaluation.graphStrength) {
            case 'Poor':
                return { borderBottomColor: '#ff0000', borderRightColor: '#ff0000' };
            case 'Average':
                return { borderBottomColor: '#ff9900', borderRightColor: '#ff9900' };
            case 'Good':
                return { borderBottomColor: '#bbe604', borderRightColor: '#bbe604' };
            case 'Excellent':
                return { borderBottomColor: '#00ff4c', borderRightColor: '#00ff4c' };
            default:
                return {};
        }
    };

    return (
        <div className="progressBar m-0">
            <div className=" graph-left">
                <span className="resultstage">0%</span>
                <span className="resultstage1">25%</span>
                <span className="resultstage2">50%</span>
                <span className="resultstage3">75%</span>
                <span className="resultstage4">100%</span>
                <div className="progress">
                    <div className="barOverflow">
                        <div
                            className="bar"
                            style={getBorderStyle()}
                        ></div>
                    </div>
                    <div class="bar1" style={{ transform: "rotate(1.3deg)" }}><i class="fa fa-caret-left" style={{ color: "white", position: "relative", right: "5rem", top: "3rem", fontSize: "5rem",transform: "rotate(-4deg)"}} aria-hidden="true" ></i>
                    </div>
                    <div className="divcontain">
                        <span className="markpercentage" style={{ color: 'black' }}>{Math.round(data.evaluation.new_width)}</span>
                        <p
                            className="markpercentage3"
                            style={{
                                color:
                                    data.evaluation.graphStrength === 'Poor'
                                        ? '#ff0000'
                                        : data.evaluation.graphStrength === 'Average'
                                            ? '#ff9900'
                                            : data.evaluation.graphStrength === 'Good'
                                                ? '#bbe604'
                                                : data.evaluation.graphStrength === 'Excellent'
                                                    ? '#00ff4c'
                                                    : ''
                            }}
                        >
                            {data.evaluation.graphStrength}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressComponent;
