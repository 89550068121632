import React from "react";
import "./MediaBlog.css"

const BlogCard = ({ blog }) => {
  return (
    <div className="container-fluid px-5 col-12 w-100">
      <div className="row">
        {blog.map((postarticle) => {
          const blogImage =
            postarticle._embedded["wp:featuredmedia"][0].media_details.sizes
              .medium.source_url;
          const blogTitle = postarticle.title.rendered;
          const blogDate = new Date(postarticle.date).toLocaleDateString(
            "en-US",
            { month: "short", day: "2-digit", year: "numeric" }
          );
          const blogId = postarticle.id;
          const blogLink = postarticle
            ? postarticle.link
            : "javascript:void(0)";

          return (
            <div className="col-md-4" id="BlogCard"  key={blogId}>
              <div className=" card mb-4 mr-3" id="blogCard" style={{ maxWidth: "none" }}>
                <div className="blog-picture">
                  <img id="blogImg" src={blogImage} width={"100%"} height={"250px"} alt="" />
                </div>
                <div className="card-body p-3">
                  <h4
                    className="h5 card-title font-weight-bolder "
                    style={{ height: "56px" }}
                  >
                    {blogTitle}
                  </h4>
                  <div className="d-flex justify-content-between align-items-center fw-bolder">
                    <div className="btn-group">
                      <span className="h6 card-title">{blogDate}</span>
                    </div>
                    <a
                      href={blogLink}
                      className="btn btn-sm btn-outline-secondary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                    <small className="text-muted d-none">0 mins</small>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlogCard;
