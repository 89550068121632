import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './AssesmentPage.css';
import { useHistory } from 'react-router';
import { ActionCheckvalidSession, ActiongetCourseProgressofCurrentOrder, ActionUpdateCourseProgressCurrentOrder, ActionUpdateCourseSession, ActionUpdateNextAssesmentAttemptstatus } from '../../store/actions/actions-server-data';
import { useDispatch, useSelector } from 'react-redux';
import { ActionRouteNavigate } from '../../store/actions/actions-route';


function AssesmentModal({ show, handleClose, userToken, setStartTime, setTimeRemaining, setSelectedAnswers, sessionId, LectureId, userId, orderId, firstname, testimonialId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userStore = useSelector((state) => state);
  // const updatesessionLoader = userStore.rLoading?.updateCourseProgress; 
  // const updateLoader = userStore.rLoading?.updatesession;
  // const nextassessmentLoader = userStore.rLoading?.nextassessmentstatusattempt; 
  // const validsessionLoader = userStore.rLoading?.validsession;
  // const getCourseProgressLoader = userStore.rLoading?.getCourseProgress;

  const updatesessionLoader = userStore.rLoading?.updateCourseProgress ?? false;
  const updateLoader = userStore.rLoading?.updatesession ?? false;
  const nextassessmentLoader = userStore.rLoading?.nextassessmentstatusattempt ?? false;
  const validsessionLoader = userStore.rLoading?.validsession ?? false;
  const getCourseProgressLoader = userStore.rLoading?.getCourseProgress ?? false;
  const [isLoading, setIsLoading] = useState(false);

useEffect(() => {
  dispatch(ActiongetCourseProgressofCurrentOrder({ userId: userId, token: userToken }));
},[])

const getCourseProgressData = userStore.rServerData.getCourseProgress;

  const handleStartQuiz = () => {
    setStartTime(true)
    setTimeRemaining(1200)
    setSelectedAnswers({})
    handleClose()
  }

  const handleGotoPreviousSessions = () => {
    console.log('testimonialId', typeof (testimonialId), testimonialId);
    if (testimonialId == "null" || testimonialId == null) {
      history.push({ pathname: '/watchVideos', state: { id: sessionId, SessionId: sessionId, LectureId: LectureId } });
    } else {
      history.push({ pathname: '/watchVideos', state: { id: sessionId, SessionId: sessionId, LectureId: LectureId, testMonialId: testimonialId } });
    }
  }

  // const handleUpdateNextSession = async () => {
  //   try {
  //     setIsLoading(true);

  //     const performApiCalls = async () => {
  //       if (getCourseProgressData !== undefined && LectureId >= getCourseProgressData.lectureId) {
  //         debugger
  //         await dispatch(ActionUpdateCourseProgressCurrentOrder({ userId: userId, cour_status: 'inprogress', sessionId: sessionId ? sessionId : getCourseProgressData.sessionId, lectureId: LectureId ? LectureId : getCourseProgressData.lectureId, assessmentlecture: LectureId, token: userToken }));
  //       }

  //       dispatch(ActionCheckvalidSession()); debugger
  //       dispatch(ActionUpdateNextAssesmentAttemptstatus({ token: userToken, userId: userId }));
  //       dispatch(ActionUpdateCourseSession({ userId: userId, courseId: 10, sessionId: sessionId, lectureId: parseInt(LectureId, 10) + 1, orderId: orderId, token: userToken, testimonialid: null }));
  //       dispatch(ActiongetCourseProgressofCurrentOrder({ userId: userId, token: userToken }));
  //     };

  //     // Execute all API calls
  //     await performApiCalls();

  //     // Wait for all loaders to be false
  //     await new Promise(resolve => {
  //       const checkLoaders = () => {
  //         const allLoadersFalse =
  //           !updatesessionLoader &&
  //           !updateLoader &&
  //           !nextassessmentLoader &&
  //           !validsessionLoader &&
  //           !getCourseProgressLoader;

  //         if (allLoadersFalse) {
  //           resolve();
  //         } else {
  //           setTimeout(checkLoaders, 100); // Check again after 100ms
  //         }
  //       };
  //       checkLoaders();
  //     });

  //     setIsLoading(false);

  //     // Navigate
  //     history.push({
  //       pathname: '/watchVideos',
  //       state: {
  //         id: sessionId,
  //         fromAssessment: true,
  //         SessionId: sessionId,
  //         LectureId: parseInt(LectureId, 10) + 1
  //       }
  //     });
  //     console.log('All loaders are false, navigation complete');

  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //     setIsLoading(false);
  //   }
  // };

  const handleUpdateNextSession = async () => {
    try {
      setIsLoading(true);
  
      const performApiCalls = async () => {
        if (getCourseProgressData !== undefined && LectureId >= getCourseProgressData.lectureId) {
          await dispatch(ActionUpdateCourseProgressCurrentOrder({ userId: userId, cour_status: 'inprogress', sessionId: sessionId ? sessionId : getCourseProgressData.sessionId, lectureId: LectureId ? LectureId : getCourseProgressData.lectureId, assessmentlecture: LectureId, token: userToken }));
        }
  
        dispatch(ActionCheckvalidSession()); 
        dispatch(ActionUpdateNextAssesmentAttemptstatus({ token: userToken, userId: userId }));
        dispatch(ActionUpdateCourseSession({ userId: userId, courseId: 10, sessionId: sessionId, lectureId: parseInt(LectureId, 10) + 1, orderId: orderId, token: userToken, testimonialid: null }));
        dispatch(ActiongetCourseProgressofCurrentOrder({ userId: userId, token: userToken }));
      };
  
      // Execute all API calls
      await performApiCalls();
  
      // Wait for all loaders to be false
      await new Promise(resolve => {
        const checkLoaders = () => {
          const allLoadersFalse =
            !updatesessionLoader &&
            !updateLoader &&
            !nextassessmentLoader &&
            !validsessionLoader &&
            !getCourseProgressLoader;
  
          if (allLoadersFalse) {
            resolve();
          } else {
            setTimeout(checkLoaders, 100); // Check again after 100ms
          }
        };
        checkLoaders();
      });
  
      setIsLoading(false);
  
      // Wait for 2 to 3 seconds before navigating
      setTimeout(() => {
          history.push({
            pathname: '/watchVideos',
            state: {
              id: sessionId,
              fromAssessment: true,
              SessionId: sessionId,
              LectureId: parseInt(LectureId, 10) + 1
            }
          });
      }, 2500);
  
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  };
  
  return (
    <div>
      <Modal show={show} onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title style={{ textTransform: 'capitalize' }}>{firstname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>To help solidify your understanding of the material in this unit, please take the following quiz. Note that you will have a maximum of 20 minutes to complete the quiz.</p>
            <p>Note : If you wish to review the session and lecture again, click on 'Watch Again.' If you feel confident in your understanding, proceed with 'Start Quiz.'</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='watchAgain' type='button' onClick={() => handleGotoPreviousSessions()}>Watch Again</button>
          <button className='startQuiz' type='button' onClick={() => handleStartQuiz()}>Start the Quiz</button>
          <button className='startQuiz d-flex gap-3' type='button' onClick={handleUpdateNextSession}>
            Skip the Quiz {updatesessionLoader && <span><i className="fa fa-spinner fa-spin"></i></span>}</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AssesmentModal
