import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default function LegalModel({show,handleClose}) {
    return (
        <div>
            <div>       
                <Modal show={show}  animation={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                    keyboard={false}
                    backdrop="static"
                >
                    <Modal.Header >
                        <Modal.Title> <h5 class="modal-title font-weight-bold" id="exampleModalLabel">Legal Disclaimer </h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="modal-body">
                            <div class="">
                                <p>The purpose of this online course is to be a reference guide for advanced healthcare through the
                                    use of magnets. This information is not intended to treat, prevent, mitigate, or cure any
                                    medical or psychological condition, to support or sustain human life, or to prevent impairment
                                    of human health. It is for self-education purposes. The use of medical terms of art is intended
                                    for practitioner convenience.</p>
                                <b>©2024 Garcia Group LLC. All rights reserved. Video and/or text material may not be reproduced
                                    without written permission.</b>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button data-dismiss="modal" aria-label="Close" type="button" class="btn btn-primary" onClick={handleClose}>
                            I have read and accept
                        </Button>
                      
                    </Modal.Footer>
                </Modal>
            </div >
        </div>
    )
}