import React, { useEffect, useState } from 'react';
import './AssesmentResult.css';
import { useDispatch, useSelector } from 'react-redux';
import correct from '../../Assets/images/assessment1.PNG';
import inCorrect from '../../Assets/images/assessment2.PNG';
import orange from "../../Assets/images/assessmentavaerage.PNG";
import lightgreen from "../../Assets/images/assessmentgood.PNG";
import { ActionCheckvalidSession, ActionDownloadAssessment, ActionRetakeAssessment, ActionUpdateCourseProgressCurrentOrder, ActionUpdateCourseSession, ActionUpdateNextAssesmentAttemptstatus, ActiongetCourseVideos } from '../../store/actions/actions-server-data';
import ProgressComponent from './ProgressComponent ';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function AssesmentResult() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [courseCard, setCourseCard] = useState(null);
    const [renewed, setRenewed] = useState('');
    const [orderDetail, setOrderDetail] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userStore = useSelector((state) => state);

    var testimonialId = userStore.router?.location ? userStore.router?.location?.state?.params?.testimonialId : null;

    const userId = userStore.rServerData?.userDataLogggedIn;
    const userAccessToken = userStore.rServerData.Usertoken;
    const userRouteData = userStore.rServerData.assesmentResult ? userStore.rServerData.assesmentResult.quizResouce : '';
    const userAnswers = userStore.rServerData.assesmentResult ? userStore.rServerData.assesmentResult.quizResouce.allQuestions : '';
    const userChoices = userStore.rServerData.userChoices ? userStore.rServerData.userChoices.answers_list : '';
    const updateAssesment = userStore.rServerData.assesmentResult ? userStore.rServerData.assesmentResult.quizResouce.orderDetails : '';
    const userData = userStore.rServerData.useraccount ? userStore.rServerData.useraccount.userDetails : [];
    const ResponseResult = userStore.rServerData?.assesmentResult;
    const { orders } = userStore.rServerData.IntiallcourseDetails;
    const retakeAssessmentLoader = userStore.rLoading?.retakeAssessment;
    const getCourseProgress = userStore.rServerData.getCourseProgress;
    const downloadAssessmentUrl = userStore.rServerData.downloadAssessment ? userStore.rServerData.downloadAssessment : [];
    window.scrollTo(0, 0)

    const updatesessionLoader = userStore.rLoading?.updateCourseProgress ?? false;
    const validsessionLoader = userStore.rLoading?.validsession ?? false;

    useEffect(() => {
        const obj = {
            token: userAccessToken,
            sessionid: ResponseResult.sessionId,
            lectureid: ResponseResult.lectureId
        }
        dispatch(ActionDownloadAssessment(obj));
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userStore.rServerData.IntiallcourseDetails) {

                    const { coursedetails, orders, Renewed } = userStore.rServerData.IntiallcourseDetails;
                    setCourseCard(coursedetails);
                    setOrderDetail(orders);
                    setRenewed(Renewed);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [dispatch]);

    const handledownloadAnswersheet = () => {
        if (downloadAssessmentUrl && downloadAssessmentUrl.answer_pdf) {
            fetch(downloadAssessmentUrl.answer_pdf)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(blob);
                    link.href = url;
                    link.download = `Session - ${ResponseResult.sessionId} | Lecture - ${ResponseResult.lectureId} | Assessment - Answer Sheet.pdf`;
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                })
                .catch(error => console.error('Error downloading the file:', error));
        }
    }

    const handleRetakeQuiz = async () => {
        const param = {
            token: userAccessToken,
            userId: userData.id,
            sessionid: ResponseResult.sessionId,
            lectureid: ResponseResult.lectureId,
        }
        await dispatch(ActionRetakeAssessment(param))
        await history.push({ pathname: '/assesmentPage', state: { SessionId: ResponseResult.sessionId, LectureId: ResponseResult.lectureId, TestimonialId: testimonialId } });

    }

    const handleUpdateNextSession = async () => {
        try {
            setIsLoading(true);

            const performApiCalls = async () => {
                if (getCourseProgress !== undefined) {
                    if (ResponseResult.lectureId >= getCourseProgress.lectureId) {
                        const req = {
                            userId: userData.id,
                            cour_status: 'inprogress',
                            sessionId: ResponseResult.sessionId ? ResponseResult.sessionId : getCourseProgress.sessionId,
                            lectureId: ResponseResult.lectureId ? ResponseResult.lectureId : getCourseProgress.lectureId,
                            assessmentlecture: ResponseResult.lectureId,
                            token: userAccessToken
                        };
                        await dispatch(ActionUpdateCourseProgressCurrentOrder(req));
                    }
                }
                var param = {
                    userId: userData.id,
                    courseId: 10,
                    sessionId: ResponseResult.sessionId,
                    lectureId: ResponseResult.lectureId,
                    orderId: orders.length > 0 ? orders[0].orderid : null,
                    token: userAccessToken,
                    testimonialid: null
                };
                await dispatch(ActionCheckvalidSession());
                await dispatch(ActionUpdateNextAssesmentAttemptstatus({ token: userAccessToken, userId: userData.id }))
                await dispatch(ActionUpdateCourseSession(param));
            }
            await performApiCalls();

            await new Promise(resolve => {
                const checkLoaders = () => {
                    const allLoadersFalse =
                        !updatesessionLoader &&
                        !validsessionLoader;

                    if (allLoadersFalse) {
                        resolve();
                    } else {
                        setTimeout(checkLoaders, 100); // Check again after 100ms
                    }
                };
                checkLoaders();
            });
            setIsLoading(false);

            setTimeout(() => { history.push({ pathname: '/watchVideos', state: { id: ResponseResult.sessionId, SessionId: ResponseResult.sessionId, LectureId: parseInt(ResponseResult.lectureId, 10) + 1, TestimonialId: testimonialId } }) }, 2500);
        } catch (error) {
            console.error("An error occurred:", error);
            setIsLoading(false);
        }
    }

    const handleGotoPreviousSessions = () => {
        if (testimonialId == null || testimonialId == 'null') {
            history.push({ pathname: '/watchVideos', state: { id: ResponseResult.sessionId, SessionId: ResponseResult.sessionId, LectureId: ResponseResult.lectureId } });
        } else {
            history.push({ pathname: '/watchVideos', state: { id: ResponseResult.sessionId, SessionId: ResponseResult.sessionId, LectureId: ResponseResult.lectureId, testMonialId: testimonialId } });
        }
    }
    const handleGotoPreviousSessionsAfterQuizCompleted = async () => {
        if (getCourseProgress !== undefined) {
            if (ResponseResult.lectureId >= getCourseProgress.lectureId) {
                const req = {
                    userId: userData.id,
                    cour_status: 'inprogress',
                    sessionId: ResponseResult.sessionId ? ResponseResult.sessionId : getCourseProgress.sessionId,
                    lectureId: ResponseResult.lectureId ? ResponseResult.lectureId : getCourseProgress.lectureId,
                    assessmentlecture: ResponseResult.lectureId,
                    token: userAccessToken
                };
                await dispatch(ActionUpdateCourseProgressCurrentOrder(req));
            }
        }

        await dispatch(ActionCheckvalidSession());
        await dispatch(ActionUpdateNextAssesmentAttemptstatus({ token: userAccessToken, userId: userData.id }))
        if (testimonialId == null || testimonialId == "null") {
            await history.push({ pathname: '/watchVideos', state: { id: ResponseResult.sessionId, SessionId: ResponseResult.sessionId, LectureId: ResponseResult.lectureId } });
        } else {
            await history.push({ pathname: '/watchVideos', state: { id: ResponseResult.sessionId, SessionId: ResponseResult.sessionId, LectureId: ResponseResult.lectureId, testMonialId: testimonialId } });
        }
    }

    return (
        <div>
            {
                retakeAssessmentLoader || updatesessionLoader ? <div className="payment_overlay">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#6f42c1' }} />
                        <p className='mt-2 payment_loading' >Please wait...</p>
                    </div>
                </div> : ''
            }
            <div className='resultbanner'>
                <div className='resultInner py-4'>
                    <h1 className='resultbannerHeading '>MODULE {ResponseResult.sessionId} UNIT {ResponseResult.lectureId} QUIZ <span>RESULTS</span></h1>
                    <div className='container-fluid pt-2'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <ProgressComponent data={userRouteData} />
                            </div>
                            <div className='col-md-6'>
                                <h2 className='marksHeading'><span>{userRouteData.evaluation?.marks}</span>/{userRouteData.evaluation?.numOfQuestion}</h2>
                                <p>{userRouteData.evaluation?.resultDescription}</p>
                                <h5 className="font-weight-bold mons-font">Legends</h5>
                                <div className='legendDiv'>
                                    <p> <img src={correct} style={{ width: "21px" }} alt="img" />  Correctly Answered Questions</p>
                                    <p> <img src={inCorrect} style={{ width: "21px" }} alt="img" />  Incorrectly Answered Questions</p>
                                </div>
                                <div className='resultDiv'>
                                    <div>
                                        <img src={inCorrect} style={{ width: "21px" }} alt="img" />
                                        <p className='mons-font'>Poor</p>
                                        <p style={{ fontSize: "14px" }}>0%-25%</p>
                                    </div>
                                    <div>
                                        <img src={orange} style={{ width: "21px" }} alt="img" />
                                        <p>Average</p>
                                        <p>25%-50%</p>
                                    </div>
                                    <div>
                                        <img src={lightgreen} style={{ width: "21px" }} alt="img" />
                                        <p>Good</p>
                                        <p>50%-75%</p>
                                    </div>
                                    <div>
                                        <img src={correct} style={{ width: "21px" }} alt="img" />
                                        <p>Excellent</p>
                                        <p>75%-100%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='resultSubInner pb-4 pt-5 '>
                    <p className='resultRetake'>The quizzes are designed to facilitate the learning experience.</p>
                    <p className='resultRetake'>If you did not get them all correct, please review the material and select “Retake the Quiz” at the bottom of this page.</p>
                    <p><span className='resultNote'>Note :</span> If you wish to review the session and lecture again, click on 'Watch Again.' If you feel confident in your understanding, proceed with 'Retake the Quiz.'</p>
                    <div className='d-flex justify-content-center'>
                        {updateAssesment && updateAssesment.result_stage == "1" && userRouteData.evaluation?.marks !== userRouteData.evaluation?.numOfQuestion ? (
                            <div className='btnFlex'>
                                <button className='watchAgain' type='button' onClick={() => handleGotoPreviousSessions()}>
                                    Watch Again
                                </button>
                                {
                                    userRouteData.evaluation?.marks == userRouteData.evaluation?.numOfQuestion ?
                                        <button className='startQuiz'>
                                            <a onClick={handleUpdateNextSession}>Go To Next Session</a>
                                        </button>
                                        :
                                        <button className='startQuiz' onClick={() => handleRetakeQuiz()}>
                                            Retake the Quiz
                                        </button>
                                }
                            </div>
                        ) : (
                            <div className='btnFlex'>
                                <button className='watchAgain' type='button' onClick={() => handleGotoPreviousSessionsAfterQuizCompleted()}>
                                    Watch Again
                                </button>
                                <a onClick={handledownloadAnswersheet}>
                                    <button className='watchAgain'>
                                        Download Answer Sheets
                                    </button>
                                </a>
                                <button className='startQuiz'>
                                    <a onClick={handleUpdateNextSession}>Go To Next Session</a>
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className='resultQuestionContainer'>
                    <div className='resultQuestionInner'>
                        {userAnswers && userAnswers.map((item, questionIdx) => (
                            <div key={questionIdx}>
                                <label className='question'>{questionIdx + 1}. {item.question}</label>
                                <div className='py-2'>
                                    <ol type='a' className='alpha-list'>
                                        {item.questions.choices.split(" | ").map((choice, choiceIdx) => {
                                            const correctAnswer = "choice" + (choiceIdx + 1);
                                            const userAnswer = userChoices[questionIdx];
                                            const isCorrect = userAnswer === correctAnswer;
                                            const alphabeticCharacter = String.fromCharCode(97 + choiceIdx); // 97 is ASCII code for 'a'
                                            return (
                                                <li
                                                    key={choiceIdx}
                                                    className={'choice' + (choiceIdx + 1)}
                                                >
                                                    {isCorrect ? <p className={item.questions.is_right_choice == userChoices[questionIdx] ? 'correct' : 'wrong '}>{choice}</p> : choice}
                                                    <hr />
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='resultSubInner pt-5 '>
                    <p className='resultRetake'>The quizzes are designed to facilitate the learning experience.</p>
                    <p className='resultRetake'>If you did not get them all correct, please review the material and select “Retake the Quiz” at the bottom of this page.</p>
                    <p><span className='resultNote'>Note :</span> If you wish to review the session and lecture again, click on 'Watch Again.' If you feel confident in your understanding, proceed with 'Retake the Quiz.'</p>

                    <div className='d-flex justify-content-center'>
                        {updateAssesment && updateAssesment.result_stage == "1" && userRouteData.evaluation?.marks !== userRouteData.evaluation?.numOfQuestion ? (
                            <div className='btnFlex'>
                                <button className='watchAgain' type='button' onClick={() => handleGotoPreviousSessions()}>
                                    Watch Again
                                </button>
                                <button className='startQuiz' onClick={() => handleRetakeQuiz()}>
                                    Retake the Quiz
                                </button>
                            </div>
                        ) : (
                            <div className='btnFlex'>
                                <button className='watchAgain' type='button' onClick={() => handleGotoPreviousSessionsAfterQuizCompleted()}>
                                    Watch Again
                                </button>
                                <a onClick={handledownloadAnswersheet}>
                                    <button className='watchAgain'>
                                        Download Answer Sheets
                                    </button>
                                </a>
                                <button className='startQuiz'>
                                    <a onClick={handleUpdateNextSession} >Go To Next Session</a>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssesmentResult;
