import React, { Component, useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import Routes from "./Routes";
import "./App.css";
import { store, persistor, history } from "./store/store";
import { NotificationContainer } from 'react-notifications';
import "react-notifications/lib/notifications.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ActionLogout } from "./store/actions/actions-user";
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

// New component to handle inactivity
const InactivityHandler = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.rServerData.userDataLogggedIn);
  const [lastActivity, setLastActivity] = useState(Date.now());

  useEffect(() => {
    const TWO_HOURS = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

    const checkInactivity = () => {
      const inactiveTime = Date.now() - lastActivity;
      if (isLoggedIn && inactiveTime > TWO_HOURS) {
        console.log("Inactivity detected. Logging out.");
        dispatch(ActionLogout());
      }
    };

    const resetTimer = () => {
      setLastActivity(Date.now());
    };

    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

    events.forEach(event => {
      document.addEventListener(event, resetTimer);
    });

    const intervalId = setInterval(checkInactivity, 60000); // Check every minute

    return () => {
      events.forEach(event => {
        document.removeEventListener(event, resetTimer);
      });
      clearInterval(intervalId);
    };
  }, [dispatch, isLoggedIn, lastActivity]);

  return null;
};

class App extends Component {
  componentDidMount() {
    console.log("App component mounted");
  }

render() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <ConnectedRouter history={history}>
            <InactivityHandler />
            <Routes history={history} store={store} />
            <NotificationContainer />
          </ConnectedRouter>
        </Router>
      </PersistGate>
    </Provider>
  )
}
}

export default App




