import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MediaBlog.css";
import BlogCard from "./BlogCard";
import Carousel, { consts } from "react-elastic-carousel";
import { useDispatch, useSelector } from "react-redux";
import { ActionGetMediaAndBlogs } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function MediaBlog(props) {
  const [loading, setLoading] = useState(true);
  const [currentBiomagnetismVideoIndex, setCurrentBiomagnetismVideoIndex] = useState(null);
  const [currentPodcastVideoIndex, setCurrentPodcastVideoIndex] = useState(null);
  const [currentWebniarVideoIndex, setCurrentWebniarVideoIndex] = useState(null);
  const dispatch = useDispatch();
  const [Therapy, setTherapy] = useState([]);
  const [podcast, setPodcast] = useState([]);
  const [webniar, setWebinar] = useState([]);
  const [Blogs, setBlogs] = useState([]);
  const [mediaBlogs, setmediaBlogs] = useState([]);

  const carouselRef = useRef(null);

  const MyState = useSelector((state) => state);
  const isLoading = MyState.rLoading.mediaAndBlogs !== undefined ? MyState.rLoading.mediaAndBlogs : null;
  const BlogList = MyState.rServerData.mediaAndBlogs ? MyState.rServerData.mediaAndBlogs?.blog : [];

  const breakpoint = [
    { width: 500, itemsToShow: 1 },
    { width: 910, itemsToShow: 3 },
    { width: 1080, itemsToShow: 3 },
  ];

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? (
      <i className="fa-solid fa-chevron-left"></i>
    ) : (
      <i className="fa-solid fa-chevron-right"></i>
    );
    return (
      <button className="carouselBtn" onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };

  const handleTherapyVideoClick = useCallback((index) => {
    setCurrentBiomagnetismVideoIndex(index);
    setCurrentWebniarVideoIndex(null);
    setCurrentPodcastVideoIndex(null);
  }, []);

  const handlePodcastVideoClick = useCallback((IdP) => {
    setCurrentPodcastVideoIndex(IdP);
    setCurrentBiomagnetismVideoIndex(null);
    setCurrentWebniarVideoIndex(null);
  }, []);

  const handleWebniarVideoClick = useCallback((webniarId) => {
    setCurrentWebniarVideoIndex(webniarId);
  }, []);

  useEffect(() => {
    if (!MyState.rServerData.mediaAndBlogs) {
      dispatch(ActionGetMediaAndBlogs());
    }
  }, []);

  useEffect(() => {
    if (MyState.rServerData.mediaAndBlogs) {
      setmediaBlogs(MyState.rServerData.mediaAndBlogs.videoresult);
    }
  }, [MyState.rServerData.mediaAndBlogs]);

  useEffect(() => {
    if (mediaBlogs.length > 0) {
      setTherapy(mediaBlogs[0] || []);
      setPodcast(mediaBlogs[1] || []);
      setWebinar(mediaBlogs[2] || []);
      setBlogs(BlogList);
    }
  }, [mediaBlogs, BlogList]);

  return (
    <div>
      {isLoading ? (
        <div className="">
          <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
            <p className='mt-2' >Please wait...</p>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <h2 className="text-center my-5" id="mediaBlogsTitle">
              Biomagnetism Therapy
            </h2>
            <div className="container-fluid">
              <div className="row ">
                <div className="col-md-12 float-left p-0  position-relative">
                  <ul className="d-flex ps-0 m-5">
                    <Carousel
                      itemsToShow={3}
                      pagination={0}
                      breakPoints={breakpoint}
                      renderArrow={myArrow}
                    >
                      {Therapy && Therapy.map((item, index) => (
                        <div className="BiomagVideoHere">
                          <li className="list-unstyled BiomagVideo">
                            <div className="mediablogsHere px-3">
                              {currentBiomagnetismVideoIndex === index ? (
                                <div
                                  className="embed-responsive"
                                  id="mediaBlogsVideos"
                                >
                                  <iframe
                                    src={`https://www.youtube-nocookie.com/embed/${item.snippet.resourceId.videoId}?autoplay=1&rel=0&showinfo=0`}
                                    id={item.id}
                                    key={index}
                                    ref={carouselRef}
                                    width={"100%"}
                                    height={"250px"}
                                    className="iframe"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              ) : (
                                <div className="Mediablogsimg position-relative">
                                  <img
                                    src={`https://i.ytimg.com/vi/${item.snippet.resourceId.videoId}/mqdefault.jpg`}
                                    alt=""
                                    width={"100%"}
                                    height={"250px"}
                                    className="d-block position-relative "
                                    id="MediaBlogsImage"
                                  />
                                  <div
                                    className="MediaBlogPlayBtn card-img-overlay bottom-0"
                                    id="MediaBlogPlayBtn"
                                  >
                                    <a
                                      data-id={item.id}
                                      onClick={() =>
                                        handleTherapyVideoClick(index)
                                      }
                                    >
                                      <i className="fa-solid fa-play bg-light rounded-circle p-3 shadow "></i>
                                    </a>
                                    <h6 className="card-title m-0 position-absolute ">
                                      {item.snippet.title}
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>
                        </div>
                      ))}
                    </Carousel>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-center my-5" id="mediaBlogsTitle">
              Podcast with Inna Topiler
            </h2>
            <div className="container-fluid">
              <div className="row ">
                <div className="col-md-12 float-left p-0  position-relative">
                  <ul className="d-flex ps-0 m-5">
                    <Carousel
                      itemsToShow={3}
                      pagination={0}
                      breakPoints={breakpoint}
                      renderArrow={myArrow}
                    >
                      {podcast.map((item, IdP) => (
                        <div key={IdP} className="BiomagVideoHere">
                          <li className="list-unstyled BiomagVideo">
                            <div className="mediablogsHere px-3">
                              {currentPodcastVideoIndex === IdP ? (
                                <div
                                  className="embed-responsive"
                                  id="mediaBlogsVideos"
                                >
                                  <iframe
                                    src={`https://www.youtube-nocookie.com/embed/${item.snippet.resourceId.videoId}?autoplay=1&rel=0&showinfo=0`}
                                    id={item.id}
                                    key={item.id}
                                    ref={carouselRef}
                                    width={"100%"}
                                    height={"250px"}
                                    className="iframe"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              ) : (
                                <div className="Mediablogsimg position-relative">
                                  <img
                                    src={`https://i.ytimg.com/vi/${item.snippet.resourceId.videoId}/mqdefault.jpg`}
                                    alt=""
                                    width={"100%"}
                                    height={"250px"}
                                    className="d-block position-relative"
                                    id="MediaBlogsImageP"
                                  />
                                  <div
                                    className="MediaBlogPlayBtn card-img-overlay bottom-0"
                                    id="MediaBlogPlayBtn"
                                  >
                                    <a
                                      onClick={() =>
                                        handlePodcastVideoClick(IdP)
                                      }
                                    >
                                      <i className="fa-solid fa-play bg-light rounded-circle p-3 shadow "></i>
                                    </a>
                                    <h6 className="card-title m-0 position-absolute ">
                                      {item.snippet.title}
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>
                        </div>
                      ))}
                    </Carousel>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-center my-5" id="mediaBlogsTitle">
              BIOMAGNETISM WEBINAR - Luis F Garcia MD
            </h2>
            <div className="container-fluid">
              <div className="row ">
                <div className="col-md-12 float-left p-0  position-relative">
                  <ul className="d-flex ps-0 m-5">
                    <Carousel
                      itemsToShow={3}
                      pagination={0}
                      breakPoints={breakpoint}
                      renderArrow={myArrow}
                    >
                      {webniar.map((item, webniarId) => (
                        <div key={webniarId} className="BiomagVideoHere">
                          <li className="list-unstyled BiomagVideo">
                            <div className="mediablogsHere px-3">
                              {currentWebniarVideoIndex === webniarId ? (
                                <div
                                  className="embed-responsive"
                                  id="mediaBlogsVideos"
                                >
                                  <iframe
                                    src={`https://www.youtube-nocookie.com/embed/${item.snippet.resourceId.videoId}?autoplay=1&rel=0&showinfo=0`}
                                    id={item.id}
                                    key={item.id}
                                    width={"100%"}
                                    height={"250px"}
                                    className="iframe"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              ) : (
                                <div className="Mediablogsimg position-relative">
                                  <img
                                    src={`https://i.ytimg.com/vi/${item.snippet.resourceId.videoId}/mqdefault.jpg`}
                                    alt=""
                                    width={"100%"}
                                    height={"250px"}
                                    className="d-block position-relative "
                                    id="MediaBlogsImage"
                                  />
                                  <div
                                    className="MediaBlogPlayBtn card-img-overlay bottom-0"
                                    id="MediaBlogPlayBtn"
                                  >
                                    <a
                                      onClick={() =>
                                        handleWebniarVideoClick(webniarId)
                                      }
                                    >
                                      <i className="fa-solid fa-play bg-light rounded-circle p-3 shadow "></i>
                                    </a>
                                    <h6 className="card-title m-0 position-absolute ">
                                      {item.snippet.title}
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>
                        </div>
                      ))}
                    </Carousel>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="BLOGS">
            <h2 className="text-center my-5">BLOGS</h2>
            <div className="m-1">
              <BlogCard blog={BlogList} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MediaBlog;
