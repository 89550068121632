import React, { useRef, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import userIcon from "../../Assets/images/userIcon.png";
import { ActionRouteNavigate } from "../../store/actions/actions-route";
import { ActiongetUserDetails } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ActionOpenNotification } from "../../store/actions/actions-utils-data";

const ProfilePicUpload = () => {
  const MyState = useSelector((state) => state);
  const User = MyState.rServerData.userDataLogggedIn || {};
  const UserAccessToken = MyState.rServerData.Usertoken || null;
  const UserDetails = MyState.rServerData.useraccount?.userDetails;
  const isLoading = MyState.rLoading ? MyState.rLoading.useraccountLoading : null;
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(UserDetails?.profile_image || '');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleImageChange = async (e) => {
    const selectedImage = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (selectedImage && allowedTypes.includes(selectedImage.type)) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("profilepic", selectedImage);
        formData.append("userId", User.id);

        const response = await axios.post(
          "https://lmsreactbackend.helenzysinc.com/userprofileUpload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${UserAccessToken}`,
            },
          }
        );

        if (response.status === 200) {
          setImageUrl(response.data.data);
          dispatch(ActiongetUserDetails({
            userId: User.id,
            email: User.email,
            token: UserAccessToken,
          }));
          setLoading(false);
          dispatch(ActionRouteNavigate('useraccount'))
        } else {
          ActionOpenNotification("info", "failed", "Error uploading image. Please try again.");
          setLoading(false);
        }
      } catch (error) {
        setError();
        ActionOpenNotification("info", "failed", "Error uploading image. Please try again.")
        setLoading(false);
      }
    } else {
      ActionOpenNotification("info", "failed", "Please select a valid image file (PNG or JPEG)")
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center " style={{ height: '100%' }}>
      {isLoading && loading ? <div className="">
        <div className="">
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
        </div>
      </div> : <div>
        <input type="file" accept="image/*" ref={fileInputRef} style={{ display: "none" }} onChange={handleImageChange} />
        <i className="fa-solid fa-camera" onClick={() => fileInputRef.current.click()}></i>
        {imageUrl ? (
          <img src={imageUrl} alt="Preview" onClick={() => fileInputRef.current.click()} />
        ) : (
          <img src={userIcon} alt="User Icon" />
        )}
      </div>
      }
    </div>
  );
};

export default ProfilePicUpload;
