import React from 'react'
import { Button, Modal } from 'react-bootstrap'

function SomethingError({ showWrong, handleWrongClose }) {
    return (
        <div>
            <Modal
                show={showWrong}
                onHide={handleWrongClose}
                animation={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <div className='py-2' style={{ textAlign: 'center', }}>
                    <h5 className="modal-title font-weight-bold" id="exampleModalLabel">Something went wrong</h5>
                </div>
                <hr />
                <Modal.Body>
                    <div>
                        <p>
                            Oops! Something didn't work as expected.

                            Please refresh the page or try again later.

                            Thank you for your patience and understanding.                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleWrongClose}>
                        Refresh
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SomethingError
