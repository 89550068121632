export const URL_MAP = {
  BASE_URL: "",
  Home: "/",
  dummy: "dummy",
  Login: "login",
  Register: "register",
  ForgotPassword: "forgotPassword",
  ResetPassword: "resetPassword",
  AllCourse: "allCourse",
  ApplyCourse: "applyCourse",
  MyCoursePage: "myCoursePage",
  DownladAssesmentAnswers: "assesment-answers",
  CourseDetailPage: "courseDetail",
  WatchVideoPage: "watchVideos",
  UserAccountPage: "useraccount",
  AboutUs: "about-us",
  ContactUs: "contact-us",
  CourseOverview:"Course_overView",
  MediaBlog:'press-media',
  HelpVideo:'help_videos',
  Terms_Condition: 'terms-condition',
  PrivacyPolicy:'privacy-policy',
  RefundCancel:'refund-cancellation',
  Faq:'faq',
  Community:'community',
  CommunityPost:'community/topics/:cat_id/:topic_id',
  CommunityPostDetails:'community/thread',
  AssesmentPage: 'assesmentPage',
  AssesmentResult:'assesmentResult',
  AuthorizePay: 'authorizePay',
  PaymentComplete:'paymentComplete',
  normalOrderInvoice:'normarOrderInvoice',
  RenewedOrderInvoice:'RenwedOrderInvoice',
  GiftingOrderInvoice:'GiftedOrderInvoice',
  CommunitySearch: 'community/search',
  PaymentFailed:"paymentFailed",
  FeedbackPage: "feedback"
};
