import UserAPI from "../../api/user";
import { ActionClearUtils, ActionOpenNotification } from "./actions-utils-data";
import { USER_ACTIONS } from "../actions-names/users";
import { ActionLoadingUpdate } from "./actions-loading";
import { ActionSessionClear, ActionSessionStart } from "./actions-session";
import { ActionRouteNavigate } from "./actions-route";
import { ActionAllCourse, ActionAssessmentDownload, ActionClearServer, ActionCourseModule, ActionDownloadCourseMaterial, ActionGetMediaAndBlogs, ActionServerData, ActionUpdateGenetedCodeGeneretedCode, ActiongetIntiallcourseDetails, ActiongetLogedUser, ActiongetUserDetails, ActiongetUserToken } from "./actions-server-data";
import { localStorageApi } from "../../api/storage";
import ROUTES from "../../configs/routes";

export function ActionUserUpdate(key, data) {
  return {
    type: USER_ACTIONS.UPDATE,
    key,
    data,
  };
}

export function ActionClearUsersWithKey(key) {
  return (dispatch) => {
    dispatch(ActionUserUpdate(key, {}));
  };
}

export function ActionClearUser() {
  return {
    type: USER_ACTIONS.CLEAR,
  };
}

export function ActionLogin(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("login", true));

    UserAPI.login(params)
      .then((res) => {
        if (res.success === true) {
          // On successful login
          const sessionExpirationTime = Date.now() + 2 * 60 * 60 * 1000; // 2 hours in milliseconds
          localStorage.setItem('sessionExpirationTime', sessionExpirationTime);

          if (res.screen === "MyCourse") {
            dispatch(ActionRouteNavigate("myCoursePage"));
            dispatch(ActiongetIntiallcourseDetails(res.data.details))
            // if(res.data.details){ 
            dispatch(ActionAssessmentDownload({ token: res.data.token, orderid: res.data.details.orders[0].orderid, userId: res.data.user.id }));
            // dispatch(ActionCourseModule({token: res.data.token, id: res.data.details.orders[0].id, orderid: res.data.details.orders[0].orderid  }));
            // }
          } else {
            dispatch(ActionRouteNavigate("allCourse"));
          }
          dispatch(ActionAllCourse({ token: res.data.token, userId: res.data.user.id }));
          dispatch(ActionDownloadCourseMaterial({ token: res.data.token }));
          // ActionOpenNotification("success", "Login", res.message);
          dispatch(ActiongetUserToken(res.data.token));
          dispatch(ActiongetLogedUser(res.data.user));
          dispatch(ActionUserUpdate("login", res));
          dispatch(ActionSessionStart(res));
          dispatch(ActiongetUserDetails({ email: res.data.user.email, token: res.data.token, userId: res.data.user.id }))
          dispatch(ActionGetMediaAndBlogs())
        } else {
          ActionOpenNotification("info", "failed", res.message);
          dispatch(ActionUserUpdate("login", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("login", false)));
  };
}

export function ActionRegister(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("register", true));

    UserAPI.register(params)
      .then((res) => {
        if (res.success === true) {
          ActionOpenNotification("success", "Register Successfully", res.message);
          dispatch(ActionRouteNavigate("Login"));
          dispatch(ActionSessionStart(res));
        } else {
          ActionOpenNotification("info", "failed", res.message);
          dispatch(ActionUserUpdate("register", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("register", false)));
  };
}

export function ActionUpdatePassword(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("updatePassword", true));

    UserAPI.updatePassword(params)
      .then((res) => {
        if (res.success === true) {
          ActionOpenNotification("success", "Password updated", res.message);
          dispatch(ActionUserUpdate("login", res));
          dispatch(ActionSessionStart(res));
        } else {
          ActionOpenNotification("info", "failed", res.message);
          dispatch(ActionUserUpdate("login", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("updatePassword", false)));
  };
}

export function ActionUserAccUpdate(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("updateUserAcc", true));
    UserAPI.updateUserAcc(params)
      .then((res) => {
        if (res.success === true) {
          ActionOpenNotification("success", "Saved changes", res.message);
        } else {
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("updateUserAcc", false)));
  };
}

export function ActionForgotPassword(params) {
  return async (dispatch) => {
    try {
      dispatch(ActionLoadingUpdate("userForgotpassword", true));
      const res = await UserAPI.forgotPassword(params);
      if (res.success === true) {
        ActionOpenNotification("success", "Password sent to email", res.message);
        dispatch(ActionRouteNavigate("resetPassword", { params, res }));
      } else {
        ActionOpenNotification("info", "failed", res.message);
        dispatch(ActionUserUpdate("userForgotpassword", res));
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(ActionLoadingUpdate("userForgotpassword", false));
    }
  };
}

export function ActionResetPassword(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("resetPassword", true));
    UserAPI.resetPassword(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionLogin(params));
          dispatch(ActionUserUpdate("resetPassword", res));
          ActionUpdateGenetedCodeGeneretedCode({ email: params.email });
          dispatch(ActionSessionStart(res));
        } else {
          ActionOpenNotification("info", "failed", res.message);
          dispatch(ActionUserUpdate("resetPassword", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("resetPassword", false)));
  };
}


export function ActiongetCountries(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("getcountries", true));
    UserAPI.getCountriesList(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionRouteNavigate("useraccount", { ...res.data, countries: res.data }));
          ActionOpenNotification("success", "getcountries sucessfully", res.message);
        } else {
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("getcountries", false)));
  };
}

export function ActiongetStates(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("getstates", true));
    UserAPI.resetPassword(params)
      .then((res) => {
        if (res.success === true) {
          ActionOpenNotification("success", "getStates sucessfully", res.message);
        } else {
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("getstates", false)));
  };
}



export function ActionUpdateUserDetails(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("userupdate", true));
    UserAPI.resetPassword(params)
      .then((res) => {
        if (res.success === true) {
          ActionOpenNotification("success", "userupdate sucessfully", res.message);
        } else {
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("userupdate", false)));
  };
}

export function ActionAboutUs(params) {
  return (dispatch) => {
    UserAPI.aboutus(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionUserUpdate("aboutUs", res.data));
          dispatch(ActionRouteNavigate("about-us", res.data));
        } else {
          dispatch(ActionUserUpdate("aboutUs", res.data));
        }
      })
      .catch((err) => console.log(err))
  };
}



export function ActionCourseOverView(params) {
  return (dispatch) => {
    UserAPI.courseoverview(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionUserUpdate("Course_overView", res.data));
          dispatch(ActionRouteNavigate("Course_overView", res.data));
        } else {
          dispatch(ActionUserUpdate("Course_overView", res.data));
        }
      })
      .catch((err) => console.log(err))
  };
}

// export function handleLogout(){
//   return (dispatch) => {
//     dispatch(ActionLoadingUpdate("actionLogout", true));
//     UserAPI.userLogout(params)
//       .then((res) => {
//         if (res.success === true) {
//           dispatch(ActionLogout())
//         } else {
//           dispatch(ActionLogout())
//         }
//       })
//       .catch((err) => console.log(err))
//       .finally(() => dispatch(ActionLoadingUpdate("actionLogout", false)));
//   };
// }

export function ActionLogout() {
  return (dispatch) => {
    dispatch(ActionSessionClear());
    dispatch(ActionClearServer());
    dispatch(ActionClearUser());
    dispatch(ActionClearUtils());
    localStorageApi.clear();
    dispatch(ActionRouteNavigate(ROUTES.Login));
  };
}
