import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, InputGroup } from 'react-bootstrap';
import './AuthorizePay.css';
import amex from '../../Assets/images/amex.jpg';
import masterCard from '../../Assets/images/mastercard.png';
import visa from '../../Assets/images/visa.png';
import { ActionHandlePayment } from '../../store/actions/actions-server-data';
import { useDispatch, useSelector } from 'react-redux';
import Routes from '../../Routes';
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { type } from 'jquery';

function AuthorizePay(props) {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const useraccount = props.history.location.state;
    const userState = useSelector(state => state);
    const UserAccessToken = userState.rServerData.Usertoken || null;
    const userData = userState.rServerData.useraccount ? userState.rServerData.useraccount.userDetails : [];
    const isLoading = userState.rLoading ? userState.rLoading.handlepay : null;
    const [recaptchaError, setRecaptchaError] = useState("")
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [formData, setFormData] = useState({
        owner: useraccount? `${userData.firstname} ${userData.lastname}` : '',
        cvv: '',
        card_number: '',
        amount: useraccount?.price || '',
        expiration_month: ('0' + (months.indexOf('Jan') + 1)).slice(-2),
        expiration_year: new Date().getFullYear().toString()
    });

    const [errors, setErrors] = useState({
        owner: '',
        cvv: '',
        card_number: '',
        amount: '',
        expiration_month: '',
        expiration_year: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let truncatedValue = value;
        if (name === 'cvv') {
            truncatedValue = value.slice(0, 3);
        } else if (name === 'card_number') {
            truncatedValue = value.slice(0, 16);
            truncatedValue = truncatedValue.replace(/\D/g, '');
        } else if (name === 'expiration_month') {
            // Ensure the month value is converted to index
            truncatedValue = months.indexOf(value) + 1;
        }
        setFormData({ ...formData, [name]: truncatedValue.toString() });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const handleSubmit = (e, userData) => {
        e.preventDefault();
        const newErrors = {};
        if (formData.owner.trim() === '') {
            newErrors.owner = 'owner is required';
        }
        if (formData.cvv.trim() === '') {
            newErrors.cvv = 'cvv is required';
        }
        if (formData.card_number.trim() === '') {
            newErrors.card_number = 'card number is required';
        }
        if (formData.amount.trim() === '') {
            newErrors.amount = 'amount is required';
        }
        setErrors(newErrors);
        
        const commonParams = {
            token: UserAccessToken,
            userId: userData.id,
            type: useraccount.price == '995' ? "Maincourse" : 'Subscription',
            InputName: userData.firstname,
            InputEmail: userData.email,
            InputPhone: userData.contact_number,
            InputAddress: userData.address,
            InputCountry: userData.country,
            InputState: userData.state,
            InputCity: userData.city,
            InputPincode: userData.zipcode,
            InputPromocode: "",
            coursename: "coursename",
            courseprice: useraccount.price,
            netprice: useraccount.price,
            planId: useraccount.price == "500" ? 2 : 1,            
            courseid: "10",
            cardholderName: userData.firstname,
            cvv: formData.cvv,
            cardNumber: formData.card_number,
            "expiration-month": formData.expiration_month,
            "expiration-year": formData.expiration_year,
            g_recaptcha_response: recaptchaToken,
            categoryType: 'web'
        };
        
        if (Object.keys(newErrors).length === 0) {
            if (useraccount.gifting_option == 'on') {
                const obj = {
                    ...commonParams,
                    gifting_option: useraccount.gifting_option || null,
                    gifting_name: useraccount.gifting_name || null,
                    gifting_email: useraccount.gifting_email || null,
                    gifting_date: useraccount.gifting_date || null,
                    type: 'gifting'
                };
                dispatch(ActionHandlePayment(obj));
            } else {
                const param = { ...commonParams };
                dispatch(ActionHandlePayment(param));
            }
        }
        
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };
    const handlePaste = (e) => {
        e.preventDefault();
    };
    return (
        <div>
            <div className='authorizePayment'>
                {isLoading ? <div className="payment_overlay">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#fff' }} />
                        <p className='mt-3 payment_loading' >Please wait while the transaction is processing.</p>
                        <p className='payment_loading' >Do not press refresh or go back button.</p>
                    </div>
                </div> : ''}
                <div>
                    <h2 className='payInfo'>Payment Info</h2>
                </div>
                <div className='authorizeContainer pt-5'>
                    <div className='authorizeForm'>
                        <Form onSubmit={(e) => handleSubmit(e, userData)}>
                            <Row>
                                <Col md={8}>
                                    <Form.Group controlId="owner" className='mb-3'>
                                        <Form.Label>Owner</Form.Label>
                                        <Form.Control type='text' style={{textTransform:'capitalize'}} name='owner' onPaste={handlePaste} value={formData.owner} onChange={handleInputChange} />
                                        {errors.owner && <span>{errors.owner}</span>}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="cvv">
                                        <Form.Label>CVV</Form.Label>
                                        <Form.Control type='number' onPaste={handlePaste} maxLength={3} name='cvv' value={formData.cvv} onChange={handleInputChange} />
                                        {errors.cvv && <span>{errors.cvv}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <Form.Group controlId="cardNumber" className='mb-3'>
                                        <Form.Label>Card number</Form.Label>
                                        <Form.Control type='number' onPaste={handlePaste} maxLength={16} name='card_number' value={formData.card_number} onChange={handleInputChange} />
                                        {errors.card_number && <span>{errors.card_number}</span>}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="amount">
                                        <Form.Label>Amount</Form.Label>
                                        <InputGroup className="mb-3" style={{zIndex:'10'}}>
                                            <InputGroup.Text  id="basic-addon1" style={{color:'black'}}>$</InputGroup.Text>
                                            <Form.Control type='text' name='amount' value={formData.amount} onChange={handleInputChange} disabled />
                                        </InputGroup>
                                        {errors.amount && <span>{errors.amount}</span>}
                                    </Form.Group>

                                </Col>
                            </Row>
                            <div className='row'>
                                <div className='row py-2'>
                                    <div className='col-md-8'>
                                        <p>Expiration Date</p>
                                    </div>
                                    <div className='col-md-4 d-flex gap-1' style={{ paddingLeft: '25px' }}>
                                        <img src={visa} width='55px' className='px-1 py-1' />
                                        <img src={masterCard} width='55px' className='px-1' />
                                        <img src={amex} width='55px' className='px-1' />
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <Form.Group controlId="expirationMonth">
                                                <Form.Select name='expiration_month' value={months[formData.expiration_month - 1]} onChange={handleInputChange}>
                                                    {months.map((month, index) => (
                                                        <option key={index}>{month}</option>
                                                    ))}
                                                </Form.Select>
                                                {errors.expiration_month && <span>{errors.expiration_month}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <Form.Group controlId="expirationYear">
                                                <Form.Select name='expiration_year' value={formData.expiration_year} onChange={handleInputChange}>
                                                    {[...Array(15)].map((_, index) => {
                                                        const year = new Date().getFullYear() + index;
                                                        return <option key={index}>{year}</option>;
                                                    })}
                                                </Form.Select>
                                                {errors.expiration_year && <span>{errors.expiration_year}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="form-group mb-3">
                                            <ReCAPTCHA
                                                sitekey="6Ldf-OcnAAAAAJQMdFJGE_TfQk72tUhpMHFG7Kgh"
                                                onChange={handleRecaptchaChange}
                                            />
                                        </div>
                                        <div id="recapth" className="error-message text-danger">{recaptchaError}</div>
                                    </div>
                                </div>
                            </div>
                            <button className='payBtn' type='submit'>Confirm Payment</button>
                        </Form>
                    </div>
                </div>
            </div>
            {show && <Routes props={props} />}
        </div>
    );
}

export default AuthorizePay;