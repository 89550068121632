import React, { useEffect } from 'react'
import { ActionTermsAndConditions } from '../../store/actions/actions-server-data';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Terms_Condition() {
    const dispatch = useDispatch();
    const MyState = useSelector(state => state);
    const termsCondition = MyState.rServerData.termsCondition != undefined ? MyState.rServerData.termsCondition : null;
    const isLoading = MyState.rLoading ? MyState.rLoading.termsCondition : false;
    useEffect(() => {
        if(!termsCondition){
            dispatch(ActionTermsAndConditions());
        }
    }, [termsCondition, dispatch]);
    return (
        <div className='p-5'>
            {
                isLoading ? (
                    <div className="">
                        <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
                            <p className='mt-2' >Please wait...</p>
                        </div>
                    </div>
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: termsCondition && termsCondition.content }} />
                )}

        </div>
    )
}

export default Terms_Condition
