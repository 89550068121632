import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ActionUserUpdatePassword, ActionValiadteUserOldPassword } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./ChangePasswordModal.css";
import { ActionOpenNotification } from "../../store/actions/actions-utils-data";

const ChangePasswordModal = ({ show, isHide }) => {
    const dispatch = useDispatch();
    const [eyeColor, setEyeColor] = useState(false);
    const [eyeCurrentPassColor, setEyeCurrentPassColor] = useState(false);
    const [eyeConformPassColor, setEyeConformPassColor] = useState(false);
    const [updateCurrentPassword, setUpadateCurrentPassword] = useState("password");
    const [updatePasswordEye, setUpadatePasswordEye] = useState("password");
    const [updateConformPasswordEye, setUpadateConformPasswordEye] = useState("password");

    const MyState = useSelector((state) => state);
    const UserAccessToken = MyState.rServerData.Usertoken ? MyState.rServerData.Usertoken : null;
    const UserDetails = useMemo(() => MyState.rServerData.userDataLogggedIn || [], [MyState.rServerData.userDataLogggedIn]);
    const validationError = MyState.rServerData.validateOldPassord != undefined ? MyState.rServerData.validateOldPassord : null;
    const verifyPasswordLoading = MyState.rLoading.validateOldPassord != undefined ? MyState.rLoading.validateOldPassord : false;
    const isLoading = MyState.rLoading?.updatePassword || null;
    const showMessage = MyState.rServerData.updatePassword != undefined ? MyState.rServerData.updatePassword.message : '';

    const [error, setError] = useState('');
    const [errorMsg, setErrorMsg] = useState(false);
    const [formValues, setFormValues] = useState({
        oldpassword: "",
        password: "",
        password_confirmation: "",
    });
    const [validationErrors, setValidationErrors] = useState({
        oldpassword: '',
        password: '',
        password_confirmation: '',
        passwordsMismatch: false,
    });
    const [validated, setValidated] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        validateField(name, value);
    };

    const validateField = (name, value) => {
        const errors = { ...validationErrors };

        switch (name) {
            case 'oldpassword':
                errors.oldpassword = value ? '' : 'Please enter current password.';
                break;
            case 'password':
                errors.password = value.length > 7 ? '' : 'Password must be at least 8 characters.';
                break;
            case 'password_confirmation':
                errors.password_confirmation = value ? '' : 'Please enter confirm password.';
                errors.passwordsMismatch = value === formValues.password ? '' : 'Passwords do not match.';
                break;
            default:
                break;
        }

        setValidationErrors(errors);
    };

    const handleValidatePassword = () => {
        const { oldpassword } = formValues;
        const param = {
            userId: UserDetails.id,
            token: UserAccessToken,
            old_password: oldpassword,
        };
        dispatch(ActionValiadteUserOldPassword(param));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        setValidated(true);
        setUpdateSuccess(false);
        if (isValid && validationError.success === true) {
            updatePassword();
            setUpdateSuccess(true);
        }
    };

    const validateForm = () => {
        const errors = {
            oldpassword: '',
            password: '',
            password_confirmation: '',
            passwordsMismatch: false
        };
        let isValid = true;

        if (!formValues.oldpassword) {
            errors.oldpassword = 'Please enter current password.';
            isValid = false;
        }
        
        if (!formValues.password) {
            errors.password = 'Please enter new password.';
            isValid = false;
        } else if (formValues.password.length <= 7) {
            errors.password = 'Password must be at least 8 characters.';
            isValid = false;
        }

        if (!formValues.password_confirmation) {
            errors.password_confirmation = 'Please enter confirm password.';
            isValid = false;
        } else if (formValues.password !== formValues.password_confirmation  )  {
            errors.passwordsMismatch = 'Confirm password does not match with New Password.';
            isValid = false;
        }
        if(formValues.oldpassword === formValues.password && formValues.oldpassword){
            errors.password = "Current and New password should not be same"
            isValid = false;
        }
        setValidationErrors(errors);
        return isValid;
    };

    useEffect(() => {
        if (validationError) {
            setErrorMsg(true);
            const Msg = document.getElementById('erMsg');
            if (Msg) {
                if (validationError.success === true) {
                    Msg.style.color = 'green';
                    Msg.style.display = 'none';
                } else {
                    Msg.style.color = 'red';
                    Msg.style.display = 'block';
                }
                setError(validationError.message);
            }
        }
    }, [validationError]);

    useEffect(() => {
        if (MyState.rServerData.updatePassword) {
            setUpdateSuccess(MyState.rServerData.updatePassword.success);
        }
    }, [MyState.rServerData.updatePassword]);

    useEffect(() => {
        if (!isLoading == false && updateSuccess) {
            ActionOpenNotification("success", "updatePassword", "Password updated successfully");
            isHide();
        }
    }, [isLoading, updateSuccess, showMessage]);

    const updatePassword = () => {
        const { password, password_confirmation } = formValues;
        const param = {
            userId: UserDetails.id,
            token: UserAccessToken,
            oldpassword: formValues.oldpassword,
            password: password,
            confirm_password: password_confirmation,
        };
        dispatch(ActionUserUpdatePassword(param));
    };

    const CurrentPasswordViewHandlerEye = () => {
        if (updateCurrentPassword === "text") {
            setUpadateCurrentPassword("password");
            setEyeCurrentPassColor(false);
        } else {
            setUpadateCurrentPassword("text");
            setEyeCurrentPassColor(true);
        }
    };

    const PasswordViewHandlerEye = () => {
        if (updatePasswordEye === "text") {
            setUpadatePasswordEye("password");
            setEyeColor(false);
        } else {
            setUpadatePasswordEye("text");
            setEyeColor(true);
        }
    };

    const ConformPasswordViewHandlerEye = () => {
        if (updateConformPasswordEye === "text") {
            setUpadateConformPasswordEye("password")
            setEyeConformPassColor(false)
        } else {
            setUpadateConformPasswordEye("text")
            setEyeConformPassColor(true)
        }
    };

    const keyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    return (
        <Modal show={show} onHide={isHide}>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Form id="changepassword" onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Body>
                    <Form.Group controlId="current-password" className="mb-4">
                        <Form.Label>Current Password *</Form.Label>
                        <div className="d-flex">
                            <Form.Control
                                className="formControl"
                                type={updateCurrentPassword}
                                required
                                name="oldpassword"
                                value={formValues.oldpassword}
                                placeholder="Please enter current password"
                                onChange={handleInputChange}
                                onBlur={handleValidatePassword}
                                onKeyDown={keyDown}
                            />
                            <span
                                className="password-toggle eye-icon align-items-center text-center"
                                onClick={CurrentPasswordViewHandlerEye}
                                style={{ cursor: "pointer", position: "relative" }}
                                id={eyeCurrentPassColor ? "eye-color" : ""}
                            >
                                {verifyPasswordLoading ? (
                                    <div className="position-relative translate-middle" style={{ top: '8px', color: '#6f42c1', marginRight: '-12px' }}>
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                ) : (
                                    <FontAwesomeIcon
                                        className="current_password"
                                        icon={eyeCurrentPassColor ? faEyeSlash : faEye}
                                    />
                                )}
                            </span>
                        </div>
                        <div className="position-absolute">
                            {formValues.oldpassword && errorMsg && <span id="erMsg">{error}</span>}
                            {validationErrors.oldpassword && validated && <span className="text-danger">{validationErrors.oldpassword}</span>}
                        </div>
                    </Form.Group>
                    <Form.Group controlId="password" className="mb-4">
                        <Form.Label>New Password *</Form.Label>
                        <div className="d-flex">
                            <Form.Control
                                type={updatePasswordEye}
                                required
                                name="password"
                                value={formValues.password}
                                placeholder="Please enter new password"
                                onChange={handleInputChange}
                                onKeyDown={keyDown}
                                className="formControl"
                                />
                            <span
                                className="password-toggle eye-icon align-items-center text-center"
                                onClick={PasswordViewHandlerEye}
                                style={{ cursor: "pointer", position: "relative" }}
                                id={eyeColor ? "eye-color" : ""}
                            >
                                <FontAwesomeIcon
                                    className="current_password"
                                    icon={eyeColor ? faEyeSlash : faEye}
                                />
                            </span>
                        </div>
                        <div className="position-absolute">
                            {validationErrors.password && validated && <span className="text-danger">{validationErrors.password}</span>}
                        </div>
                    </Form.Group>

                    <Form.Group controlId="password_confirmation" className="mb-4">
                        <Form.Label>Confirm Password *</Form.Label>
                        <div className="d-flex">
                            <Form.Control
                            className="formControl"
                                type={updateConformPasswordEye}
                                required
                                name="password_confirmation"
                                value={formValues.password_confirmation}
                                placeholder="Please enter confirm password"
                                onChange={handleInputChange}
                                onKeyDown={keyDown}
                            />
                            <span
                                className="password-toggle eye-icon align-items-center text-center"
                                onClick={ConformPasswordViewHandlerEye}
                                style={{ cursor: "pointer", position: "relative" }}
                                id={eyeConformPassColor ? "eye-color" : ""}
                            >
                                <FontAwesomeIcon
                                    className="current_password"
                                    icon={eyeConformPassColor ? faEyeSlash : faEye}
                                />
                            </span>
                        </div>
                        <div className="position-absolute">
                            {validationErrors.password_confirmation && validated && <span className="text-danger">{validationErrors.password_confirmation}</span>}
                            {validationErrors.passwordsMismatch && validated  && formValues.password_confirmation && <span className="text-danger">Confirm password does not match with New Password.</span>}
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Please confirm your password.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={isHide}>
                        Close
                    </Button>
                    <Button variant="btn btn-primary" type="submit" disabled={verifyPasswordLoading}>
                        Confirm Changes   {isLoading && (
                            <span className="" style={{ color: '#FFF' }}>
                                <i className="fa fa-spinner fa-spin"></i>
                            </span>
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ChangePasswordModal;
