import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCourseOverView } from '../../store/actions/actions-user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function CourseOverview() {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const courseData = useSelector(state => state.router.location.state);

    useEffect(() => {
        if (!courseData) {
            dispatch(ActionCourseOverView());
        } else {
            setLoading(false);
        }
    }, [courseData, dispatch]);

    useEffect(() => {
        if (courseData) {
            setLoading(false);
        }
    }, [courseData]);

    return (
        <div>
            {loading ? (
                <div className="">
                    <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '350px' }}>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontWeight: '600', fontSize: '20px' }} />
                        <p className='mt-2' >Please wait...</p>
                    </div>
                </div>
            ) : (
                <div dangerouslySetInnerHTML={{ __html: courseData && courseData.content }} />
            )}
        </div>
    );
}

export default CourseOverview;
