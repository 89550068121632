export const LOADER_EVENTS = {
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    ERROR: 'ERROR',
  };
  
  export const STORAGE_TYPES = {
    LOCAL: 'local',
    SESSION: 'session',
    COOKIES: 'cookies',
  };
  
  export const STATUS_CODES = {
    UNAUTHORIZED: 401,
    SERVER_ERROR: 500,
  };
  
  export const STATE_EVENTS = {
    CHANGE: 'change',
    ERROR: 'error',
  };
  
  export const CURRENCY_SYMBOLS = {
    yen: '¥',
    usd: '$',
  };
  
  