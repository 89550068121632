import React, { useEffect, useState, useMemo, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import "./UserAccountPage.css";
import { useDispatch, useSelector } from "react-redux";
import { ActionUserAccUpdate } from "../../store/actions/actions-user";
import { Formik, Form } from "formik";
import InputText from "../../components/Form/InputText";
import { updateUserValidationSchema } from "../../utils/utils-validation";
import {
  ActionDownloadGiftedOrderInvoice,
  ActionDownloadNormalOrderInvoice,
  ActionDownloadRenewedOrderInvoice,
  ActionUploadUserProfile,
  ActiongetCountries,
  ActiongetLogedUser,
  ActiongetStates,
  ActiongetUserDetails,
} from "../../store/actions/actions-server-data";
import ChangePasswordModal from "./ChangePasswordModal";
import userIcon from '../../Assets/images/userIcon.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ProfilePicUpload from "./ProfilePicUpload";

function UserAccountPage() {
  const dispatch = useDispatch();
  const MyState = useSelector((state) => state);
  const [loading, setLoading] = useState(true);

  const profilePicInputRef = useRef(null);
  const UserAccessToken = MyState.rServerData.Usertoken || null;
  const UserDetails = useMemo(
    () => MyState.rServerData.userDataLogggedIn || {},
    [MyState.rServerData.userDataLogggedIn]
  );
  const countries = MyState.rServerData.countriesList || [];
  const states = MyState.rServerData.stateList || [];
  const User = MyState.rServerData.userDataLogggedIn || {};
  const OrderInvoice = MyState.rServerData.useraccount || {};
  const OrderGifting = Array.isArray(OrderInvoice.giftOrderDetails) ? OrderInvoice.giftOrderDetails : [OrderInvoice.giftOrderDetails];
  const giftedDetails = OrderInvoice.giftedDetails;
  const giftOrderDetails = OrderInvoice.giftOrderDetails;
  const mergedArray = giftedDetails.map((giftedDetail, index) => {
    return { ...giftedDetail, ...giftOrderDetails[index] };
  }); const isLoadingUser = MyState.rLoading?.useraccountLoading || false;

  const isUpdateUserLoading = MyState.rLoading?.updateUserAcc || false;
  // const isLoading = MyState.rLoading?.updatePassword || false;
  const Orders = MyState.rServerData.useraccount?.orders || [];
  const UserData = MyState.rServerData.useraccount ? MyState.rServerData.useraccount.userDetails : {};

  const [profilePic, setProfilePic] = useState(UserData.profile_image || null);
  const [FirstName, setFirstName] = useState(UserData.firstname || "");
  const [LastName, setLastName] = useState(UserData.lastname || "");
  const [profileNumber, setprofileNumber] = useState(UserData.contact_number || "");
  const [email, setemail] = useState(UserData.email || "");
  const [Password, setPassword] = useState("1234567890");
  const [address, setaddress] = useState(UserData.address || "");
  const [Country, setCountry] = useState(UserData.country || "");
  const [State, setState] = useState(UserData.state || "");
  const [City, setCity] = useState(UserData.city || "");
  const [Zipcode, setZipcode] = useState(UserData.zipcode || "");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (UserDetails.id) {
      dispatch(ActiongetUserDetails({
        userId: UserDetails.id,
        email: UserDetails.email,
        token: UserAccessToken,
      }));
    }
    dispatch(ActiongetCountries());
  }, [UserDetails.id, UserDetails.email, UserAccessToken, dispatch]);

  useEffect(() => {
    if (UserData) {
      setProfilePic(UserData.profile_image);
      setFirstName(UserData.firstname);
      setLastName(UserData.lastname);
      setprofileNumber(UserData.contact_number);
      setemail(UserData.email);
      setaddress(UserData.address);
      setCountry(UserData.country);
      setState(UserData.state);
      setCity(UserData.city);
      setZipcode(UserData.zipcode);
      dispatch(ActiongetStates({ country_code: UserData.country }));
    }
    if (isLoadingUser) {
      setLoading(false);
    }
  }, [User, isLoadingUser]);

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    setCountry(countryCode);
    dispatch(ActiongetStates({ country_code: countryCode }));
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
      const param = {
        userId: User.id,
        profilepic: file.name,
        token: UserAccessToken,
      };
      dispatch(ActionUploadUserProfile(param));
    } else {
      setProfilePic(null);
    }
  };

  const handleDownloadNormalOrderInvoice = (orderid) => {
    const param = {
      userId: UserDetails.id,
      orderid: orderid,
      token: UserAccessToken,
    };
    dispatch(ActionDownloadNormalOrderInvoice(param));
  };

  const handleDownloadRenewedOrderInvoice = (orderid) => {
    const param = {
      userId: UserDetails.id,
      orderid: orderid,
      email: UserDetails.email,
      token: UserAccessToken,
    };
    dispatch(ActionDownloadRenewedOrderInvoice(param));
  };

  const handleDownloadGiftedOrderInvoice = (orderid) => {
    const param = {
      userId: UserDetails.id,
      orderid: orderid,
      token: UserAccessToken,
    };
    dispatch(ActionDownloadGiftedOrderInvoice(param));
  };

  const handleSubmit = (values) => {
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
  };
    const obj = {
      userId: UserDetails.id,
      firstname: capitalizeFirstLetter(values.firstname),
      lastname: capitalizeFirstLetter(values.lastname),
      profileNumber: values.profileNumber,
      address: values.address,
      state: values.state,
      city: values.city,
      zipcode: values.zipcode,
      country: values.country,
      token: UserAccessToken,
    };
    dispatch(ActionUserAccUpdate(obj))

    dispatch(ActiongetUserDetails({
      userId: UserDetails.id,
      email: UserDetails.email,
      token: UserAccessToken,
    }));
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const keyDown = (e) => {
    if ((e.key === " ")) {
      e.preventDefault();
    }
  };

  return (
    <div className="container-fluid">
      {
        isLoadingUser ? <div className="">
          <div className="payment_overlay">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
              <p className='mt-2 payment_loading' >Please wait...</p>
            </div>
          </div>
        </div> : ''
      }
      {loading ? (
        <div className="payment_overlay">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#6f42c1' }} />
            <p className='mt-2 payment_loading' >Please wait...</p>
          </div>
        </div>
      ) : (
        <div>
          {show && <ChangePasswordModal show={handleShow} isHide={handleClose} />}

          <div className="userAccountCont px-3">
            <h1 className="my-5">
              Account <span className="spanBold">Details</span>
            </h1>
            <div>
              <div className="userImageUpload">
                <div className="user_profile">
                  <ProfilePicUpload />
                </div>
                <div className="userName">
                  <h2>{UserData.firstname} {UserData.lastname}</h2>
                  <p>
                    Joined on{" "}
                    {
                      new Date(UserData.created_at)
                        .toISOString()
                        .split("T")[0]
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="hrLine my-3 "></div>
          </div>
          <div className="userForm my-4">
            <h1>
              PERSONAL <span className="spanBold">DETAILS</span>
            </h1>
            {isUpdateUserLoading ? <div className="payment_overlay">
              <div className="d-flex align-items-center justify-content-center flex-column">
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#6f42c1' }} />
                <p className='mt-2 payment_loading' >Please wait...</p>
              </div>
            </div> :
              <Formik
                initialValues={{
                  firstname: FirstName,
                  lastname: LastName,
                  profileNumber: profileNumber,
                  email: email,
                  password: Password,
                  address: address,
                  country: Country,
                  state: State,
                  city: City,
                  zipcode: Zipcode,
                }}
                // validationSchema={updateUserValidationSchema}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
              >
                {({ values, handleChange, handleBlur, isSubmitting }) => (
                  <Form id="userDetailForm">
                    <Row className="py-2">
                      <Col md={4}>
                        <div className="mb-2 userLabel" id="f-26">
                          First Name
                        </div>
                        <div className="position-relative login-input">
                          <InputText
                            name="firstname"
                            placeholder="Enter your firstName"
                            value={values.firstname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="firstname"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2 userLabel" id="f-26">
                          Last Name
                        </div>
                        <div className="position-relative login-input">
                          <InputText
                            name="lastname"
                            placeholder="Enter your lastNamer"
                            value={values.lastname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="lastname"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <Col md={4}>
                        <div className="mb-2 userLabel" id="f-26">
                          Password
                        </div>
                        <div className="login-input">
                          <input
                            name="password"
                            type="password"
                            placeholder="Enter your Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                            style={{ padding: '9px', borderRadius: '5px', width: '100%', borderColor: "#d9d9d9", borderStyle: 'solid' }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2" id="f-26">
                          E-mail address
                        </div>
                        <div className="position-relative login-input">
                          <InputText
                            name="email"
                            placeholder="Enter your email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="email"
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <Col md={4}>
                        <div className="mb-2 userLabel" id="f-26">
                          Contact Number
                        </div>
                        <div className="position-relative login-input">
                          <InputText
                            type="text"
                            name="profileNumber"
                            placeholder="Enter your Contact Number"
                            value={values.profileNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="profileNumber"
                            onKeyDown={keyDown}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2 userLabel" id="f-26">
                          Address
                        </div>
                        <div className="position-relative login-input">
                          <InputText
                            name="address"
                            placeholder="Enter your address"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="address"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <Col md={4}>
                        <div className="mb-2" id="f-26">
                          Country
                        </div>
                        <div className="position-relative login-input">
                          <select
                            name="country"
                            className="form-control"
                            value={values.country}
                            onChange={handleCountryChange}
                            required
                          >
                            <option value="">Select Country</option>
                            {countries.length > 0 &&
                              countries.map((country) => (
                                <option
                                  key={country.id}
                                  value={country.country_code}
                                >
                                  {country.country_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2" id="f-26">
                          State
                        </div>
                        <div className="position-relative login-input">
                          <select
                            name="state"
                            className="form-control"
                            value={values.state}
                            onChange={handleStateChange}
                            required
                          >
                            <option value="">Select State</option>
                            {states.length > 0 &&
                              states.map((state) => (
                                <option key={state.id} value={state.state_code}>
                                  {state.state_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <Col md={4}>
                        <div className="mb-2 userLabel" id="f-26">
                          City
                        </div>
                        <div className="position-relative login-input">
                          <InputText
                            name="city"
                            placeholder="Enter your City"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="city"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2 userLabel" id="f-26">
                          Zip Code
                        </div>
                        <div className="position-relative login-input">
                          <InputText
                            name="zipcode"
                            placeholder="Enter your zipcode"
                            value={values.zipcode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="zipcode"
                            maxLength={6}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="my-3 text-end">
                      <Col md={12}>
                        <span className="changePwd" onClick={handleShow}>
                          Change Password
                        </span>
                        <button type="submit" className="userFormSubmit">
                          Save Changes
                        </button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            }
          </div>
          <div className="paymentDetails my-3">
            {OrderInvoice && Orders.length > 0 ? (
              <section className="section container m-auto display-block w-100 py-5">
                {OrderInvoice.orders && (
                  <>
                    <h3 className="uppercase text-700 pt-3 float-left">
                      PAYMENT{" "}
                      <b style={{ color: "#4c3790", fontWeight: "700" }}>
                        DETAILS
                      </b>
                    </h3>
                    {OrderInvoice.orders.map((order) => (
                      <div
                        className="cards float-left w-100 border my-2"
                        key={order.orderid}
                      >
                        <div className="col-md-12 d-flex justify-content-between py-4 px-3">
                          <div className="col-md-9 float-left">
                            <p className="mb-0">
                              Your payment is {order.status} for this{" "}
                              {OrderInvoice.course.course_name} course
                            </p>
                            <p className="mb-0 uppercase py-2">
                              FULL COURSE FEE :{" "}
                              <span
                                style={{ color: "#4c3790" }}
                                className="ritured"
                              >
                                {order.currency} {order.amount}
                              </span>
                            </p>
                            <p className="mb-0 small">
                              Ordered at:{" "}
                              <span
                                style={{ color: "#212529" }}
                                className="bold"
                              >
                                {new Date(order.created_at).toISOString().split('T')[0]}{" "}
                                {new Date(order.created_at).toTimeString().split(' ')[0]}
                              </span>
                            </p>
                          </div>
                          <div className="col-md-3 py-4">
                            <button
                              style={{ marginRight: "15px", textTransform: 'uppercase' }}
                              type="button"
                              className={`btn ${order.status === "success"
                                ? "btn-success"
                                : "btn-outline-danger"
                                } uppercase`}
                            >
                              {order.status}
                            </button>
                            {order.status === "success" && (
                              <a
                                onClick={() =>
                                  handleDownloadNormalOrderInvoice(
                                    order.orderid
                                  )
                                }
                                className="btn btn-info"
                              >
                                DOWNLOAD INVOICE
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {OrderInvoice.renewedOrder && OrderInvoice.renewedOrder.length > 0 ? (
                  <>
                    <h3 className="uppercase text-300 pt-3 float-left">
                      RENEWED ORDER{" "}
                      <b style={{ color: "rgb(76, 55, 144)", fontWeight: "700" }}>
                        DETAILS
                      </b>
                    </h3>
                    {OrderInvoice.renewedOrder.map((order) => (
                      <div
                        className="cards float-left w-100 border my-2"
                        key={order.id}
                      >
                        <div className="col-md-12 d-flex justify-content-between py-4 px-3">
                          <div className="col-md-9 float-left">
                            <p className="mb-0">
                              Your payment is {order.status} for this{" "}
                              {OrderInvoice.course.course_name} course
                            </p>
                            <p className="mb-0 uppercase py-2">
                              FULL COURSE FEE :{" "}
                              <span
                                style={{ color: "#4c3790" }}
                                className="ritured"
                              >
                                {order.currency} {order.amount}
                              </span>
                            </p>
                            <p className="mb-0 small">
                              Ordered at:{" "}
                              <span
                                style={{ color: "#212529" }}
                                className="bold"
                              >
                                {
                                  new Date(order.created_at)
                                    .toISOString()
                                    .split("T")[0]
                                }
                              </span>
                            </p>
                          </div>
                          <div className="col-md-3 float-left py-4">
                            <button
                              style={{ marginRight: "15px" }}
                              type="button"
                              className={`btn ${order.status === "success"
                                ? "btn-success"
                                : "btn-outline-danger"
                                } uppercase`}
                            >
                              {order.status}
                            </button>
                            {order.status === "success" && (
                              <a
                                onClick={() =>
                                  handleDownloadRenewedOrderInvoice(
                                    order.orderid
                                  )
                                }
                                className="btn btn-info"
                              >
                                DOWNLOAD INVOICE
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : ''}

                {mergedArray.length > 0 ?
                  Array.isArray(mergedArray) ? (
                    <>
                      <h3 className="uppercase text-300 pt-3 float-left">
                        GIFTED ORDER{" "}
                        <b style={{ color: "rgb(76, 55, 144)", fontWeight: "700" }}>
                          DETAILS
                        </b>
                      </h3>
                      {mergedArray.map((giftedItem) => (
                        <div
                          className="cards float-left w-100 border my-2"
                          key={giftedItem.id}
                        >
                          <div className="col-md-12 d-flex justify-content-between py-4 px-3">
                            <div className="col-md-9 float-left">
                              <p className="mb-0">
                                You have gifted the course to{" "}
                                {giftedItem?.gifting_name || ''} for{" "}
                                {giftedItem?.gifting_date || ''}
                              </p>
                              <p className="mb-0 uppercase py-2">
                                FULL COURSE FEE :{" "}
                                <span
                                  style={{ color: "#4c3790" }}
                                  className="ritured"
                                >
                                  {giftedItem?.currency || ''} {giftedItem?.amount || ''}
                                </span>
                              </p>
                              <p className="mb-0 small">
                                Ordered at:{" "}
                                <span
                                  style={{ color: "#212529" }}
                                  className="bold"
                                >
                                  {
                                    new Date(giftedItem?.payment_details?.created_at)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </span>
                              </p>
                            </div>
                            <div className="col-md-3 float-left py-4">
                              <button
                                style={{ marginRight: "15px", textTransform: 'uppercase' }}
                                type="button"
                                className={`btn ${giftedItem?.status === "success"
                                  ? "btn-success"
                                  : "btn-outline-danger"
                                  } uppercase`}
                              >
                                {giftedItem?.status}
                              </button>
                              {giftedItem?.status === "success" && (
                                <a
                                  onClick={() =>
                                    handleDownloadGiftedOrderInvoice(
                                      giftedItem?.orderid
                                    )
                                  }
                                  className="btn btn-info"
                                >
                                  DOWNLOAD INVOICE
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {mergedArray.length > 0 && <h3 className="uppercase text-300 pt-3 float-left">
                        GIFTED ORDER{" "}
                        <b style={{ color: "Blue", fontWeight: "600" }}>
                          DETAILS
                        </b>
                      </h3>
                      }
                      {mergedArray.map((giftedItem) => (
                        <div
                          className="cards float-left w-100 border my-2"
                          key={giftedItem?.id}
                        >
                          <div className="col-md-12 d-flex justify-content-between py-4 px-3">
                            <div className="col-md-9 float-left">
                              <p className="mb-0">
                                You have gifted the course to{" "}
                                {giftedItem?.gifting_name || " "} for{" "}
                                {giftedItem?.gifting_date || " "}
                              </p>
                              <p className="mb-0 uppercase py-2 ">
                                FULL COURSE FEE :{" "}
                                <span
                                  style={{ color: "#4c3790" }}
                                  className="ritured"
                                >
                                  {giftedItem?.currency} {giftedItem?.amount}
                                </span>
                              </p>
                              <p className="mb-0 small">
                                Ordered at:{" "}
                                <span
                                  style={{ color: "#212529" }}
                                  className="bold"
                                >
                                  {
                                    giftedItem && new Date(giftedItem?.created_at)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </span>
                              </p>
                            </div>
                            <div className="col-md-3 float-left py-4">
                              <button
                                style={{ marginRight: "15px" }}
                                type="button"
                                className={`btn ${giftedItem?.status === "success"
                                  ? "btn-success"
                                  : "btn-outline-danger"
                                  } uppercase`}
                              >
                                {giftedItem?.status}
                              </button>
                              {giftedItem?.status === "success" && (
                                <a
                                  onClick={() =>
                                    handleDownloadGiftedOrderInvoice(
                                      giftedItem?.orderid
                                    )
                                  }
                                  className="btn btn-info"
                                >
                                  DOWNLOAD INVOICE
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : ''}
              </section>
            ) : ''}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserAccountPage;
