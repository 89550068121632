import React, { useState } from 'react';
import { Formik, Form } from "formik";
import InputText from "../../components/Form/InputText";
import './ForgotPassword.css';
import { forgetPasswordFormValidationSchema } from '../../utils/utils-validation';
import { useDispatch, useSelector } from 'react-redux';
import { ActionForgotPassword } from '../../store/actions/actions-user';
import { Button } from 'antd';

function ForgotPassword() {
    // const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();
    const MyState = useSelector(state => state);
    const isLoading = MyState.rLoading ? MyState.rLoading.userForgotpassword : null;

    const handleForgot = (param) => {
        dispatch(ActionForgotPassword(param))
    }
    return (
        <div className='container-fluid'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 text-center mb-4'>
                        <h1 className='forgotHeading'>Welcome</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Formik
                            initialValues={{ email: "" }}
                            validationSchema={forgetPasswordFormValidationSchema}
                            onSubmit={(values) => handleForgot(values)}
                            enableReinitialize
                            render={({ values, handleChange, handleBlur }) => {
                                return (
                                    <Form className="row w-100">
                                        <div className="mb-2 mt-2 col-md-4 col-sm-12   text-end" id="f-26" style={{ color: 'rgb(119, 227, 222)' }}>
                                            Email Address :
                                        </div>
                                        <div className="position-relative login-input col-md-8 col-sm-12">
                                            <InputText
                                                name="email"
                                                placeholder={"Enter your Email "}
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>

                                        <div className="mt-3" style={{ marginLeft: '200px' }}>
                                            <button className='nextBtn' type="submit" id="resetBtn" disabled={isLoading}>
                                                SUBMIT {isLoading && <span className='px-2'><i className="fa fa-spinner fa-spin"></i></span>}
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;
