import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from '../src/store/store';
import { ActionLogout } from './store/actions/actions-user';

window.ActionLogout = ActionLogout;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

window.store = store;