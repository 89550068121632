import React, { useEffect, useMemo, useState } from 'react';
import apply_course from '../../Assets/images/apply-course-img.png';
import './AllCourse.css';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAllCourse, ActionApplyCourse } from '../../store/actions/actions-server-data';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function AllCourse() {
    const [singleCard, setSingleCard] = useState([]);
    const [subscriptionCard, setSubscriptionCard] = useState([]);
    const userState = useSelector(state => state);
    const UserAccessToken = userState.rServerData.Usertoken || null;
    const userData = userState.rServerData.useraccount ? userState.rServerData.useraccount.userDetails : [];
    const orderDetails = userState.rServerData.useraccount ? userState.rServerData.useraccount.renewedOrder : [];
    const orders = userState.rServerData.useraccount ? userState.rServerData.useraccount.orders : [];
    const allCourse = userState.rServerData.allCourse ? userState.rServerData.allCourse.data : [];
    const isLoadingCourse = userState.rLoading ? userState.rLoading.allCourse : '';
    const applyCourseLoader = userState.rLoading ? userState.rLoading.applyCourse : '';
    const isNoticedsed = allCourse.isOnNotice;
    const renewalBtns = userState.rServerData?.IntiallcourseDetails;
    const dispatch = useDispatch();

    const UserDetails = useMemo(() => userState.rServerData.userDataLogggedIn || [], [userState.rServerData.userDataLogggedIn]);
    const renewalButtons = UserDetails;

    const handleSubmit = (e, item) => {
        e.preventDefault();
        const obj = {
            token: UserAccessToken,
            userId: userData.id,
            tenure: orderDetails.length > 0 ? orderDetails[0]?.tenure : '1',
            courseid: orderDetails.length > 0 ? orderDetails[0]?.courseid : item.id,
            courseType: "Maincourse"
        };
        dispatch(ActionApplyCourse(obj));
    };

    const handleSubscription = (e, item) => {
        e.preventDefault();
        const obj = {
            token: UserAccessToken,
            userId: userData.id,
            tenure: item.tenure,
            courseid: item.courseid,
            courseType: "Subscription",
        };
        const price = item.amount;
        dispatch(ActionApplyCourse(obj, price))
    }

    useEffect(() => {
        setSingleCard(allCourse.courses)
        setSubscriptionCard(allCourse.repeatedCourses);
    }, [allCourse]);

    function getOneMonthBefore(expirationDate) {
        // Parse the expiration date
        const date = new Date(expirationDate);

        // Subtract one month
        date.setMonth(date.getMonth() - 1);

        // Format the date to Y-M-D
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        // Return the formatted date
        return `${year}-${month}-${day}`;
    }

    // const expirationDate = renewalBtns?.orders[0]?.expired_at;
    // const oneMonthBefore = getOneMonthBefore(expirationDate);
    // const currentDate = new Date().toISOString().split('T')[0];
    // const showButton = currentDate >= oneMonthBefore;

    return (
        <div className='container'>
            {
                applyCourseLoader && <div className="payment_overlay">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#6f42c1' }} />
                        <p className='mt-2 payment_loading' >Please wait...</p>
                    </div>
                </div>
            }
            <div className='mt-5'>
                {isLoadingCourse ? (
                    <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '350px' }}>
                        <p style={{ fontWeight: '600', fontSize: '20px' }}>Loading...</p>
                    </div>
                ) : (
                    <div>
                        {singleCard && singleCard.map((item, idx) => (
                            <div className="card" key={idx}>
                                <form onSubmit={(e) => handleSubmit(e, item)}>
                                    <div className="skeleton-wrapper">
                                        {item.course_image ? (
                                            <img
                                                src={item.course_image}
                                                className="card-img-top"
                                                alt="img"
                                                id="apply_img"
                                            />
                                        ) : (
                                            <div className="skeleton-image"></div>
                                        )}
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title" style={{ fontSize: '20px' }}>{item.course_name}</h5>
                                        <p className="card-text">{item.course_description}</p>
                                        <Button className="allCourseBtn" type='submit'>
                                            Buy for ${item.course_price}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className='mt-5 mb-5'>
                {/* {renewalButtons && renewalButtons.is_notice_enabled === 1 || showButton ? ( */}
                {renewalButtons && renewalButtons.is_notice_enabled === 1 ? (
                    <div className='card-container '>
                        <h2>Subscription</h2>
                        <hr />
                        <div className="card-container d-flex flex-wrap gap-3">
                            {subscriptionCard && subscriptionCard.map((item, idx) => (
                                <div className="card" key={idx}>
                                    <form onSubmit={(e) => handleSubscription(e, item)}>
                                        <img src={singleCard[0].course_image} className="card-img-top" alt="img" id="apply_img" />
                                        <div className="card-body">
                                            <h5 className="card-title" style={{ fontSize: '20px' }}>{singleCard[0].course_name}</h5>
                                            <p className="card-text">{singleCard[0].course_description}</p>
                                            <Button className="allCourseBtn" type='submit'>
                                                Buy for ${item.amount}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>

    )
}

export default AllCourse;
