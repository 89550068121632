import React, { useMemo, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const GuideBookModal = ({ show, handleClose, Url }) => {
    const MyState = useSelector((state) => state);
    const UserDetails = useMemo(() => MyState.rServerData.userDataLogggedIn || [],[MyState.rServerData.userDataLogggedIn]);
    const userLogin = MyState.rServerData.userDataLogggedIn
    const order = MyState.rServerData.IntiallcourseDetails ? MyState.rServerData.IntiallcourseDetails : [];
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState("")
    const [downloadProgress, setDownloadProgress] = useState(0);


    const handleDownload = async () => {
        setDownloading(true);
            try {
                const response = await fetch(Url.data);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
        
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'GuideBook.pdf');
                link.style.display = 'none';
                document.body.appendChild(link);
        
                link.click();
        
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        
            setDownloading(false);
    };
    const handlePurchase=()=>{
        if(userLogin){
            setError("This material will be available once the online training is purchased. We look forward to you joining our biomagnetism community.")
        }
        else{
            setError("Email is Requried Here")
        }
        return
    }
    
    

    return (
        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="col-md-12 px-5 py-4 text-center" id="getcourse">
                <h2 className="text-center">Download <span style={{color:'#4c3790', fontWeight:'700'}} className="text-bold ritured">The Complete Guide for Biomagnetism Practitioners</span></h2>
                <p className="mt-5 uppercase font-weight-bold"><b>YOU WILL BE ABLE TO DOWNLOAD THE GUIDEBOOK. YOU SHOULD FIND THE PDF FILE IN YOUR DOWNLOADS FOLDER.</b></p>
                <p className="mt-1 uppercase font-weight-bold"><b>ENTER YOUR EMAIL ADDRESS</b></p>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <b className="font-weight-bold" style={{ position: 'relative', fontSize: '12px', right: '160px', top: '11px', background: 'white' }}>E-MAIL ADDRESS</b>
                        <input type="email" style={{padding:'13px 0px 11px 12px'}} name="outline-email" id="outline-email" className="form-control" placeholder="" required="true" readOnly disabled value={UserDetails.email} />
                    </div>
                    <div className="col-md-2"></div>
                </div>
                <br />
                <span id="valid-email"></span>
                <br />
                {downloading ? (
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                ) : order.orders ?.length > 0 ?
                    <button type="button" className="btn btn-primary uppercase" style={{ fontWeight: '600', background: '#48308c', color: 'white', border: '1px solid white', boxShadow: '0 6.5px 25px 0 rgba(72, 48, 140, 0.32)', padding: '9px 49px', fontSize: '15px' }} onClick={handleDownload}>
                        DOWNLOAD NOW!!
                    </button>
                    :
                    <div> 
                  {error && <div className="text-danger mb-1">{error}</div>}
                    <button type="button" className="btn btn-primary uppercase" style={{ fontWeight: '600', background: '#48308c', color: 'white', border: '1px solid white', boxShadow: '0 6.5px 25px 0 rgba(72, 48, 140, 0.32)', padding: '9px 49px', fontSize: '15px' }} onClick={handlePurchase} >
                        DOWNLOAD NOW!!
                    </button>
                  </div>

                }
            </div>
            </Modal.Body>
        </Modal>
    );
};

export default GuideBookModal;
