import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CommunityPost.css';
import { ActionPostCommunityDetails, ActionPostCommunityLikes, ActionPostCommunityLikesApi } from '../../store/actions/actions-server-data';
import Pagination from 'react-bootstrap/Pagination';
import userIcon from '../../Assets/images/userIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function CommunityPost() {
    const userState = useSelector(state => state);
    const userData = userState.rServerData.Usertoken;
    const isCommunityLoading = userState.rLoading ? userState.rLoading?.communityPost : '';
    const isLoading = userState.rLoading ? userState.rLoading?.communityLikes : '';
    const communityData = userState.rServerData.communityDetail ? userState.rServerData.communityDetail : [];
    const userDetails = userState.rServerData.userDataLogggedIn || [];

    const [showReplies, setShowReplies] = useState({});
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
    const [vote, setvote] = useState();
    const [likeId, setLikeId] = useState(null);

    const toggleReplies = (itemId) => {
        setShowReplies(prevState => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    };

    const paginate = (pageNumber) => { setCurrentPage(pageNumber) };

    useEffect(() => {
        setCurrentPage(1);
    }, [communityData.posts]);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = communityData.posts ? communityData.posts.slice(indexOfFirstPost, indexOfLastPost) || [] : [];
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear().toString().slice(-2);
        return `${day} ${month} ${year}`;
    };

    const handlePostDetails = (item) => {
        const obj = {
            token: userData,
            postId: item.id,
            topicid: item.topic_id,
            categoryid: item.category_id
        }
        dispatch(ActionPostCommunityDetails(obj));
    }

    const handleVote = async (item, type) => {
        setLikeId(item.id)
        let obj = {
            token: userData,
            type: type === 'up' ? 'up' : 'down',
            userId: userDetails.id,
            category: "posts",
            note: item.note,
            dataId: item.id
        };
        dispatch(ActionPostCommunityLikes(obj));
    };

    useEffect(() => {
        const param = {
            categoryid: currentPosts[0]?.category_id,
            topicid: currentPosts[0]?.topic_id,
            token: userData
        };
        dispatch(ActionPostCommunityLikesApi(param));
    }, [isLoading]);

    return (
        <div className='p-5'>
            <div className='container-fluid'>
                {isCommunityLoading &&
                    <div className="payment_overlay">
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
                            <p className='mt-2 payment_loading' >Please wait...</p>
                        </div>
                    </div>
                }
                {
                    currentPosts && currentPosts.length > 0 ?
                        (currentPosts.map(item => (
                            <div className='row w-100' key={item.id}>
                                <div className='col-md-1'>
                                    <div className='response'>
                                        <span>{item.comments.length}</span>
                                        <span>Response</span>
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <span className='postTitle' onClick={() => handlePostDetails(item)}>{item.post_name}</span>
                                    <div className='repliesContainer pt-3'>
                                        <div style={{ display: 'flex', position: 'relative', paddingTop: '5px', height: '30px' }}>
                                            <i className="fa-solid fa-thumbs-up px-1" onClick={() => handleVote(item, 'up')}>
                                                <span className='px-1' style={{ color: 'black' }}>{item.up_vote}</span>
                                            </i>
                                            <i className="fa-solid fa-thumbs-down px-1" onClick={() => handleVote(item, 'down')}>
                                                <span className='px-1' style={{ color: 'black' }}>{item.down_vote}</span>
                                            </i>
                                            {likeId == item.id && isLoading ? <span className="px-1" style={{ color: '#6f42c1', position: 'relative', bottom: '5px' }}>
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </span> : ''}
                                        </div>
                                        <div>
                                            {item.comments.length > 0 && (
                                                !showReplies[item.id] ? (
                                                    <div className='viewReplies' onClick={() => toggleReplies(item.id)}>
                                                        <i className="fa fa-chevron-down" style={{ marginLeft: '5px' }}></i> View {item.comments.length} reply
                                                    </div>
                                                ) : (
                                                    <div className='hideReplies' onClick={() => toggleReplies(item.id)}>
                                                        <i className="fa fa-chevron-up" style={{ marginLeft: '5px' }}></i> Hide replies
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className='postRightSide'>
                                        <div className='up_profile'>
                                            {item?.user?.profile_image == null ? (
                                                <img src={userIcon} />
                                            ) : (
                                                <img src={item?.user?.profile_image} alt="Profile" />
                                            )}
                                        </div>
                                        <div>
                                            <p>On {formatDate(item.created_at)} from {item?.user?.firstname}</p>
                                        </div>
                                    </div>
                                </div>
                                {showReplies[item.id] && item.comments.length > 0 && (
                                    <div className="col-md-12" style={{ paddingLeft: "100px", paddingTop: "20px" }}>
                                        {item.comments.map(comment => (
                                            <div key={comment.id}>
                                                <div className='commentDiv mb-3'>
                                                    <div style={{ width: '40px', height: '40px', borderRadius: '50%', background: 'slategrey' }}>
                                                        <img src={comment.user.profile_image} width='100%' height='100%' style={{ borderRadius: '50%' }} />
                                                        <p>{comment?.user?.firstname}</p>
                                                    </div>
                                                    <div>
                                                        <p>{comment?.comment}</p>
                                                    </div>
                                                </div>
                                                <hr className='mt-4' />
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <hr className='mt-4' />
                            </div>
                        ))) : (<div className='text-center py-5'>
                            <p style={{ fontSize: '20px', fontWeight: '600' }}>No posts available.</p>
                        </div>)
                }
            </div>

            <div style={{ display: 'flex', width: '100%', alignItems: 'end', justifyContent: 'end' }}>
                {communityData.posts && communityData.posts.length > postsPerPage &&
                    <Pagination>
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {communityData.posts && (
                            Array.from({ length: Math.ceil(communityData.posts.length / postsPerPage) }, (_, i) => (
                                <Pagination.Item key={i + 1} active={currentPage === i + 1} onClick={() => paginate(i + 1)}>
                                    {i + 1}
                                </Pagination.Item>
                            ))
                        )}
                        <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(communityData?.posts?.length / postsPerPage)} />
                    </Pagination>
                }
            </div>

        </div>
    )
}

export default CommunityPost;

