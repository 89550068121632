import { USER_ACTIONS } from './users';
import { UTILS_ACTIONS } from './utils';
import { SERVER_ACTIONS } from './server';
import { LOADING_ACTIONS } from './loading';
import { SESSION_ACTIONS } from './sessions';

const actionTypes = {
  USER_ACTIONS,
  UTILS_ACTIONS,
  SERVER_ACTIONS,
  LOADING_ACTIONS,
  SESSION_ACTIONS,
};

export default actionTypes;
