import ServerData from "../../api/server";
import { ActionLoadingUpdate } from "./actions-loading";
import { SERVER_ACTIONS } from "../actions-names/server";

import { ActionOpenNotification } from "./actions-utils-data";
import { ActionRouteNavigate } from "./actions-route";
import { buildRoute } from "../../configs/routes";
import { URL_MAP } from "../../constants/urls";
import { ActionSessionStart } from "./actions-session";
import { ActionLogin } from "./actions-user";
export const SET_ASSESSMENT_ICON_VISIBILITY = 'SET_ASSESSMENT_ICON_VISIBILITY';

export function ActionServerData(ns, payload) {
  const data = {};
  data[ns] = payload;
  return {
    type: SERVER_ACTIONS.UPDATE_DATA,
    data,
  };
}

export function ActionClearServer() {
  return {
    type: SERVER_ACTIONS.CLEAR,
  };
}

export function ActionClearServerWithKey(key) {
  return (dispatch) => {
    dispatch(ActionServerData(key, undefined));
  };
}

export function Actiondummy(param) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("dummy", true));
    ServerData.dummy(param)
      .then((res) => {
        if (res.success === true) {
          ActionOpenNotification("success", "success", res.message);
          // dispatch(ActionCalendarTilesData({ employeeCode: res.data.employeeCode }));
          dispatch(ActionServerData("dummy", res.data));
        } else {
          ActionOpenNotification("info", "failed", res.message);
          dispatch(ActionServerData("dummy", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("dummy", false)));
  };
}




export function ActionFaq(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("faq", true));
    ServerData.faq(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("faq", res.data));
          dispatch(ActionRouteNavigate("faq", res.data));
        } else {
          dispatch(ActionServerData("faq", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("faq", false)));
  };
}


export function ActionMyCourse(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("mycourse", true));
    ServerData.mycourse(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("mycourse", res));
          dispatch(ActionRouteNavigate("myCoursePage", res));
        } else {
          dispatch(ActionServerData("mycourse", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("TaskHistory", false)));
  };
}

export function ActionCourseModule(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("courseModule", true));
    ServerData.coursemodule(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("courseModule", res));
          dispatch(ActionLoadingUpdate("courseModule", false))
        } else {
          dispatch(ActionServerData("courseModule", res));
          dispatch(ActionLoadingUpdate("courseModule", false))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("courseModule", false)));
  };
}

export function ActionAssessmentDownload(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("AssessmentDownload", true));
    ServerData.AssessmentDownload(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("AssessmentDownload", res.data));
          // dispatch(ActionLoadingUpdate("AssessmentDownload", false))
        } else {
          // dispatch(ActionLoadingUpdate("AssessmentDownload", false))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("AssessmentDownload", false)));
  };
}

export function ActionAssesment(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("isAssesmentLoading", true));
    ServerData.assesments(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("assesment", res.data));
        } else {
          dispatch(ActionServerData("assesment", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("isAssesmentLoading", false)));
  };
}

export function ActionAllCourse(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("allCourse", true));
    ServerData.allcourse(params)
      .then((res) => {
        if (res) {
          dispatch(ActionServerData("allCourse", res));
        } else {
          dispatch(ActionServerData("allCourse", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("allCourse", false)));
  };
}

export function ActionApplyCourse(params, price) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("applyCourse", true));
    ServerData.applycourse(params)
      .then((res) => {
        if (res) {
          dispatch(ActionServerData("applyCourse", res));
          dispatch(ActionRouteNavigate("applyCourse", price));
        } else {
          dispatch(ActionRouteNavigate("applyCourse", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("applyCourse", false)));
  };
}

export function ActionHandlePayment(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("handlepay", true));
    ServerData.handlepay(params)
      .then((res) => {
        if (res.success === true) {
          if (res.data.success === true) {
            dispatch(ActiongetLogedUser(res.data.user))
            dispatch(ActionRouteNavigate("paymentComplete", res));
            dispatch(ActionServerData("paymentComplete", { res, params }));
            dispatch(ActiongetUserDetails({
              userId: params.userId,
              email: params.InputEmail,
              token: params.token,
            }));
          } else {
            dispatch(ActionRouteNavigate("paymentFailed", res));
            dispatch(ActionServerData("paymentComplete", { res, params }));
            dispatch(ActionOpenNotification("info", "failed", res.data.messageText))
          }
        } else {
          dispatch(ActionRouteNavigate("allCourse", res));
          dispatch(ActionServerData("paymentComplete", res))
          // dispatch(ActionOpenNotification("failed","allCourse", res.data.messageText))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("handlepay", false)));
  };
}

export function AtionSaveAssesmentResults(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("assesmentSubmit", true));
    ServerData.assesmentsresults(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("assesmentResult", res.data, params));
          dispatch(ActionServerData("userChoices", params));
          dispatch(ActionRouteNavigate("assesmentResult", { res, params }));
        } else {
          dispatch(ActionServerData("assesmentResult", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("assesmentSubmit", false)));
  };
}

export function ActionDownloadAssessment(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("downloadAssessment", true));
    ServerData.downloadassesment(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("downloadAssessment", res.data));
        } else {
          dispatch(ActionServerData("downloadAssessment", res.data));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(ActionLoadingUpdate("downloadAssessment", false)));
  };
}

export function ActionRetakeAssessment(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("retakeAssessment", true));
    ServerData.retakeassesment(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("retakeAssessment", res));
        } else {
          dispatch(ActionServerData("retakeAssessment", res));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(ActionLoadingUpdate("retakeAssessment", false)));
  };
}

export function ActionVerifyGiftEmail(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("verifygiftemail", true));
    ServerData.verifygiftemail(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("verifygiftemail", res));
          // dispatch(ActionRouteNavigate("community", res.data));
        } else {
          dispatch(ActionServerData("verifygiftemail", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("verifygiftemail", false)));
  }
}

export function ActionVerifyGeneretedCode(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("generetedCode", true));
    ServerData.verifygeneteredCode(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("generetedCode", res));
          ActionOpenNotification("success", "ForgotPassword", res.message);
        } else {
          dispatch(ActionServerData("generetedCode", res));
          dispatch(ActionOpenNotification("info", "failed", res.message))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("generetedCode", false)));
  }
}

export function ActionUpdateGenetedCodeGeneretedCode(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("UpdateCode", true));
    ServerData.updateGeneretedCode(params)
      .then((res) => {
        if (res.success === true) {

          // dispatch(ActionServerData("UpdateCode", res));
        } else {
          dispatch(ActionServerData("UpdateCode", res));
          ActionOpenNotification("success", "Please verify the generated code", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("UpdateCode", false)));
  }
}

export function ActionGetCommunity(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("community", true));
    ServerData.community(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("community", res.data));
        } else {
          dispatch(ActionServerData("community", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("community", false)));
  };
}


export function ActionPostCommunity(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("communityDetail", true));
    ServerData.postcommunity(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("communityDetail", res.data));
          if(params.data != 3){
            dispatch(ActionRouteNavigate(`community/topics/${params.categoryid}/${params.topicid}`, res.data)); 
          }
        } else {
          dispatch(ActionServerData("communityDetail", res ? res.data : null));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("communityDetail", false)));
  };
}

export function ActionPostCommunityLikesApi(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("communityDetail", true));
    ServerData.postcommunity(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("communityDetail", res.data));
        } else {
          dispatch(ActionServerData("communityDetail", res ? res.data : null));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("communityDetail", false)));
  };
}

// export function ActionPostCommunity(params) {
//   return (dispatch) => {
//     dispatch(ActionLoadingUpdate("communityDetail", true));
//     return new Promise((resolve, reject) => {
//       ServerData.postcommunity(params)
//         .then((res) => {
//           if (res && res.success === true) {
//             dispatch(ActionServerData("communityDetail", res.data));
//             // dispatch(ActionRouteNavigate(`community/topics/${params.categoryid}/${params.topicid}`, res.data));
//             resolve();
//           } else {
//             dispatch(ActionServerData("communityDetail", res ? res.data : null));
//             reject();
//           }
//         })
//         .catch((err) => {
//           reject();
//         })
//         .finally(() => dispatch(ActionLoadingUpdate("communityDetail", false)));
//     });
//   };
// }

export function ActionPostCommunityDetails(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("communityPost", true));
    ServerData.postcommunitydetails(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("communityPost", res.data));
          dispatch(ActionRouteNavigate(buildRoute(URL_MAP.CommunityPostDetails, params.categoryid, params.topicid, params.postId), res));
        } else {
          dispatch(ActionServerData("communityPost", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("communityPost", false)));
  };
}



export function ActionPostQuery(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("postQuery", true));
    ServerData.postquery(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("postQuery", res.data));
          dispatch(ActionGetCommunity({ token: params.token }));
          ActionOpenNotification("success", "community", res.message);
        } else {
          dispatch(ActionServerData("postQuery", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("postQuery", false)));
  };
}

export function ActionAskQuery(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("askQuery", true));
    ServerData.askquery(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("askQuery", res.data));
          ActionOpenNotification("success", "askQuery", res.message);
        } else {
          dispatch(ActionServerData("askQuery", res.data));
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("askQuery", false)));
  };
}

export function ActionPostCommunityLikes(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("communityLikes", true));
    ServerData.postcommunitylikes(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("communityLikes", res));
        } else {
          dispatch(ActionServerData("communityLikes", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("communityLikes", false)));
  };
}

export function ActionPostCommentLikes(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("communityCommentLikes", true));
    ServerData.postcommentlikes(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("communityCommentLikes", res));
          window.location.reload()
        } else {
          dispatch(ActionServerData("communityCommentLikes", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("communityCommentLikes", false)));
  };
}

export function ActionPostSubCommentLikes(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("subcommunityCommentLikes", true));
    ServerData.postsubcommentlikes(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("subcommunityCommentLikes", res));
          window.location.reload()
        } else {
          dispatch(ActionServerData("subcommunityCommentLikes", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("subcommunityCommentLikes", false)));
  };
}

export function ActionSearchPost(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("searchPost", true));
    ServerData.searchpost(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("searchPost", res.data));
          dispatch(ActionRouteNavigate("community/search", res));
        } else {
          dispatch(ActionServerData("searchPost", res.data));
          dispatch(ActionRouteNavigate("community/search", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("searchPost", false)));
  };
}

export function ActionCommunityComment(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("comment", true));
    ServerData.communitycomment(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("comment", res));
          window.location.reload()
        } else {
          dispatch(ActionServerData("comment", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("comment", false)));
  };
}

export function ActionCommunitySubComment(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("subcomment", true));
    ServerData.communitysubcomment(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("subcomment", res));
          window.location.reload()
        } else {
          dispatch(ActionServerData("subcomment", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("subcomment", false)));
  };
}

export function ActionDownloadCourseMaterial(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("Coursematerial", true));
    ServerData.downloadcoursematerial(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("Coursematerial", res));
        } else {
          dispatch(ActionServerData("Coursematerial", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("Coursematerial", false)));
  };
}

export function ActionDownloadGuideBook(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("GuideBook", true));
    ServerData.downloadcourseguidebook(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("GuideBook", res));
        } else {
          dispatch(ActionServerData("GuideBook", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("GuideBook", false)));
  };
}

export function ActionGetCourseCompletionCertificate(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("completionCertificate", true));
    ServerData.downloadCourseCertificate(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("completionCertificate", res));
        } else {
          dispatch(ActionServerData("completionCertificate", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("completionCertificate", false)));
  };
}

export function ActiongetCountries() {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("countriesList", true));
    ServerData.getCountriesList()
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("countriesList", res.data));
        } else {
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("countriesList", false)));
  };
}

export function ActiongetStates(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("stateList", true));
    ServerData.getStateList(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("stateList", res.data));
        } else {
        }
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(ActionLoadingUpdate("stateList", false)));
  };
}

export function ActionUploadProfilePhoto(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("uploadPic", true));
    ServerData.userProfileImageUpload(params)
      .then((res) => {
        if (res.success === true) {
          ActionOpenNotification("success", "Login", res.message);
          dispatch(ActionServerData("uploadPic", res.data));
        } else {
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((error) => {
      })
      .finally(() => dispatch(ActionLoadingUpdate("uploadPic", false)));
  };
}

export function ActionSaveSubscriber(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("savesubscriber", true));
    ServerData.saveSubscriber(params)
      .then((res) => {
        if (res.success === true) {
          ActionOpenNotification("success", "savesubscriber", res.message);
        } else {
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((error) => {
      })
      .finally(() => dispatch(ActionLoadingUpdate("savesubscriber", false)));
  };
}



export function ActiongetUserDetails(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("useraccountLoading", true));
    ServerData.getUserDetails(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("useraccount", res.data));
        } else {
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("useraccountLoading", false)));
  };
}

let invoiceWindow;
export function ActionDownloadNormalOrderInvoice(params) {
  return (dispatch) => {
    if (invoiceWindow && !invoiceWindow.closed) {
      alert("The invoice window is already open.");
      // return;
    }
    dispatch(ActionLoadingUpdate("NormalOrderInvoice", true));
    ServerData.getNormalOrderInvoice(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("NormalOrderInvoice", res.data));
          invoiceWindow = window.open(
            'normarOrderInvoice',
            'myActionWin',
            'width=500,height=300,toolbar=0'
          );

          invoiceWindow.blur();

          // Close the invoice window if the user refreshes the page
          window.addEventListener("beforeunload", () => {
            if (invoiceWindow) {
              invoiceWindow.close();
            }
          });

          // Track window focus and close if minimized
          const checkWindowFocus = setInterval(() => {
            if (invoiceWindow && invoiceWindow.closed) {
              clearInterval(checkWindowFocus);
            } else if (invoiceWindow && !invoiceWindow.document.hasFocus()) {
              invoiceWindow.close();
              clearInterval(checkWindowFocus);
            }
          }, 1000);

        } else {
          dispatch(ActionServerData("NormalOrderInvoice", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("NormalOrderInvoice", false)));
  };
}

export function ActionGetFeedbackForm() {
  return (dispatch) => {
    window.open('feedback', 'ActionWin', 'width=900,height=780,toolbar=0');
  };
}

export function ActionDownloadRenewedOrderInvoice(params) {
  return (dispatch) => {
    if (invoiceWindow && !invoiceWindow.closed) {
      alert("The invoice window is already open.");
      // return;
    }
    dispatch(ActionLoadingUpdate("RenwedOrderInvoice", true));
    ServerData.getRenwedOrderInvoice(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("RenwedOrderInvoice", res.data));
          invoiceWindow = window.open('RenwedOrderInvoice', 'myActionWin', 'width=500,height=300,toolbar=0');
        
          invoiceWindow.blur();

          // Close the invoice window if the user refreshes the page
          window.addEventListener("beforeunload", () => {
            if (invoiceWindow) {
              invoiceWindow.close();
            }
          });

          // Track window focus and close if minimized
          const checkWindowFocus = setInterval(() => {
            if (invoiceWindow && invoiceWindow.closed) {
              clearInterval(checkWindowFocus);
            } else if (invoiceWindow && !invoiceWindow.document.hasFocus()) {
              invoiceWindow.close();
              clearInterval(checkWindowFocus);
            }
          }, 1000);
        } else {
          dispatch(ActionServerData("RenwedOrderInvoice", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("RenwedOrderInvoice", false)));
  };
}


export function ActionUserUpdatePassword(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("updatePassword", true));
    ServerData.userUpdatePassword(params)
      .then((res) => {
        if (res.success === true) {
          // ActionOpenNotification("success", "updatePassword", res.message);
          dispatch(ActionServerData("updatePassword", res))
        } else {
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("updatePassword", false)));
  };
}

export function ActionValiadteUserOldPassword(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("validateOldPassord", true));
    ServerData.validateusersOldPassword(params)
      .then((res) => {
        if (res.success === true) {
          // ActionOpenNotification("success", "validateOldPassord", res.message);
          dispatch(ActionServerData("validateOldPassord", res))
        } else {
          // ActionOpenNotification("info", "failed", res.message);
          dispatch(ActionServerData("validateOldPassord", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("validateOldPassord", false)));
  };
}

export function ActionDownloadGiftedOrderInvoice(params) {
  return (dispatch) => {
    if (invoiceWindow && !invoiceWindow.closed) {
      alert("The invoice window is already open.");
      // return;
    }
    dispatch(ActionLoadingUpdate("GiftedOrderInvoice", true));
    ServerData.getGiftedOrderInvoice(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("GiftedOrderInvoice", res.data));
          window.open('GiftedOrderInvoice', 'myActionWin', 'width=500,height=300,toolbar=0');
        
          invoiceWindow = window.open('GiftedOrderInvoice', 'myActionWin', 'width=500,height=300,toolbar=0');
        
          invoiceWindow.blur();

          // Close the invoice window if the user refreshes the page
          window.addEventListener("beforeunload", () => {
            if (invoiceWindow) {
              invoiceWindow.close();
            }
          });

          // Track window focus and close if minimized
          const checkWindowFocus = setInterval(() => {
            if (invoiceWindow && invoiceWindow.closed) {
              clearInterval(checkWindowFocus);
            } else if (invoiceWindow && !invoiceWindow.document.hasFocus()) {
              invoiceWindow.close();
              clearInterval(checkWindowFocus);
            }
          }, 1000);
        } else {
          dispatch(ActionServerData("GiftedOrderInvoice", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("GiftedOrderInvoice", false)));
  };
}

export function ActiongetUserToken(userToken) {
  return (dispatch) => {
    dispatch(ActionServerData("Usertoken", userToken));
  };
}

export function ActiongetLogedUser(User) {
  return (dispatch) => {
    dispatch(ActionServerData("userDataLogggedIn", User));
  };
}

export function ActiongetIntiallcourseDetails($data) {
  return (dispatch) => {
    dispatch(ActionServerData("IntiallcourseDetails", $data));
  };
}



// export function ActiongetMediaBlogData($data) {
//   return (dispatch) => {
//     dispatch(ActionServerData("mediaAndBlogs", $data));
//   };
// }

export function ActionGetMediaAndBlogs() {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("mediaAndBlogs", true));
    ServerData.getMyMediaBlogs()
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("mediaAndBlogs", res.data));
          // dispatch(ActiongetMediaBlogData(res.data));
        } else {
          dispatch(ActionServerData("mediaAndBlogs", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("mediaAndBlogs", false)));

  };
}

export function ActionGetFeedback(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("getFeedback", true));
    ServerData.getFeedback(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("getFeedback", res.data));
        } else {
          dispatch(ActionServerData("getFeedback", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("getFeedback", false)));

  };
}

export function ActionPostFeedback(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("postFeedback", true));
    ServerData.postFeedback(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("postFeedback", res.data));
          ActionOpenNotification("success", "Feedback sent successfully", res.message);
          // dispatch(ActionRouteNavigate("myCoursePage", res));
        } else {
          dispatch(ActionServerData("postFeedback", res.data));
          ActionOpenNotification('info', 'failed', res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("postFeedback", false)));

  };
}

export function ActionUploadUserProfile(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("userprofileUpload", true))
    ServerData.userprofileUpload(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("userprofileUpload", res))
          // dispatch(ActionRouteNavigate("useraccount", res))
        } else {
          dispatch(ActionServerData("userprofileUpload", res))
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("userprofileUpload", false)))
  }
}


export function ActiongetCourseVideos(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("isWatchLoading", true));
    ServerData.getCourseVideos(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("watchVideos", res.data));
          // dispatch(ActionRouteNavigate('watchVideos', res.data))
        } else {
          console.log(res);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("isWatchLoading", false)));
  };
}

export function ActionsaveContactus(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("contactUs", true));
    ServerData.saveContactus(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionRouteNavigate("contact-Us", res.data));
          ActionOpenNotification("success", "success", res.message);
          if (res.message) {
            setTimeout(() => {
              window.location.reload()
            }, 2000);
          }
        } else {
          dispatch(ActionRouteNavigate("contact-Us", res.data));
          ActionOpenNotification("info", "failed", res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(ActionLoadingUpdate("contactUs", false));
      });
  };
}

export function ActionIntiallpage(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("home", true));
    ServerData.home(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("home", res.data));
          // dispatch(ActionRouteNavigate("home", res.data));
        } else {
          dispatch(ActionServerData("home", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("home", false)));
  };
}

export function ActionUpdateCourseSession(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("updatesession", true));
    ServerData.updatesession(params)
      .then((res) => {
        if (res.success == true) {
          dispatch(ActionServerData("updatesession", res.data));
        } else {
          dispatch(ActionServerData("updatesession", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("updatesession", false)));
  };
}


export function ActionUpdateCourseSubLectureTestmonialSession(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("SubLectureTestmonialSession", true));
    ServerData.UpdateSubLectureTestmonialSession(params)
      .then((res) => {
        if (res.success == true) {
          console.log('params actions ---------', params);
          dispatch(ActionServerData("SubLectureTestmonialSession", res.data));
          // dispatch(ActionRouteNavigate({pathname: 'watchVideos', state: { testMonialId: params.testMonialID }}));
        } else {
          dispatch(ActionServerData("SubLectureTestmonialSession", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("SubLectureTestmonialSession", false)));
  };
}


export function ActionUpdateLectureTestmonialSession(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("SubLectureTestmonialSession", true));
    ServerData.UpdateSubLectureTestmonialSession(params)
      .then((res) => {
        if (res.success == true) {
          dispatch(ActionServerData("SubLectureTestmonialSession", res.data));
        } else {
          dispatch(ActionServerData("SubLectureTestmonialSession", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("SubLectureTestmonialSession", false)));
  };
}

export function ActionUpdateNextAssesmentAttemptstatus(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("nextassessmentstatusattempt", true));
    ServerData.Nextassessementattemptstatus(params)
      .then((res) => {
        if (res.success == true) {
          dispatch(ActionServerData("nextassessmentstatusattempt", res.data));
        } else {
          dispatch(ActionServerData("nextassessmentstatusattempt", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("nextassessmentstatusattempt", false)));
  };
}


export function ActionCheckvalidSession(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("validsession", true));
    ServerData.validsession(params)
      .then((res) => {
        if (res.success == true) {
          dispatch(ActionServerData("validsession", res.data));
        } else {
          dispatch(ActionServerData("validsession", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("validsession", false)));
  };
}



export function ActiongetCourseProgressofCurrentOrder(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("getCourseProgress", true));
    ServerData.getCourseProgress(params)
      .then((res) => {
        if (res.success == true) {
          dispatch(ActionServerData("getCourseProgress", res.data));
        } else {
          dispatch(ActionServerData("getCourseProgress", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("getCourseProgress", false)));
  };
}

export function ActionUpdateCourseProgressCurrentOrder(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("updateCourseProgress", true));
    ServerData.updateCourseProgress(params)
      .then((res) => {
        if (res.success == true) {
          dispatch(ActionServerData("updateCourseProgress", res.data));
        } else {
          dispatch(ActionServerData("updateCourseProgress", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("updateCourseProgress", false)));
  };
}

export function ActionUpdateTestmonialVideos(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("updateTestMonial", true));
    ServerData.updateTestMonialVideos(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("updateTestMonial", res.data));
        } else {
          dispatch(ActionServerData("updateTestMonial", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("updateTestMonial", false)));
  };
}


export function ActiongetCurrentActiveOrderDetails(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("CurrentOrder", true));
    ServerData.getCurrentOrderDetails(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("CurrentOrder", res.data));
        } else {
          dispatch(ActionServerData("CurrentOrder", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("CurrentOrder", false)));
  };
}

export function ActionRefundCancellation(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("refundCancel", true));
    ServerData.refund(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("refundCancel", res.data));
        } else {
          dispatch(ActionServerData("refundCancel", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("refundCancel", false)));
  };
}

export function ActionPrivacyPolicy(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("privacyPolicy", true));
    ServerData.privacyPolicy(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("privacyPolicy", res.data));
        } else {
          dispatch(ActionServerData("privacyPolicy", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("privacyPolicy", false)));
  };
}

export function ActionTermsAndConditions(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("termsCondition", true));
    ServerData.termsCondition(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("termsCondition", res.data));
        } else {
          dispatch(ActionServerData("termsCondition", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("termsCondition", false)));
  };
}
export function ActionVerifyCheckEmail(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("checkEmail", true));
    
    // Return the promise so we can handle it in the component
    return ServerData.checkEmail(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("checkEmail", res.data));
        } else {
          dispatch(ActionServerData("checkEmail", res.data));
          // Handle notification or any other case
        }
        return res; // Return the response so it can be used in the `.then()`
      })
      .catch((err) => {
        console.log(err);
        throw err; // Re-throw the error so it can be caught in the component
      })
      .finally(() => {
        dispatch(ActionLoadingUpdate("checkEmail", false));
      });
  };
}


export const setAssessmentIconVisibility = (visibility) => ({
  type: SET_ASSESSMENT_ICON_VISIBILITY,
  payload: visibility
});