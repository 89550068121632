import React, { useState, useEffect } from 'react';
import { Modal, Accordion } from 'react-bootstrap';
import './DownladAssesmentAnswers.css';
import { ActionAssessmentDownload } from '../../store/actions/actions-server-data';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function DownladAssesmentAnswers() {
    const [content, setContent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedModule, setSelectedModule] = useState(null);
    const dispatch = useDispatch();

    const userState = useSelector(state => state);
    const assessmentData = userState.rServerData?.AssessmentDownload || [];
    const isLoading = userState.rLoading?.AssessmentDownload || false;
    const userAccessToken = userState.rServerData.Usertoken || null;
    const { orders } = userState.rServerData.IntiallcourseDetails;
    const userDetails = userState.rServerData.userDataLogggedIn || [];
    useEffect(() => {
        dispatch(ActionAssessmentDownload({ token: userAccessToken, orderid: orders[0].orderid, userId: userDetails.id }));
    }, []);

    // const fetchData = async () => {
    //     try {
    //         dispatch(ActionAssessmentDownload({ token: userAccessToken, orderid: orders[0].orderid, userId: userDetails.id }));
    //         setContent(assessmentData);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    const handleShowModal = (moduleNumber) => {
        setSelectedModule(moduleNumber);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getModuleStatus = (moduleNumber) => {
        if (!assessmentData) return 'Yet to Start';

        const { sessionId, assessmentlecture } = assessmentData?.CourseProgress[0];

        if (sessionId === '' && assessmentlecture === '' && moduleNumber === 1) {
            return 'Not Yet Started';
        } else if (sessionId > moduleNumber || assessmentlecture === 55) {
            return 'Completed';
        } else if (sessionId == moduleNumber) {
            return assessmentlecture === 5 ? 'Yet to Start' : 'In Progress';
        }
        return 'Yet to Start';
    };

    if (!assessmentData) return <div>Loading...</div>;

    const handleDownload = (assessment,url) => {
        if (!url) {
            console.error('No URL provided for download');
            return;
        }
    
        // Fetch the PDF file
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                // Create a Blob URL for the file
                const blobUrl = window.URL.createObjectURL(blob);
    
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = blobUrl;
                
                // Extract filename from the URL or use a default name
                const filename = `Unit-${assessment.lecture_id}_answers.pdf`;
                link.download = filename;
                
                // Append to the body
                document.body.appendChild(link);
                
                // Programmatically click the link to start the download
                link.click();
                
                // Remove the link from the body
                document.body.removeChild(link);
    
                // Revoke the Blob URL to free up resources
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    };
    return (
        <>
            {isLoading &&
                <div className="payment_overlay">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#fff' }} />
                        <p className='mt-3 payment_loading' >Please wait...</p>
                    </div>
                </div>
            }
            <div className="module-container">
                {assessmentData?.assementdata?.reduce((acc, assessment) => {
                    if (!acc.find(item => item.session_id === assessment.session_id)) {
                        acc.push({
                            session_id: assessment.session_id,
                            module_name: `MODULE - ${assessment.session_id}`,
                            status: getModuleStatus(assessment.session_id),
                            moduleImage: assessment?.session?.video_image,
                            sessionName: assessment?.session?.session_name
                        });
                    }
                    return acc;
                }, []).map((module, index) => (
                    <div key={module.session_id} className="module-card" onClick={() => handleShowModal(module.session_id)}>
                        <div className="module-image">
                            <img src={module.moduleImage} alt="img" width='100%' height='100%' style={{ borderRadius: '10px' }} />
                        </div>
                        <div className="module-content">
                            <div>
                                <h2 className="module-title">{module.module_name}</h2>
                                <h3 className="module-subtitle">{module.sessionName}</h3>
                            </div>
                            <p className="card-text uppercase small">Status | {module.status}</p>
                        </div>
                    </div>
                ))}

                <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="ModalHeading">Download Answer Sheet - Module {selectedModule}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Accordion>
                            {(() => {
                                let assessmentCounter = 0;
                                return assessmentData && assessmentData?.assementdata?.filter(assessment => assessment.session_id === selectedModule).map((assessment, index) => {
                                    assessmentCounter++;
                                    const isCompleted = assessmentData?.CourseProgress[0]?.assessmentlecture >= assessment.lecture_id;
                                    return (
                                        <Accordion.Item eventKey={index.toString()} key={index}>
                                            <div className={`assessment-item ${!isCompleted ? 'disabled' : ''}`}>
                                                <div className={`assessment-item ${!isCompleted ? 'disabled' : 'assessment-content assessmentDiv'}`} >
                                                    <div className="assessment-info">
                                                        <i className={`fa questionCircle ${isCompleted ? 'fa-question-circle' : 'fa-lock disabled'}`} aria-hidden="true"></i>
                                                        <span>
                                                            Quiz - {assessmentCounter}  Unit - {assessment?.lecture?.id} {assessment?.lecture?.lecture_name} - ({assessment.numberQuestions} Questions)
                                                        </span>
                                                    </div>
                                                    <div className={`downloadBtn ${!isCompleted ? 'disabled' : ''}`}>
                                                        {isCompleted ? (
                                                            // <a href={assessment.answer_pdf} target="_blank" rel="noopener noreferrer">
                                                            <div onClick={() => handleDownload(assessment, assessment?.answer_pdf)} style={{cursor: 'pointer'}}>
                                                                <i className={`fa-solid fa-download ${!isCompleted ? 'disabled' : "fa-solid fa-download"}`}></i>
                                                                <span className={`download-text ${!isCompleted ? 'disabled' : "download-text"}`}>Download</span>
                                                            </div>
                                                            // </a>
                                                        ) : (
                                                            <div>
                                                                <i className={`fa-solid fa-download ${!isCompleted ? 'disabled' : "fa-solid fa-download"}`}></i>
                                                                <span className={`download-text ${!isCompleted ? 'disabled' : "download-text"}`}>Download</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Item>
                                    );
                                });
                            })()}
                        </Accordion>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default DownladAssesmentAnswers;