import React, { useState } from "react";
import { ErrorMessage } from "formik";
import { Input } from "antd";
// import cl from "classnames";
// import Svg from "../svg";

import "./InputStyle.less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { colors } from "react-select/lib/theme";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const InputText = (props) => {
  const {
    id,
    value,
    name,
    type,
    onChange,
    icon,
    onBlur,
    label,
    err,
    disabled,
    maxLength,
    minLength,
    placeholder,
    onKeyDown
  } = props;

  const [updateType, setUpdatedType] = useState(props.type);
  const [eyeColor, setEyeColor] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const passwordViewHandler = () => {
    if (updateType === "text") {
      setUpdatedType("Password");
      setEyeColor(false);
    } else {
      setUpdatedType("text");
      setEyeColor(true);
    }
  };

  return (
    <div className="input-email position-relative">
      {label !== undefined ? <label htmlFor={name}> {label} </label> : ""}
      <Input
        id={id && id}
        min={0}
        name={name}
        disabled={disabled}
        type={updateType || ""}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="off"
        // className={cl((err) ? 'error_input' : '')}
        style={{ padding: icon !== undefined ? "0 0 0 35px" : "10px" }}
        maxLength={maxLength}
        minLength={minLength}
        onKeyDown={onKeyDown}
      />

      {type === "password" && (
        <span
          className="password-toggle eye-icon"
          onClick={() => passwordViewHandler()}
          style={{ cursor: 'pointer' }}
          id={eyeColor ? "eye-color" : ""}
        >
          <FontAwesomeIcon icon={eyeColor ? faEyeSlash : faEye} />
        </span>
      )}

      
      <ErrorMessage name={name}>
        {(errorMessage) => {
          return <p className="error_message pt-2">{errorMessage}</p>;
        }}
      </ErrorMessage>
    </div>
  );
};

export default InputText;
