import { ErrorMessage, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import InputText from '../../components/Form/InputText';
import './ResetPassword.css';
import { restPasswordFormValidationSchema } from '../../utils/utils-validation';
import { useDispatch, useSelector } from 'react-redux';
import { ActionResetPassword } from '../../store/actions/actions-user';
import EmailInputField from '../../components/Form/EmailInputField';
import { ActionVerifyGeneretedCode } from '../../store/actions/actions-server-data';
import { ActionRouteNavigate } from '../../store/actions/actions-route';
import { ActionOpenNotification } from '../../store/actions/actions-utils-data';

function ResetPassword() {
    const dispatch = useDispatch();
    const MyState = useSelector(state => state);
    const isLoading = MyState.rLoading ? MyState.rLoading.resetPassword : null;
    const userEmail = MyState.router?.location?.state?.params.email || null;
    const genPassword = MyState.router?.location?.state?.res?.oldpassword || null;
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (userEmail == null || userEmail == undefined) {
            dispatch(ActionRouteNavigate("forgotPassword"));
        }
    })

    const handleReset = (values) => {
       
        if(values.gen_password.trim() == values.new_password.trim()){
            ActionOpenNotification("info", "failed", "The generated password and new password should be different.")
        }else{
            if (values.gen_password == genPassword) {
                const param = {
                    email: values.email,
                    password: values.gen_password,
                    password: values.new_password,
                    confirm_password: values.new_password
                }
                dispatch(ActionResetPassword(param))
            } else {
                ActionOpenNotification("info", "failed", "Please check generated password");
                setIsDisabled(true);
                setTimeout(() => {
                    setIsDisabled(false);
                }, 2500);
            }
        }
    }

    const handleBlur = (e, values) => {
        const { name, value } = e.target;
        if (name === "gen_password" && value.trim() !== "") {
            const obj = {
                email: userEmail,
                otp: value,
            };
            if (values.gen_password.length > 0) {
                dispatch(ActionVerifyGeneretedCode(obj));
            }
        }
    };

    return (
        <Container fluid>
            <Container>
                <div className='mb-4'>
                    <h1 className='resetHead'>Welcome</h1>
                </div>
                <Row>
                    <Formik
                        initialValues={{ email: userEmail, gen_password: '', new_password: '' }}
                        enableReinitialize
                        validationSchema={restPasswordFormValidationSchema}
                        onSubmit={(values) => handleReset(values)}
                        render={({ values, handleChange }) => (
                            <Form className="w-100">
                                <Row>
                                    <Col md={4} className="text-white text-end mb-3 mt-2" id="f-26">
                                        E-mail Id
                                    </Col>
                                    <Col md={7} className="position-relative login-input" id="resetInpBox">
                                        <EmailInputField
                                            name="email"
                                            type="email"
                                            value={userEmail}
                                            onChange={handleChange}
                                            disabled
                                            className="inpText" id='resetInpEmail'
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="mt-4 text-end text-white mb-3" id="f-26">
                                        Generated Password
                                    </Col>
                                    <Col md={7} className="login-input mt-3">
                                        <InputText
                                            name="gen_password"
                                            type="password"
                                            value={values.gen_password}
                                            onChange={handleChange}
                                            onBlur={(e) => handleBlur(e, values)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="mt-4 text-end text-white mb-3" id="f-26">
                                        New Password
                                    </Col>
                                    <Col md={7} className="login-input mt-3">
                                        <InputText
                                            name="new_password"
                                            type="password"
                                            value={values.new_password}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <div className='mt-3' style={{ marginLeft: '205px' }}>
                                    <Button className='nextBtn' type="submit" id={isDisabled ? 'bg' : 'resetBtn'} disabled={isDisabled}>
                                        Submit {isLoading && <span className='px-2'><i className="fa fa-spinner fa-spin"></i></span>}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    />
                </Row>
            </Container>
        </Container>
    );
}

export default ResetPassword;
