import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Container } from "react-bootstrap";
import about_banner from "../../Assets/images/pages/about-us/who-we-are.png";
import InputText from "../../components/Form/InputText";
import "./ContactUs.css";
import { contactUsValidationSchema } from "../../utils/utils-validation";
import { useDispatch, useSelector } from "react-redux";
import { ActionsaveContactus } from "../../store/actions/actions-server-data";

const ContactUs = () => {
    const [recaptchaError, setRecaptchaError] = useState("")
  const state = useSelector((state)=>state);
  const isLoading = state.rLoading?.contactUs;


  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
 
  const handleSubmit = (values) => {
    const response = window.grecaptcha.getResponse();
    if (response === '') {
        setRecaptchaError("Google recaptcha not verified.Try again")
    }else{
        const param = {
            name:values.name,
            email:values.email,    
            phone:values.phone,
            message:values.message
        }        
        dispatch(ActionsaveContactus(param));
    }
  };



  return (
    <div>
      <Container className="p-5">
        <div className="row p-3">
          <div className="col-md-7 contactBanner">
            <div className="text-center">
              <img src={about_banner} alt="img" />
            </div>
          </div>
          <div className="col-md-4">
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                message: "",
              }}
              validationSchema={contactUsValidationSchema}
              onSubmit={ handleSubmit}
              enableReinitialize>
              {({ values, handleChange, handleBlur,handleSubmit }) => (
                <Form className="w-100 contactForm" onSubmit={handleSubmit}>
                  <div className="mb-2" id="f-26">
                    Your Name
                  </div>
                  <div className="position-relative login-input mb-2 w-100">
                    <InputText
                      name="name"
                      type="text"
                      placeholder="Enter your name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="mb-2" id="f-26">
                    E-mail Address
                  </div>
                  <div className="position-relative login-input mb-2 w-100">
                    <InputText
                      name="email"
                      placeholder="Enter your Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="mb-2" id="f-26">
                    Phone Number
                  </div>
                  <div className="login-input mb-2 w-100">
                    <InputText
                      name="phone"
                      type="phone"
                      placeholder="Enter your phone number"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="mb-2" id="f-26">
                    Message
                  </div>
                  <div className="login-input mb-2 w-100">
                    <textarea
                      name="message"
                      cols={60}
                      rows={3}
                      className="p-2"
                      placeholder="Enter your message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ borderColor: "#d9d9d9" }}
                    />
                  </div>
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="error-message text-danger"
                  />

                  <br />
                  <div className="form-group">
                    <div
                      className="text-center g-recaptcha m-auto display-block"
                      data-sitekey="6Ldf-OcnAAAAAJQMdFJGE_TfQk72tUhpMHFG7Kgh"
                    ></div>
                  </div>
                  <div id="recapth" className="error-message text-danger">{recaptchaError}</div>          
                  <br />

                  <div className="w-100 mt-2 d-flex">
                    <button 
                      className="contactBtn btn btn" 
                      type="submit" 
                      disabled={isLoading}
                    >
                      Submit Now {isLoading && <span className='px-2'><i className="fa fa-spinner fa-spin" id="ContactUsBtn"></i></span>}
                    </button>
                  </div>                 
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;