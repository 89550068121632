// Validations.js

export const validateEmail = (email, verifyEmailCheck) => {
    if (!email.trim()) {
        return 'Please enter your email';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        return 'Please enter a valid email address';
    } else if (Array.isArray(verifyEmailCheck) && verifyEmailCheck.length > 0 && verifyEmailCheck[0]?.email === email) {
        return 'This email is already registered';
    }
    return '';
};

const validatePassword = (password) => {
    if (!password) {
        return 'Please enter your password';
    } else if (password.length < 8) {
        return 'Password must have minimum 8 characters';
    } else if (password.trim() !== password) {
        return 'Password cannot start or end with a space';
    } else if (password.includes(' ')) {
        return 'Password cannot contain spaces in the middle';
    }
    return '';
};

const validateConfirmPassword = (password, confirmPassword) => {
    if (!confirmPassword) {
        return 'Please enter your confirm password';
    } else if (password !== confirmPassword) {
        return 'Confirm Password and Password must be same';
    }
    return '';
};

const validateDOB = (dob) => {
    if (!dob) {
        return 'Please enter your date of birth';
    }
    const selectedDOB = new Date(dob);
    const currentDate = new Date();
    const minDOB = new Date(
        currentDate.getFullYear() - 13,
        currentDate.getMonth(),
        currentDate.getDate()
    );
    
    if (selectedDOB > currentDate) {
        return 'Date of birth is not valid. Future dates are not allowed.';
    }
    
    if (selectedDOB > minDOB) {
        return 'You must be at least 13 years old';
    }
    
    return '';
};

export const validation = (step, setStep, formData, Country, State, setErrors, verifyEmailCheck) => {    
    let newErrors = {};
    let isValid = true;

    if (step === 1) {
        // First Name
        if (!formData.firstname || formData.firstname.trim() === '') {
            newErrors.firstname = 'Please enter your first name';
            isValid = false;
        }

        // Last Name
        if (!formData.lastname || formData.lastname.trim() === '') {
            newErrors.lastname = 'Please enter your last name';
            isValid = false;
        }

        // Email
        const emailError = validateEmail(formData.email, verifyEmailCheck);
        if (emailError) {
            newErrors.email = emailError;
            isValid = false;
        }

        // Date of Birth
        const dobError = validateDOB(formData.dob);
        if (dobError) {
            newErrors.dob = dobError;
            isValid = false;
        }

        // Contact Number
        if (!formData.contact_number || formData.contact_number.trim() === '') {
            newErrors.contact_number = 'Please enter your contact number';
            isValid = false;
        }

        // Password
        const passwordError = validatePassword(formData.password);
        if (passwordError) {
            newErrors.password = passwordError;
            isValid = false;
        }

        // Confirm Password
        const confirmPasswordError = validateConfirmPassword(formData.password, formData.confirm_password);
        if (confirmPasswordError) {
            newErrors.confirm_password = confirmPasswordError;
            isValid = false;
        }
    } else if (step === 2) {
        // Address
        if (!formData.address || formData.address.trim() === '') {
            newErrors.address = 'Please enter your address';
            isValid = false;
        }

        // Country
        if (!Country) {
            newErrors.Country = 'Please select your country';
            isValid = false;
        }

        // State
        if (!State) {
            newErrors.State = 'Please select your state';
            isValid = false;
        }

        // City
        if (!formData.city || formData.city.trim() === '') {
            newErrors.city = 'Please enter your city';
            isValid = false;
        }

        // Zipcode
        if (!formData.zipcode || formData.zipcode.trim() === '') {
            newErrors.zipcode = 'Please enter your ZIP code';
            isValid = false;
        }
    }

    setErrors(prevErrors => ({
        ...prevErrors,
        ...newErrors
    }));

    return isValid;
};