import ACTIONS from "../actions-names/index";

import {

  NotificationManager,
} from "react-notifications";
const { UTILS_ACTIONS } = ACTIONS;

export const ActionOpenNotification = (type, messages, description) => {
  if (messages === "failed") {
    NotificationManager.error(description,'',2500);
  } else {
    NotificationManager.success(description);
  }
};

export function ActionUtilsData(ns, payload) {
  const data = {};
  data[ns] = payload;
  return {
    type: UTILS_ACTIONS.UPDATE_DATA,
    data,
  };
}

export function ActionClearUtils() {
  return {
    type: UTILS_ACTIONS.CLEAR,
  };
}




