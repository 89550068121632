/* eslint-disable indent */
import { SERVER_ACTIONS } from "../actions-names/server";
import { SET_ASSESSMENT_ICON_VISIBILITY } from "../actions/actions-server-data";

const serverdataReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVER_ACTIONS.UPDATE_DATA: {
      if (action.key) {
        return {
          ...state,
          [action.key]: action.data,
        };
      }

      return {
        ...state,
        ...action.data,
      };
    }

    case SERVER_ACTIONS.CLEAR: {
      return {}
    }

    case SET_ASSESSMENT_ICON_VISIBILITY:
      return {
        ...state,
        assessmentIconVisible: action.payload
      };

    default:
      return state;
  }
};
export default serverdataReducer;
