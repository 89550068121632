/* eslint-disable no-undef */
/* globals: window */

import moment from 'moment';
import _ from 'lodash';
import { getState } from '../store/store'
import { ROLES } from '../configs';

export class UtilsHelper {
  static serialize(obj, prefix = false) {
    const str = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const p in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? `${prefix}[${p}]` : p;
        const v = obj[p];
        str.push((v !== null && typeof v === 'object')
          ? UtilsHelper.serialize(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
      }
    }
    return str.join('&');
  }

  static clearPhoneNumber(number) {
    return number.replace(/\D/gi, '');
  }

  static randomStr(length) {
    let i = 0;
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (i; i < length; i += 1) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  static random = length => Math.round(Math.random() * (10 ** length));

  static getWeekRangeByDate(date) {
    // const weekNumber = moment(date)
    //   .isoWeek();
    // const yearNumber = moment(date)
    //   .format('YYYY');

    return UtilsHelper.getWeekRangeByWeekAndYear(weekNumber, yearNumber);
  }

  static getWeekRangeByWeekAndYear(weekNumber, yearNumber) {
    // const startDate = moment()
    //   .year(yearNumber)
    //   .isoWeek(weekNumber)
    //   .startOf('isoweek');
    // const endDate = moment()
    //   .year(yearNumber)
    //   .isoWeek(weekNumber)
    //   .endOf('isoweek');

    return {
      startDate,
      endDate,
    };
  }

  static numberWithCommas(x) {
    const parts = x.toString()
      .split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  static trimSlashes = url => url && url.replace(/^\/|\/$/g, '');

  static normalizeDividedNumber = (number) => {
    return number - Math.round(number) < 0.015 ? Math.round(number) : number;
  };

  static round = (number, roundTo = 100) => Math.round(number * roundTo) / roundTo;

  static isNumeric = value => !Number.isNaN(value - parseFloat(value));

  static reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  static getNum = (val) => {
    if (Number.isNaN(val) || val === undefined) {
      return 0;
    }
    return parseFloat(val);
  };

  static removeEmptyFields = (object) => {
    return Object.entries(object)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, value]) => {
        if (_.isArray(value)) {
          return !_.isEmpty(value);
        }

        return !!value || value === 0;
      })
      .reduce((acc, [key, value]) => {
        acc[key] = value;

        return acc;
      }, {});
  };

  static normalizeImageUrl = (url, options = { size: 'sm' }) => {

    if (!url) return './images/required/placeholder-product.jpg';
    let size = '&w=320';

    switch (options.size) {
      case 'sm':
        size = '&w=320';
        break;
      case 'xs':
        size = '&h=50';
        break;
      default:
        size = '&w=320';
    }

    return _.first(url) + size;
  };

  static milesToKilometers = (miles) => {
    return parseInt(miles) * 1.60934;
  }

  static convertObjToArray = (obj) => {
    let arr = [];
  
    _.forEach(obj, function (value, key) {
      let keyString = key.replace(/([a-z])([A-Z])/g, '$1 $2');
      arr.push({ key: keyString, value });
    });
  
    return arr;
  };
  

  static _getListofRecord(arr, values) {

    let finalArr = []

    _.map(values, obj => {
      const o = _.filter(arr, arrObj => {
        return arrObj.id === obj.courseId;
      })
      if (o.length > 0) {
        finalArr.push(o[0].name)
      }
    })

    return finalArr
  }

  static getDateDiff(d1, d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    const diffTime = (date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  static getScheduleCredit(d1, d2) {
    let credit = 0;
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    const diffTime = (date2 - date1);

    const diffWeek = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    credit = (Math.floor(diffWeek / 7) * 0.5)

    return credit
  }

  static numberToStringMap(num) {
    var str = num.toString();
    var len = str.length;
    var val = "";
    if (len >= 4 && len <= 6)
      val = str.substr(0, len - 3) + "k"
    else if (len >= 7 && len <= 9)
      val = str.substr(0, len - 6) + (str[1] === "0" ? "" : "." + str[1]) + (str[2] === "0" ? "" : "" + str[2]) + "M"
    else if (len <= 3)
      val = str;

    return val;
  }

  static getCustomNumberInDropDown(start, end) {
    let dateArray = [];
    for (let index = start; index <= end; index++) {
      dateArray.push({ value: index, label: index })
    }
    return dateArray;
  }

  static getMaxLimit(ar) {
    let max = 0

    for (let i = 0; i < ar.length; i++) {
      if (ar[i][1] > max)
        max = ar[i][1]
    }

    let str = "" + max, rem = 1
    let FirstIndex = (parseInt(str[0]) + 1);

    for (let i = 1; i < str.length; i++)
      rem *= 10;

    return FirstIndex * rem
  }

 

  static randomColor() {
    const colors = ['#5D2905', '#89A421', '#206C3A', '#179ED4', '#1C05E0', '#2EA23A', '#3E695B', '#CCBF66', '#123E34', '#F7D113', '#0DB9BD']
    return colors[Math.floor(Math.random() * 11)];
  }

  static managerAssigned(Id, department) {
    const managerAssigned = []
    const Manager = _.filter(department, (obj) => {
      return obj.Id === Id
    });
    _.map(Manager, obj => managerAssigned.push({ "value": obj.Id, "label": obj.Manager, "SignerId": obj.SignerId, "SignerRole": "IAE4TQ" }))

    return managerAssigned
  }

  static getRequestType(obj) {
    const { RequestData } = JSON.parse(obj.Request ? obj.Request : obj.RequestDetails)
    const WorkflowReqName = obj.WorkflowReqName ? obj.WorkflowReqName : obj.Workflow
    const WorkflowReqCode = obj.WorkflowReqCode ? obj.WorkflowReqCode : obj.WorkflowCode
    if (WorkflowReqCode === "OOHS") {
      if (RequestData.OOHSDetails.length > 0) {
        return `${WorkflowReqName} (Schedule)`
      } else if (RequestData.WFHOOHSDetails.length > 0) {
        return `${WorkflowReqName} (Work From Home)`
      } else if (RequestData.UnSceduleOOHSDetails.length > 0) {
        return `${WorkflowReqName} (Unschedule)`
      }
    }
    else {
      if (WorkflowReqCode === "CANCLV") {
        if (RequestData.CancelLeaveDetails.OOHSDetails?.length > 0) {
          return `${WorkflowReqName} (Schedule)`
        } else if (RequestData.CancelLeaveDetails.WFHOOHSDetails?.length > 0) {
          return `${WorkflowReqName} (Work From Home)`
        } else if (RequestData.CancelLeaveDetails.UnSceduleOOHSDetails?.length > 0) {
          return `${WorkflowReqName} (Unschedule)`
        }
        if (RequestData.CancelLeaveDetails.leaveTypeCode === "1")
          return `${WorkflowReqName} (Sick)`
        if (RequestData.CancelLeaveDetails.leaveTypeCode === "2")
          return `${WorkflowReqName} (Casual)`
        if (RequestData.CancelLeaveDetails.leaveTypeCode === "3")
          return `${WorkflowReqName} (Privilege)`
      }
      if (WorkflowReqCode === "LVMGT") {
        if (RequestData.LeaveManagementDetails.TypeCode) {
          if (RequestData.LeaveManagementDetails.TypeCode === 1)
            return `${WorkflowReqName} (Sick)`
          if (RequestData.LeaveManagementDetails.TypeCode === 2)
            return `${WorkflowReqName} (Casual)`
          if (RequestData.LeaveManagementDetails.TypeCode === 3)
            return `${WorkflowReqName} (Privilege)`
        }
      }
      return WorkflowReqName;
    }
    return ""
  }

  // returns details between the date range
  static getDateDiffExcludingHolidays(d1, d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    let currentDate = date1,
      workingDates = [],
      weeksEnds = [],
      holyDays = [],
      totalWorkingDays = 0,
      totalSelectedDate = 0;
    const { rServerData } = getState();
    while (currentDate <= date2) {
      const isHoliday = rServerData.allPublicHoliday.filter(obj => new Date(obj.Datetime).toDateString() === new Date().toDateString()).length > 0;
      if (isHoliday.length !== 0) {
        _.map(isHoliday, item => holyDays.push(item))
      } else {
        var weekday = new Date(currentDate.valueOf());
        const dayIndex = weekday.getDay()
        if (dayIndex !== 0 && dayIndex !== 6) {
          totalWorkingDays += 1
          workingDates.push(currentDate);
        }
        else
          weeksEnds.push(currentDate);
      }
      totalSelectedDate++;
      var d = new Date(currentDate.valueOf());
      d.setDate(d.getDate() + 1);
      currentDate = d;
    }
    return {
      workingDates: workingDates,
      weeksEnds: weeksEnds,
      holyDays: holyDays,
      totalWorkingDays: totalWorkingDays,
      totalSelectedDate: totalSelectedDate
    }
  }

  static getRequestDate(key, record) {
    const { RequestData } = JSON.parse(
      record.RequestDetails || record.Request || '{}'
    );
  
    if (RequestData === undefined) {
      return '-';
    }
  
    const getFirstValidValue = (data, key) => {
      const keysIn = _.keysIn(data);
      let keyHaveArray = '';
  
      keysIn.forEach((keyIn) => {
        if (_.isArray(data[keyIn]) && data[keyIn].length > 0) {
          keyHaveArray = keyIn;
        }
      });
  
      if (keyHaveArray !== '') {
        const ary = data[keyHaveArray];
        const firstEle = ary[0];
        return firstEle.DOD || firstEle[key] || '-';
      } else {
        return '-';
      }
    };
  
    const getRequestDateHelper = () => {
      if (record.WorkflowCode === 'LVMGT' || record.WorkflowReqCode === 'LVMGT') {
        return RequestData.LeaveManagementDetails
          ? RequestData.LeaveManagementDetails[key]
          : '-';
      }
  
      if (record.WorkflowReqCode === 'CANCLV' || record.WorkflowCode === 'CANCLV') {
        if (RequestData.TypeCode === 7 || RequestData.TypeCode === 6 || RequestData.TypeCode === 8) {
          return getFirstValidValue(RequestData.CancelLeaveDetails, key);
        } else {
          return RequestData.CancelLeaveDetails ? RequestData.CancelLeaveDetails[key] : '-';
        }
      }
  
      return getFirstValidValue(RequestData, key);
    };
  
    return getRequestDateHelper();
  }
  

  static checkForAvailed(record) {
    const today = moment.utc().format('MM/DD/YYYY')
    const { RequestData } = JSON.parse(record.Request)
    if (record.WorkflowReqCode === "LVMGT" && record.RequestStatusCode === "CLSD") {
      if (RequestData.LeaveManagementDetails) {
        if (moment(today).isAfter(RequestData.LeaveManagementDetails.ToDate))
          return true
      }
    }
    return false
  }

  static checkForDate(data, session) {
    const { UserViewModel } = session;

    if (data) {
      const { RequestFromDate, RequestToDate } = data
      const responseObj = UtilsHelper.getDateDiffExcludingHolidays(RequestFromDate, RequestToDate)
      const { weeksEnds, holyDays } = responseObj;
      let message = `This Request has ${holyDays.length} Holidays and ${weeksEnds.length} Weekend Days\n`
      if (holyDays.length !== 0) {
        message += `\nHolidays:\n`
        _.map(holyDays, item => message += `${item.Name} ==> ${item.Datetime}\n`)
      }
      if (weeksEnds.length !== 0) {
        message += `\nWeekend Days:\n`
        _.map(weeksEnds, item => message += `${moment(item).format('dddd')} ${moment(item).format('MM/DD/YYYY')}\n`)
      }
      setTimeout(() => {
        if (UserViewModel.AccessGroupId !== ROLES.EMPLOYEE && UserViewModel.AccessGroupId !== ROLES.SECURITY)
          if (weeksEnds.length !== 0 || holyDays.length !== 0)
            alert(message)
      }, 1000);
    }
  }

}