import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css';
import { Carousel } from 'react-bootstrap';

function CarouselPage({ homeData }) {
    return (
        <Carousel interval={2000} prevIcon={null} nextIcon={null} controls={false} indicators={false}>
            {homeData.map((element, index) => (
                <Carousel.Item key={index} style={{height:'900px'}}>
                    <img className="d-block w-100" src={element.image} alt={`Slide ${index}`} />
                    <Carousel.Caption>
                        <div className="titleDiv">
                            <div className="carouselTitle">
                                <p>{element.title}</p>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}
export default CarouselPage;

