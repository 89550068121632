import React from 'react';
import logo from '../../Assets/images/logo.svg';
import "./Layout.css";

function Layout({ children, route }) {
    // Determine the class name based on the route
    const getClassName = () => {
        switch (route) {
            case 'login':
                return 'login-layout';
            case 'register':
                return 'register-layout';
            case 'forgotPassword':
                return 'forgotPassword-layout';
            default:
                return '';
        }
    };
    return (
        <div className={`d-flex ${getClassName()}`}>
            <div className="col-md-5 col-sm-12  login-content">
                <div className='mb-5' style={{width:'40%', height:'100px'}}>
                    <a href='/'><img src={logo} alt="Dr Garcia Logo" className="img-fluid login-logo mb-5" /></a>
                </div>
                <div className="layout" >{children}</div>
            </div>
            <div className='rightCont col-md-7 col-xs-12 col-sm-12 d-md-block d-xl-block float-left'>
                <div className="" id="rightPic"></div>
            </div>
        </div>
    )
}

export default Layout
