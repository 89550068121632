import http from "../services/http";
import $q from "q";
import { config } from "../configs";


import { getHeader } from "../store/store";

const { API_MAP, API_URL } = config;

export default class ServerData {

  //Post API
  static dummy(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .post(API_URL + API_MAP.dummy, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }

  static faq(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .get(API_URL + API_MAP.FAQ, param)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

 

  static mycourse(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.MYCOURSE, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static coursemodule(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.COURSEDETAIL, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static AssessmentDownload(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.ASSESSMENTDOWNLOADURL, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static assesments(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.USERASSESMENT, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static allcourse(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.ALLCOURSE, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static applycourse(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.APPLYCOURSE, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }  

  static handlepay(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.HANDLEPAY, param, header) 
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static assesmentsresults(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.ASSESMENTRESULT, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static downloadassesment(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.DOWNLOADASSESMENT, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static retakeassesment(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.RETAKEASSESMENT, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static verifygiftemail(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.VERIFYGIFTEMAIL, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static verifygeneteredCode(param) {
    const deferred = $q.defer();
    http
      .post(API_URL + API_MAP.VERIFYCODE, param)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static updateGeneretedCode(param) {
    const deferred = $q.defer();
    http
      .post(API_URL + API_MAP.UPDATECODE, param)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static community(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .get(API_URL + API_MAP.COMMUNITY, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static postcommunity(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.COMMUNITYPOSTS, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static postcommunitylikes(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.COMMUNITYPOSTSLIKES, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static postcommentlikes(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.COMMUNITYCOMMENTLIKES, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static postsubcommentlikes(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.COMMUNITYSUBCOMMENTLIKES, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static postcommunitydetails(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.COMMUNITYPOSTSDETAILS, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }  

  static postquery(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.POSTQUERY, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static askquery(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.ASKQUERY, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static searchpost(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.SEARCHPOST, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static communitycomment(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.ADDCOMMUNITYCOMMENT, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static communitysubcomment(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.ADDCOMMUNITYSUBCOMMENT, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 


  static getCountriesList() {
    const deferred = $q.defer();
    http
      .get(API_URL + API_MAP.GETCOUNTRIES)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static getStateList(params) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .get(API_URL + API_MAP.GETSTATES + `?country_code=${params.country_code}`, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static userProfileImageUpload(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.USERPROFILEUPLOAD, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static getUserDetails(params) {
    const authToken = params.token;
    const header = {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    };
    const url = `${API_URL}${API_MAP.GET_USER}?userId=${params.userId}&email=${params.email}`;

    return fetch(url, {
      method: 'GET',
      headers: header
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Unable to fetch user details');
        }
      })
      .catch(error => {
        console.error('Error fetching user details:', error);
        throw error;
      });
  }

  static downloadcourseguidebook(params) {
    const UserBeareToken = params.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.DOWNLOADGUIDEBOOK, params, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static downloadCourseCertificate(params) {
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + params.token;
    http
      .post(API_URL + API_MAP.DOWNLOADCERTIFICATE, params, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static downloadcoursematerial(params) {
    const UserBeareToken = params.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.COURSEMATERIAL, params, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static getNormalOrderInvoice(params) {
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + params.token;
    http
      .get(API_URL + API_MAP.NORMALORDERINVOICE + `?userId=${params.userId}&orderid=${params.orderid}`, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static getRenwedOrderInvoice(params) {
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + params.token;
    http
      .get(API_URL + API_MAP.RENWEDORDERINVOICE + `?userId=${params.userId}&email=${params.email}&orderid=${params.orderid}`, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static getGiftedOrderInvoice(params) {
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + params.token;
    http
      .get(API_URL + API_MAP.GIFTEDORDERINVOICE + `?userId=${params.userId}&orderid=${params.orderid}`, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static userUpdatePassword(params) {
    const UserBeareToken = params.token
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.UPDATE_PASSWORD, params, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static validateusersOldPassword(params) {
    const UserBeareToken = params.token
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.VALIDATEOLDPASSWORD, params, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static saveSubscriber(params) {
    const deferred = $q.defer();  
    const header = getHeader();
    http
      .post(API_URL + API_MAP.CREATESUBSCRIBER, params, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static getMyMediaBlogs() {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .get(API_URL + API_MAP.MEDIABLOGS)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static getFeedback(params) {
    const UserBeareToken = params.token
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .get(API_URL + API_MAP.GETFEEDBACK, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 
  
  static postFeedback(params) {
    const UserBeareToken = params.token
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.POSTFEEDBACK, params ,header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static userprofileUpload(params) {
    const deferred = $q.defer();
    const header = getHeader();
    header["Authorization"] = "Bearer " + params.token;
    http
      .post(API_URL + API_MAP.USERPROFILE, params, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }


  static getCourseVideos(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    const queryString = Object.keys(param).map(key => key + '=' + encodeURIComponent(param[key])).join('&');

    http
      .get(API_URL + API_MAP.GETCOURSEVIDEO + '?' + queryString, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

//   static getCourseVideos(param) {
//     const UserBeareToken = param.token;
//     const deferred = $q.defer();  
//     const header = getHeader();
//     header['Authorization'] = 'Bearer ' + UserBeareToken;

//     // Constructing the query string from the parameters object

//     // Making the API call using AngularJS $http service
//     http
//         .get(apiUrlWithParams, { headers: header })
//         .then((response) => {
//             // Resolving the promise with response data
//             deferred.resolve(response);
//         })
//         .catch((error) => {
//             // Rejecting the promise with error
//             deferred.reject(error);
//         });

//     return deferred.promise;
// }


  static saveContactus(param){
    const deferred = $q.defer();
    const header = getHeader();
    http
    .post(API_URL + API_MAP.CONTACTUS, param, header)
    .then((res)=>deferred.resolve(res))
    .catch((error)=>deferred.reject(error));
    
    return deferred.promise
  }

  static home(param) {
    const deferred = $q.defer();

    http
      .get(API_URL + API_MAP.HOME, param)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static updatesession(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.UPDATESESSION, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static UpdateSubLectureTestmonialSession(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.UPDATESUBSESSION, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }
  
  
  

  static Nextassessementattemptstatus(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.NextEASSESMENTattemptUpdate, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 
  

  static validsession(param) {
    const deferred = $q.defer();
    http
      .get(API_URL + API_MAP.VALIDSESSION, param)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }


  static getCourseProgress(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.GETCOURSEPROGRESS, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static updateCourseProgress(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.UPDATECOURSEPROGRESS, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }


  static updateTestMonialVideos(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.TESTMONIALVIDEO, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static getCurrentOrderDetails(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();  
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .post(API_URL + API_MAP.GETORDERDETAILS, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static refund() {
    const deferred = $q.defer();  
    const header = getHeader();
    http
      .get(API_URL + API_MAP.REFUNDCANCELLATION)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static privacyPolicy() {
    const deferred = $q.defer();  
    const header = getHeader();
    http
      .get(API_URL + API_MAP.PRIVACYPOLICY)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 

  static termsCondition() {
    const deferred = $q.defer();  
    const header = getHeader();
    http
      .get(API_URL + API_MAP.TERMSCONDITION)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }


  static checkEmail(params) {
    const deferred = $q.defer();  
    
    http
      .get(API_URL + API_MAP.CHECKEMAIL+`?email=${params.email}`)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  } 
   
   

}

